import { Close } from "@mui/icons-material";
import CheckRounded from "@mui/icons-material/CheckRounded";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Button, IconButton } from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-details-popup-header.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";

const GatewayDetailsPopupHeader = () => {
  const { t } = useTranslation("gateway");

  const { formState } = useFormContext();
  const { gatewayDetailsHook } = useGatewaysContextProvider();
  const {
    cancelMutation,
    mutationIsLoading,
    deleteGateway,
    currentSelectedGateway,
    openDetailsInEditingMode,
    closeDetails,
  } = gatewayDetailsHook;

  const isViewing = gatewayDetailsHook.viewingMode === "viewing";
  const isCreating = gatewayDetailsHook.viewingMode === "creation";
  const isEditing = gatewayDetailsHook.viewingMode === "editing";

  return (
    <div className={styles.container}>
      {(isCreating || isEditing) && (
        <>
          <Button
            data-testid="gatewayDetailCancelButton"
            variant="text"
            onClick={
              isCreating
                ? () => closeDetails(formState.isDirty)
                : cancelMutation
            }
            disabled={mutationIsLoading}
          >
            {t("details.cancelFormButton")}
          </Button>
          <Button
            data-testid="gatewayDetailSubmitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={mutationIsLoading}
            loadingPosition="start"
          >
            {t("details.saveFormButton")}
          </Button>
        </>
      )}
      {isViewing && (
        <>
          <AutorisationWrapper
            atLeastOnePermissionOf={[
              Permission.DeleteSystemsGatewaysConnectors,
              Permission.DeleteExternalSystem,
            ]}
          >
            <Button
              data-testid="gatewayDetailRemoveButton"
              startIcon={<DeleteForeverOutlinedIcon />}
              variant="text"
              onClick={() => deleteGateway(currentSelectedGateway!)}
              disabled={mutationIsLoading}
            >
              {t("details.removeFormButton")}
            </Button>
          </AutorisationWrapper>
          <AutorisationWrapper
            atLeastOnePermissionOf={[
              Permission.UpdateSystemsGatewaysConnectors,
              Permission.UpdateExternalSystem,
            ]}
          >
            <Button
              data-testid="gatewayDetailEditButton"
              startIcon={<ModeEditOutlineOutlinedIcon />}
              variant="text"
              onClick={() => openDetailsInEditingMode(currentSelectedGateway!)}
              disabled={mutationIsLoading}
            >
              {t("details.editFormButton")}
            </Button>
          </AutorisationWrapper>
        </>
      )}

      <IconButton
        aria-label="close"
        className={styles.closeButton}
        onClick={() => closeDetails(formState.isDirty)}
        data-testid="GatewayDetailsPopupCloseButton"
      >
        <Close />
      </IconButton>
    </div>
  );
};

export default GatewayDetailsPopupHeader;
