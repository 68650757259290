import { SvgIcon, SxProps, Theme, Tooltip } from "@mui/material";
import Constants from "style/constants";

interface Props {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  tooltipText?: string;
}

export const InBathroomIcon = ({
  disabled,
  sx,
  color,
  tooltipText,
}: Readonly<Props>) => {
  const iconColor =
    disabled !== undefined && disabled
      ? Constants.Colors.inactive
      : (color ?? "#7342BD");

  return (
    <Tooltip title={tooltipText}>
      <SvgIcon sx={sx} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7885 4.5407C15.4239 3.6582 16.4741 3 18 3C19.5259 3 20.5761 3.6582 21.2115 4.5407C21.8102 5.3722 22 6.33998 22 7V11C22 11.5304 21.7893 12.0391 21.4142 12.4142C21.2895 12.5389 21.1501 12.6454 21 12.7321V14C21 15.1564 20.4879 17.3544 18.5104 18.4134L18.9453 19.6738C19.1255 20.1959 18.8483 20.7652 18.3262 20.9453C17.8041 21.1255 17.2348 20.8483 17.0547 20.3262L16.5881 18.9739C16.3914 18.9928 16.1938 19.0016 15.9958 19H8C7.79833 19 7.60238 18.991 7.41217 18.9732L6.9453 20.3262C6.76515 20.8483 6.19588 21.1255 5.67381 20.9453C5.15173 20.7652 4.87455 20.1959 5.0547 19.6738L5.48963 18.4134C3.5121 17.3544 3 15.1564 3 14V13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H5.07292C5.27665 10.1191 5.91343 9 7.06 9C8.20657 9 9.51286 10.1191 9.97605 11H20V7C20 6.66002 19.8898 6.1278 19.5885 5.7093C19.3239 5.3418 18.8741 5 18 5C17.1259 5 16.6761 5.3418 16.4115 5.7093C16.345 5.80175 16.2877 5.89975 16.239 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H13C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6H14.1259C14.2426 5.52343 14.4503 5.01033 14.7885 4.5407ZM5 13V14C5 14.9463 5.48894 16.4595 7.00951 16.8766L7.01076 16.877C7.28655 16.953 7.61352 17 8 17H16.0087C16.3411 17.0029 16.6725 16.9611 16.9938 16.8757C18.5118 16.4574 19 14.9456 19 14V13H5ZM8.3 6.65C8.3 7.56127 7.56127 8.3 6.65 8.3C5.73873 8.3 5 7.56127 5 6.65C5 5.73873 5.73873 5 6.65 5C7.56127 5 8.3 5.73873 8.3 6.65Z"
          color={iconColor}
        />
      </SvgIcon>
    </Tooltip>
  );
};
