import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, TextField, Typography } from "@mui/material";
import BackgroundImage from "features/authentication/assets/background-image.svg";
import Logo from "features/authentication/assets/logo.svg";
import { RegexExpressions } from "constants/regex/regex-expressions";
import {
  authenticationActions,
  useLazyPreValidateQuery,
} from "features/authentication/domain/reducers/authentication.reducer";
import { ReactElement, useEffect, useState, useRef } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux-base/store";
import "./login.view.scss";
import { useLogin } from "features/authentication/hooks";
import authenticationService from "../services/authentication.service";
import { SessionStorageKeys } from "../constants/session-storage-keys";
import routes from "features/routing/routes";

function LoginView(): ReactElement {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
    setValue,
  } = useForm();
  const { t } = useTranslation("authentication");

  const dispatch = useAppDispatch();

  const [
    triggerPreValidateQuery,
    {
      isSuccess: preValidateIsSuccess,
      isLoading: preValidateIsLoading,
      error: preValidateError,
    },
  ] = useLazyPreValidateQuery();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (
      sessionStorage.getItem(SessionStorageKeys.TRIED_TO_SIGNIN_ONCE) !== "true"
    ) {
      sessionStorage.setItem(SessionStorageKeys.TRIED_TO_SIGNIN_ONCE, "true");
      sessionStorage.setItem(
        SessionStorageKeys.REDIRECT_TO,
        routes.organisation.path,
      );
      authenticationService.login("", "none", "");
    }
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  });

  const [
    lastEnteredEmailDomainOnFirstRender,
    setLastEnteredEmailDomainOnFirstRender,
  ] = useState<string>("");
  const { setLastEnteredEmailDomain, lastEnteredEmailDomain } = useLogin();

  useEffect(() => {
    if (preValidateIsSuccess) {
      setLastEnteredEmailDomain(getValues("email").trim().split("@")[1]);
      dispatch(authenticationActions.login(getValues("email").trim()));
    } else if (preValidateError) {
      setError("email", { type: "custom", message: t("emailInputHelperText") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preValidateIsSuccess, preValidateError]);

  useEffect(() => {
    if (lastEnteredEmailDomainOnFirstRender?.length > 0) {
      setValue("email", `@${lastEnteredEmailDomainOnFirstRender}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEnteredEmailDomainOnFirstRender]);

  useEffect(() => {
    if (isFirstRender) {
      setLastEnteredEmailDomainOnFirstRender(lastEnteredEmailDomain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEnteredEmailDomain]);

  const submitForm = (fieldValues: FieldValues) => {
    triggerPreValidateQuery(fieldValues.email.trim());
  };

  const registerOptions = {
    email: {
      required: t("emailInputHelperText"),
      // Use validate i.s.o. pattern to be able to trim the value
      validate: (value: string) => {
        const regex = new RegExp(RegexExpressions.EMAIL);
        return regex.test(value?.trim()) || t("emailInputHelperText");
      },
    },
  };

  return (
    <div className="login-container">
      <div className="image-container">
        <img
          src={BackgroundImage}
          className="background-image"
          alt="background"
        />
        <img src={Logo} className="logo" alt="logo" />
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="login-form-container">
          <Typography variant="h2" className="login-title">
            {t("loginTitle")}
          </Typography>
          <Typography variant="h4" className="login-item">
            {t("loginText")}
          </Typography>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={registerOptions.email}
            render={({ field }) => (
              <TextField
                {...field}
                id="EmailInput"
                label={t("emailInputLabelText")}
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message?.toString()}
                className="form-input login-item"
                sx={{
                  "& .MuiInputBase-root": {
                    "& input": {
                      textAlign:
                        lastEnteredEmailDomain?.length > 0 ? "right" : "left",
                    },
                  },
                }}
              />
            )}
          />
          <Button
            data-testid="LoginButton"
            className="login-button"
            type="submit"
            variant="contained"
            color="primary"
            loading={preValidateIsLoading}
            loadingPosition="center"
          >
            {t("loginButtonText")}
            <ChevronRightIcon />
          </Button>
        </div>
      </form>
    </div>
  );
}

export default LoginView;
