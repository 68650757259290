import {
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Logo from "components/logo/logo.component";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { GetMenuItemGroupPermissions } from "features/menu/views/menu-item-group";
import "features/menu/views/menu.component.scss";
import SecondaryMenu from "features/menu/views/secondary-menu/secondary-menu.component";
import useMenuItems from "features/menu/views/use-menu-items";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-base/store";

const Menu = () => {
  const menuItemGroups = useMenuItems();
  const { t } = useTranslation("menu");
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  function goToRoute(route: string) {
    dispatch(
      showConfirmationPopup({
        confirmActionNextAction: () => {
          navigate(route);
        },
      }),
    );
  }

  const isActive = (route: string) => {
    let splittedCurrentRoute = location.pathname.split("/");
    let splittedMenuRoute = route.split("/");

    if (splittedCurrentRoute.length >= 2 && splittedMenuRoute.length >= 2) {
      return splittedCurrentRoute[1] === splittedMenuRoute[1];
    }

    return false;
  };

  return (
    <>
      <div className="menu" data-testid="menu">
        <Drawer variant="permanent" anchor="left" open>
          <div className="logo-container">
            <Logo />
          </div>
          <div className="menu-items-container">
            {menuItemGroups.map((menuItemGroup, index) => (
              <AutorisationWrapper
                key={menuItemGroup.key}
                atLeastOnePermissionOf={GetMenuItemGroupPermissions(
                  menuItemGroup,
                )}
              >
                <>
                  {menuItemGroup.menuItems.map((item) => (
                    <AutorisationWrapper
                      key={item.titleTranslationKey}
                      atLeastOnePermissionOf={item.atLeastOnePermissionOf}
                      showForTenantManagerOnly={item.showForTenantManagerOnly}
                    >
                      <ListItem disablePadding>
                        <ListItemButton
                          data-testid={`menu-item-${item.titleTranslationKey}`}
                          selected={isActive(item.route)}
                          onClick={() => goToRoute(item.route)}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={t(item.titleTranslationKey)} />
                        </ListItemButton>
                      </ListItem>
                    </AutorisationWrapper>
                  ))}
                </>
                {index !== menuItemGroups.length - 1 ? (
                  <Divider className="menu-divider" />
                ) : (
                  <></>
                )}
              </AutorisationWrapper>
            ))}
          </div>
        </Drawer>
      </div>
      <SecondaryMenu />
    </>
  );
};

export default Menu;
