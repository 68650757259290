import { CommentOutlined } from "@mui/icons-material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";
import { Connector } from "features/connectors/domain/connector";
import IntegrationsNavigation from "features/integrations/views/integrations-navigation";
import styles from "features/connectors/views/connectors.module.scss";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { NestedKeyof } from "utils/nested-keyof-utils";
import ConnectorDetailsPopup from "./connector-details/connector-details-popup.component";
import Permission from "features/autorisation/domain/models/permission";
import { useAuth } from "features/authentication/providers/authentication.provider";
import LocationRow from "components/location/location-row.component";

const Connectors = () => {
  const { t } = useTranslation("connectors");
  const { hasPermission } = useAuth();

  const {
    connectorsResponse,
    isLoading,
    isSuccess,
    connectorsFilterHook,
    connectorDetailsHook,
    openDetailsInViewingModeAndRefresh,
    openDetailsInEditingModeAndRefresh,
  } = useConnectorsContextProvider();

  const { connectors, total } = connectorsResponse ?? {
    connectors: [],
    total: 0,
  };
  const showConnectorsTable = !isLoading && isSuccess;

  const locationRows = (connector: Connector) => {
    return (
      <>
        {connector.organisationUnits.map((unit) => (
          <LocationRow key={unit.id} name={unit.name} />
        ))}
      </>
    );
  };

  const noteIcon = (hasNote: boolean) => (
    <CommentOutlined
      sx={{
        width: 16,
        height: 16,
        color: Constants.Colors.onSurfaceVariant,
        visibility: hasNote ? "visible" : "hidden",
      }}
    />
  );

  const columns: ColumnDefinition<Connector, NestedKeyof<Connector>>[] = [
    { key: "name", label: t("table.column.name") },
    {
      key: "type",
      label: t("table.column.type"),
      renderCustomContentProvider: ({ type }) => (
        <>{t(`details.information.type.${type}`)}</>
      ),
      disableSort: true,
    },

    {
      key: "organisationUnits",
      label: t("table.column.locations"),
      renderCustomContentProvider: locationRows,
      disableSort: true,
      tableCellProps: { width: "400px" },
    },
    {
      key: "note",
      label: "",
      renderCustomContentProvider: (connector) => noteIcon(!!connector.note),
      disableSort: true,
    },
  ];

  const getOverflowMenuItems = ():
    | Array<TableOverflowMenuItem<Connector>>
    | undefined => {
    let menuItems: Array<TableOverflowMenuItem<Connector>> = [
      {
        label: t("overflowMenu.view"),
        action: (connector) => openDetailsInViewingModeAndRefresh(connector),
      },
    ];

    if (
      hasPermission(
        Permission.UpdateSystemsGatewaysConnectors ||
          Permission.UpdateExternalSystem,
      )
    ) {
      menuItems.push({
        label: t("overflowMenu.edit"),
        action: (connector) => openDetailsInEditingModeAndRefresh(connector),
      });
    }

    return menuItems;
  };

  return (
    <div className={styles.container}>
      <PageHeader
        title={t("pageTitle")}
        navigationComponent={<IntegrationsNavigation />}
      />
      {isLoading && <LoadingIndicator />}
      {showConnectorsTable && (
        <Table
          data={connectors.map<IRowItem<Connector>>((connector) => ({
            data: connector,
          }))}
          columns={columns}
          onItemClick={(connector: Connector) =>
            openDetailsInViewingModeAndRefresh(connector)
          }
          rowIdentifier={(item) => item.id!}
          initialOrderBy={
            connectorsFilterHook.table.sortFromSessionStorage!.property
          }
          initialOrderDirection={
            connectorsFilterHook.table.sortFromSessionStorage!.isAscending
              ? "asc"
              : "desc"
          }
          useBuiltInSorter
          enablePagination
          count={total}
          onPageChanged={connectorsFilterHook.table.handleOnPageChanged}
          rowsPerPage={connectorsFilterHook.table.currentRowsPerPage}
          page={connectorsFilterHook.table.currentPage}
          onRowsPerPageChanged={
            connectorsFilterHook.table.handleOnRowsPerPageChanged
          }
          onSortChanged={connectorsFilterHook.table.handleOnSortChanged}
          overflowMenuOptions={getOverflowMenuItems()}
        />
      )}
      {connectorDetailsHook.isDetailsOpen && <ConnectorDetailsPopup />}
    </div>
  );
};

export default Connectors;
