import Device from "features/device/domain/models/device";
import LinkableDevice from "features/device/linkable-device/domain/models/linkable-device";
import { DeviceType } from "features/device/models/device-type";
import { TFunction } from "i18next";

export function deviceDisplayType(
  t: TFunction,
  device: Device | LinkableDevice | undefined | null,
): string {
  if (device) {
    return device.displayType ?? t(`${device.type}`);
  }

  return t(`${DeviceType.Universal}`);
}
