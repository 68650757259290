import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { useTranslation } from "react-i18next";
import { Button, IconButton, Typography } from "@mui/material";
import { CheckRounded, Close } from "@mui/icons-material";
import styles from "features/systems/IS32/views/IS32-edit-configuration-header.module.scss";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";

const IS32EditConfigurationHeader = () => {
  const { t } = useTranslation("IS32");

  const {
    systemsDetailsHook: { selectedSystem },
  } = useSystemsContextProvider();
  const {
    selectedIS32ConfigurationUnit,
    updateIS32ConfigurationIsLoading,
    cancelIS32ConfigurationMutation,
  } = useIS32DetailsContextProvider();

  const formatUnitName = () => {
    const displayType =
      selectedIS32ConfigurationUnit?.displayType &&
      selectedIS32ConfigurationUnit.displayType.trim() !== ""
        ? selectedIS32ConfigurationUnit.displayType
        : null;
    const type =
      selectedIS32ConfigurationUnit?.type &&
      selectedIS32ConfigurationUnit.type.trim() !== ""
        ? selectedIS32ConfigurationUnit.type
        : "";
    const serial = selectedIS32ConfigurationUnit?.serial
      ? `-${selectedIS32ConfigurationUnit.serial}`
      : "";
    const room =
      selectedIS32ConfigurationUnit?.room &&
      selectedIS32ConfigurationUnit.room.trim() !== ""
        ? `-${selectedIS32ConfigurationUnit.room}`
        : "";
    return `${displayType ?? type}${serial}${room}`;
  };

  return (
    <>
      <div className={styles.titleButtonsContainer}>
        <Typography variant="h2">
          {t("configuration.unitTitle", {
            address: selectedIS32ConfigurationUnit?.address,
            name: formatUnitName(),
          })}
        </Typography>
        <div className={styles.buttonContainer}>
          <Button
            data-testid="deviceDetailCancelButton"
            variant="text"
            onClick={cancelIS32ConfigurationMutation}
            disabled={updateIS32ConfigurationIsLoading}
          >
            {t("actions.cancel")}
          </Button>

          <Button
            data-testid="deviceDetailSubmitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={updateIS32ConfigurationIsLoading}
            loadingPosition="start"
          >
            {t("actions.save")}
          </Button>

          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={() => cancelIS32ConfigurationMutation()}
            data-testid="devicePopupCloseButton"
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <Typography variant="subtitle1">{selectedSystem?.name}</Typography>
    </>
  );
};

export default IS32EditConfigurationHeader;
