import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "components/bread-crumb/bread-crumb.module.scss";
import { Typography } from "@mui/material";
import React, { ReactElement } from "react";
import BreadCrumbTooltip from "components/bread-crumb/bread-crumb-tooltip.component";

interface Props {
  showEllipsis: boolean;
  startIcon?: ReactElement;
  children: ReactElement | Array<ReactElement>;
  allElementNames: string | Array<string>;
}

const BreadCrumb = ({
  showEllipsis,
  startIcon,
  children,
  allElementNames,
}: Readonly<Props>) => {
  const getBreadCrumbs = (): Array<ReactElement> => {
    let breadCrumbs = new Array<ReactElement>();

    if (showEllipsis) {
      breadCrumbs.push(<Typography variant="body2">...</Typography>);
    }

    if (Array.isArray(children)) {
      breadCrumbs =
        showEllipsis && children.length > 2
          ? breadCrumbs.concat(children.slice(children.length - 2))
          : breadCrumbs.concat(children);
    } else {
      breadCrumbs.push(children);
    }

    return breadCrumbs;
  };

  return (
    <BreadCrumbTooltip allElementNames={allElementNames}>
      <div className={styles.container}>
        {startIcon && (
          <div className={styles.startIconContainer}>{startIcon}</div>
        )}
        {getBreadCrumbs().map((child, index, { length }) => (
          <React.Fragment key={`bread_crumb_${index}`}>
            {child}
            {index + 1 < length && (
              <ChevronRightIcon className={styles.chevronRightIcon} />
            )}
          </React.Fragment>
        ))}
      </div>
    </BreadCrumbTooltip>
  );
};

export default BreadCrumb;
