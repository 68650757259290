import { Close, NotificationsActiveOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import "./send-test-event-popup.component.scss";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import { useSendTestEventContextProvider } from "../context/send-test-event-provider";
import SendTestEventOrganisation from "./send-test-event-organisation";
import { Controller, FieldValues, FormProvider } from "react-hook-form";
import SelectField from "components/formFields/select-field.component";
import { EventType } from "../domain/models/event-type";

function SendTestEventPopup(): ReactElement {
  const {
    isSendTestEventPopupOpen,
    closePopup,
    selectedDevice,
    form,
    submitTestEvent,
    sendTestEventIsLoading,
  } = useSendTestEventContextProvider();

  const { t } = useTranslation("sendTestEvent");

  function submitForm(fieldValues: FieldValues) {
    submitTestEvent(fieldValues.eventType, fieldValues.eventPayload);
  }

  return (
    <Dialog
      open={isSendTestEventPopupOpen}
      onClose={() => {
        closePopup();
      }}
      maxWidth="md"
    >
      <div className="send-test-event-popup">
        <div className="send-test-event-header">
          <Typography variant="h3">{t("dialogTitle")}</Typography>
          <div className="header-buttons">
            <IconButton
              aria-label="close"
              className="close-button"
              onClick={() => closePopup()}
              data-testid="sendTestEventCloseButton"
            >
              <Close />
            </IconButton>
          </div>
        </div>

        <Divider />
        <Typography variant="h5">{t("organisationUnit")}</Typography>
        <OrganisationUnitTreeProvider.ForLinkedIntegration
          gatewayId={selectedDevice?.gatewayId}
        >
          <SendTestEventOrganisation />
        </OrganisationUnitTreeProvider.ForLinkedIntegration>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(submitForm)}>
            <fieldset className="send-test-event-form-fields">
              <Controller
                name="eventType"
                control={form.control}
                render={({ field }) => {
                  return (
                    <SelectField
                      {...field}
                      inputProps={{ "data-testid": "typeInput" }}
                      id="typeInput"
                      variant="outlined"
                      className="input-field"
                      label={t("eventType")}
                    >
                      {Object.values(EventType)
                        .map((eventType) => ({
                          original: eventType,
                          translated: t(`eventTypes.${eventType}`),
                        }))
                        .sort((a, b) =>
                          a.translated.localeCompare(b.translated),
                        )
                        .map(({ original, translated }) => (
                          <MenuItem
                            data-testid={`event-type-${original}`}
                            key={`eventType-${original}`}
                            value={original}
                          >
                            {translated}
                          </MenuItem>
                        ))}
                    </SelectField>
                  );
                }}
              />
              <Controller
                name="eventPayload.location"
                control={form.control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      id="typeInput"
                      variant="outlined"
                      className="input-field"
                      label={t("location")}
                    />
                  );
                }}
              />
              <Controller
                name="eventPayload.message"
                control={form.control}
                render={({ field }) => {
                  return (
                    <TextField
                      {...field}
                      id="typeInput"
                      variant="outlined"
                      className="input-field"
                      label={t("message")}
                    />
                  );
                }}
              />
            </fieldset>
            <Button
              startIcon={<NotificationsActiveOutlined />}
              loading={sendTestEventIsLoading}
              variant="contained"
              type="submit"
            >
              {t("send")}
            </Button>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
}

export default SendTestEventPopup;
