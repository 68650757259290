import { SvgIcon, SxProps, Theme, Tooltip } from "@mui/material";
import { ReactElement } from "react";
import Constants from "style/constants";

interface Props {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  tooltipText?: string;
}

export default function SolidPersonFallingIcon({
  disabled,
  sx,
  color,
  tooltipText,
}: Props): ReactElement {
  const determinedColor =
    disabled !== undefined && disabled
      ? Constants.Colors.inactive
      : (color ?? "#7342BD");
  return (
    <Tooltip title={tooltipText}>
      <SvgIcon sx={sx} viewBox="0 0 24 24">
        <path
          d="M12.6309 1.90637C13.3287 1.90637 13.8924 2.4701 13.8924 3.16786V3.55419C13.8924 5.7066 12.7926 7.67767 11.0344 8.82089L11.0422 8.83271L13.2814 11.9983H16.7308C17.326 11.9983 17.8858 12.2781 18.2445 12.7551L19.9475 15.0258C20.3654 15.5817 20.2511 16.374 19.6952 16.7919C19.1394 17.2098 18.347 17.0954 17.9292 16.5396L16.4154 14.5212H12.5757L16.2143 20.1427C16.5928 20.7262 16.4272 21.5067 15.8398 21.8891C15.2525 22.2715 14.4759 22.102 14.0935 21.5146L7.76239 11.7302C7.64807 12.0929 7.58499 12.4792 7.58499 12.8734V15.7827C7.58499 16.4805 7.02127 17.0442 6.32351 17.0442C5.62575 17.0442 5.06202 16.4805 5.06202 15.7827V12.8734C5.06202 10.3071 6.62311 7.99698 9.00811 7.04298C10.4352 6.47137 11.3694 5.09162 11.3694 3.55419V3.16786C11.3694 2.4701 11.9332 1.90637 12.6309 1.90637ZM5.69276 6.95231C4.6481 6.95231 3.80054 6.10475 3.80054 5.06008C3.80054 4.01542 4.6481 3.16786 5.69276 3.16786C6.73743 3.16786 7.58499 4.01542 7.58499 5.06008C7.58499 6.10475 6.73743 6.95231 5.69276 6.95231Z"
          fill={determinedColor}
        />
      </SvgIcon>
    </Tooltip>
  );
}
