import { SvgIcon, SxProps, Theme, Tooltip } from "@mui/material";
import Constants from "style/constants";

interface IProps {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  tooltipText?: string;
}

export const OutOfRoomIcon = ({
  disabled,
  sx,
  color,
  tooltipText,
}: Readonly<IProps>) => {
  const iconColor =
    disabled !== undefined && disabled
      ? Constants.Colors.inactive
      : (color ?? "#7342BD");

  return (
    <Tooltip title={tooltipText}>
      <SvgIcon sx={sx} viewBox="13 0 6 24">
        <path
          d="M11 13C11.2833 13 11.521 12.904 11.713 12.712C11.905 12.52 12.0007 12.2827 12 12C12 11.7167 11.904 11.479 11.712 11.287C11.52 11.095 11.2827 10.9993 11 11C10.7167 11 10.479 11.096 10.287 11.288C10.095 11.48 9.99934 11.7173 10 12C10 12.2833 10.096 12.521 10.288 12.713C10.48 12.905 10.7173 13.0007 11 13ZM7 21V19L13 18V6.875C13 6.625 12.925 6.4 12.775 6.2C12.625 6 12.4333 5.88333 12.2 5.85L7 5V3L12.5 3.9C13.2333 4.03333 13.8333 4.375 14.3 4.925C14.7667 5.475 15 6.11667 15 6.85V17.95C15 18.4333 14.8417 18.8627 14.525 19.238C14.2083 19.6133 13.8083 19.8423 13.325 19.925L7 21ZM7 19H17V5H7V19ZM4 21C3.71667 21 3.479 20.904 3.287 20.712C3.095 20.52 2.99934 20.2827 3 20C3 19.7167 3.096 19.479 3.288 19.287C3.48 19.095 3.71734 18.9993 4 19H5V5C5 4.43333 5.196 3.95833 5.588 3.575C5.98 3.19167 6.45067 3 7 3H17C17.5667 3 18.0417 3.19167 18.425 3.575C18.8083 3.95833 19 4.43333 19 5V19H20C20.2833 19 20.521 19.096 20.713 19.288C20.905 19.48 21.0007 19.7173 21 20C21 20.2833 20.904 20.521 20.712 20.713C20.52 20.905 20.2827 21.0007 20 21H4Z"
          fill={iconColor}
        />
        <path
          d="M21 10.55C20.7515 10.55 20.55 10.7515 20.55 11C20.55 11.2485 20.7515 11.45 21 11.45V10.55ZM26.3182 11.3182C26.4939 11.1425 26.4939 10.8575 26.3182 10.6818L23.4544 7.81802C23.2787 7.64228 22.9938 7.64228 22.818 7.81802C22.6423 7.99376 22.6423 8.27868 22.818 8.45442L25.3636 11L22.818 13.5456C22.6423 13.7213 22.6423 14.0062 22.818 14.182C22.9938 14.3577 23.2787 14.3577 23.4544 14.182L26.3182 11.3182ZM21 11.45H26V10.55H21V11.45Z"
          fill={iconColor}
        />
      </SvgIcon>
    </Tooltip>
  );
};
