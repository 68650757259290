import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { ReactElement, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch } from "redux-base/store";
import "./multi-select-organisation-tree.component.scss";
import OrganisationUnitTreeItem from "features/organisation/views/organisation-tree-item/organisation-unit-tree-item.component";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import { Checkbox, alpha } from "@mui/material";
import Constants from "style/constants";

interface IProps {
  onSelectOrganisationUnit: (selectedNode: OrganisationTreeNode) => void;
  selectedOrganisationUnitIds: string[];
}

export default function MultiSelectOrganisationTree(props: Readonly<IProps>) {
  const dispatch = useAppDispatch();

  const nodesByNodeId = new Map<string, OrganisationTreeNode>();

  const { organisationUnitTree, isLoading, isSuccess, error } =
    useOrganisationUnitTreeContext();

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  useEffect(() => {
    initTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitTree]);

  useEffect(() => {
    if (error) {
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const initTree = () => {
    const newExpandedNodes: string[] = [];
    if (organisationUnitTree) {
      const notExpanded = organisationUnitTree
        .map((x) => x.id)
        .filter((id) => !newExpandedNodes.includes(id));
      newExpandedNodes.push(...notExpanded);
    }

    setExpandedNodes(newExpandedNodes);
  };

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    event.persist();
    if (
      event.target instanceof SVGElement ||
      (event.target as HTMLElement).className === "MuiTreeItem-iconContainer"
    ) {
      setExpandedNodes(nodeIds);
    }
  };

  const handleSelect = (
    event: SyntheticEvent<Element, Event>,
    nodeId: string,
  ) => {
    event.persist();
    if (
      event.target instanceof HTMLElement &&
      event.target.className !== "MuiTreeItem-iconContainer"
    ) {
      if (nodesByNodeId.has(nodeId)) {
        onSelect(nodeId);
      }
    }
  };

  function onSelect(nodeId: string): void {
    const selectedNode = nodesByNodeId.get(nodeId)!;
    props.onSelectOrganisationUnit(selectedNode);
  }

  const isIndeterminate = (node: OrganisationTreeNode): boolean =>
    props.selectedOrganisationUnitIds.includes(node.id) &&
    !allChildrenAreSelected(node);

  function allChildrenAreSelected(node: OrganisationTreeNode): boolean {
    return node.childOrganisationUnits.every(
      (child) =>
        props.selectedOrganisationUnitIds.includes(child.id) &&
        allChildrenAreSelected(child),
    );
  }

  const renderCheckbox = (node: OrganisationTreeNode) => (
    <Checkbox
      indeterminate={isIndeterminate(node)}
      sx={{
        padding: "6px",
        margin: 0,
        marginLeft: node.childOrganisationUnits.some((x) => x) ? "0px" : "32px",
        "&:hover": {
          backgroundColor: alpha("#241A0029", 0.16),
        },
      }}
      size="small"
      checked={props.selectedOrganisationUnitIds?.some((o) => o === node.id)}
    />
  );

  function renderChildNodes(
    node: OrganisationTreeNode,
  ): ReactElement[] | undefined {
    nodesByNodeId.set(node.id, node);

    return node.childOrganisationUnits?.map((child) => {
      return (
        <OrganisationUnitTreeItem
          key={child.id}
          itemId={child.id}
          labelText={child.name}
          disableSelection={false}
          highlightColor={"transparent"}
          highlightedHoverColor={alpha(
            Constants.Colors.secondaryContainer,
            0.16,
          )}
          startingIcons={renderCheckbox(child)}
          isRoot={false}
          data-testid={`multiSelectOrganisationTreeItem-${child.id}`}
        >
          {renderChildNodes(child)}
        </OrganisationUnitTreeItem>
      );
    });
  }

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        isSuccess &&
        organisationUnitTree && (
          <div
            className="tree-view-container"
            data-testid="multiSelectOrganisationTree"
          >
            <SimpleTreeView
              aria-label="rich object"
              className="tree-view"
              onExpandedItemsChange={handleToggle}
              onItemClick={handleSelect}
              expandedItems={expandedNodes}
            >
              {organisationUnitTree.map((rootItem) => (
                <OrganisationUnitTreeItem
                  key={rootItem.id}
                  itemId={rootItem.id}
                  labelText={rootItem.name}
                  disableSelection={false}
                  highlightColor={"transparent"}
                  highlightedHoverColor={alpha(
                    Constants.Colors.secondaryContainer,
                    0.16,
                  )}
                  startingIcons={renderCheckbox(rootItem)}
                  isRoot={false}
                  data-testid={`multiSelectOrganisationTreeItem-${rootItem.id}`}
                >
                  {renderChildNodes(rootItem)}
                </OrganisationUnitTreeItem>
              ))}
            </SimpleTreeView>
          </div>
        )
      )}
    </>
  );
}
