import { KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  FormControlLabel,
  Accordion,
  AccordionSummary,
  Checkbox,
  AccordionDetails,
  Typography,
  FormGroup,
} from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import PermissionGroup from "features/autorisation/domain/models/permission-group";
import { ReactElement, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ViewingMode } from "utils/viewing-utils";
import PermissionGroupPermissionsComponent from "./permission-group-permissions-component";
import styles from "features/role/views/role-details/permissions/role-permission-group-component.module.scss";

interface IProps {
  permissionGroup: PermissionGroup;
  permissions: Array<Permission>;
  icon: ReactElement;
  viewingMode: ViewingMode;
}

function RolePermissionGroupComponent(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("permissions");
  const { getValues, setValue, watch: useFormWatch } = useFormContext();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [selectedPermissionsLength, setSelectedPermissionsLength] =
    useState<number>(getSelectedPermissionsLength());
  const permissionsLength = props.permissions.length;

  useFormWatch(() => {
    setSelectedPermissionsLength(getSelectedPermissionsLength());
  });

  function getSelectedPermissionsLength(): number {
    return props.permissions
      .map((permission) => ({
        permission: permission,
        isPermissionEnabled: getValues(`permissions.${permission}`),
      }))
      .filter((permission) => permission.isPermissionEnabled).length;
  }

  function changeAllPermissions(value: boolean) {
    for (const permission of props.permissions) {
      setValue(`permissions.${permission}`, value, { shouldDirty: true });
    }
  }

  return (
    <Accordion disableGutters onChange={(_, value) => setIsExpanded(value)}>
      <AccordionSummary expandIcon={<KeyboardArrowDownRounded />}>
        <div className={styles.summary}>
          <div
            className={styles.groupContainer}
            data-testid={`groupContainer${props.permissionGroup}`}
          >
            <div className={styles.icon}>
              {props.icon}
            </div>
            <Typography variant="h5">
              {t(`permissionGroups.${props.permissionGroup}`)}
            </Typography>
          </div>
          <FormGroup>
            <FormControlLabel
              className={styles.checkbox}
              control={
                <Checkbox
                  checked={selectedPermissionsLength > 0}
                  indeterminate={
                    selectedPermissionsLength > 0 &&
                    selectedPermissionsLength < permissionsLength
                  }
                  disabled={
                    props.viewingMode !== "creation" &&
                    props.viewingMode !== "editing"
                  }
                  onClick={(event: { stopPropagation: () => any }) =>
                    isExpanded && event.stopPropagation()
                  }
                  onChange={(_, value) => changeAllPermissions(value)}
                  // prettier-ignore
                  // @ts-ignore
                  inputProps={{ "data-testid": `groupPermissionSelection${props.permissionGroup}` }}
                />
              }
              label={`${selectedPermissionsLength}/${permissionsLength}`}
              labelPlacement="end"
            />
          </FormGroup>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="permission-group-detail-container">
          <PermissionGroupPermissionsComponent
            permissions={props.permissions}
            viewingMode={props.viewingMode}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default RolePermissionGroupComponent;
