import { ReactElement, useEffect } from "react";
import "./temporary-user-details-popup.component.scss";
import {
  Button,
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { useTemporaryAccessContextProvider } from "../providers/temporary-access-provider";
import { useTranslation } from "react-i18next";
import { BusinessOutlined, Close, EmailOutlined } from "@mui/icons-material";
import { RegexExpressions } from "constants/regex/regex-expressions";
import DateRangePicker from "components/date-range-picker/date-range-picker";
import SelectField from "components/formFields/select-field.component";
import { DateRange } from "components/date-range-picker/models/date-range";
import { isValidPhoneNumber } from "libphonenumber-js";
import SonevoLoadingIndicator from "components/loading-indicator/sonevo-loader";
import moment from "moment";
import { useAppDispatch } from "redux-base/store";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Alert from "components/alert/alert.component";

function TemporaryUserDetailsPopup(): ReactElement {
  const { t } = useTranslation("temporaryAccess");
  const dispatch = useAppDispatch();

  const registerOptions = {
    firstName: {
      required: t(
        "detailsPopup.body.form.details.firstNameInputRequiredHelperText",
      ),
    },
    lastName: {
      required: t(
        "detailsPopup.body.form.details.lastNameInputRequiredHelperText",
      ),
    },
    email: {
      required: t(
        "detailsPopup.body.form.details.emailInputRequiredHelperText",
      ),
      validate: (value: string) => {
        const regex = new RegExp(RegexExpressions.EMAIL);
        if (form.getValues().period) {
          form.trigger("period");
        }
        return (
          regex.test(value?.trim()) ||
          t("detailsPopup.body.form.details.emailInputInvalidHelperText")
        );
      },
    },
    phone: {
      required: t(
        "detailsPopup.body.form.details.phoneInputRequiredHelperText",
      ),
      validate: (value: string) =>
        isValidPhoneNumber(value.trim(), "NL") ||
        t("detailsPopup.body.form.details.phoneInputInvalidHelperText"),
    },
    organisationUnit: {
      required: t(
        "detailsPopup.body.form.access.organisationUnitInputRequiredHelperText",
      ),
      validate: (value: string) => {
        if (form.getValues().period) {
          form.trigger("period");
        }
        return true;
      },
    },
    role: {
      required: t("detailsPopup.body.form.access.roleInputRequiredHelperText"),
    },
    period: {
      required: t(
        "detailsPopup.body.form.period.periodInputRequiredHelperText",
      ),
      validate: validatePeriod,
    },
  };

  const {
    closeTemporaryUserDetailsPopup,
    temporaryUserDetailsPopupIsOpen,
    createTemporaryUser,
    createTemporaryUserIsLoading,
    createTemporaryUserIsSuccess,
    createTemporaryUserIsError,
    isTemporaryUserPeriodValid,
    readOrganisationUnitsForTemporaryAccessData,
    readOrganisationUnitsForTemporaryAccessIsSuccess,
    readRolesForTemporaryAccess,
    readRolesForTemporaryAccessData,
    readRolesForTemporaryAccessIsSuccess,
    readTemporaryUserData,
    readTemporaryUserIsFetching,
    readTemporaryUserError,
    detailsViewingMode,
  } = useTemporaryAccessContextProvider();

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    organisationUnit: "",
    role: "",
    period: undefined as DateRange | undefined,
  };

  const form = useForm({ mode: "onBlur", defaultValues });

  useEffect(() => {
    form.reset({
      firstName:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? readTemporaryUserData.firstName
          : "",
      lastName:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? readTemporaryUserData.lastName
          : "",
      email:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? readTemporaryUserData.email
          : "",
      phone:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? readTemporaryUserData.phoneNumber
          : "",
      organisationUnit:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? readTemporaryUserData.accessOrganisationUnitId
          : "",
      role:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? readTemporaryUserData.roleIdpId
          : "",
      period:
        detailsViewingMode === "viewing" && readTemporaryUserData
          ? ({
              startDate: moment(readTemporaryUserData.startsOn).toDate(),
              endDate: moment(readTemporaryUserData.endsOn).toDate(),
            } as DateRange)
          : (undefined as DateRange | undefined),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readTemporaryUserData]);

  useEffect(() => {
    if (readTemporaryUserError) {
      dispatch(
        setErrorMessage({
          error: readTemporaryUserError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readTemporaryUserError]);

  const control = form.control;
  const errors = form.formState.errors;

  const submit = async (fieldValues: FieldValues) => {
    await createTemporaryUser(
      fieldValues.firstName,
      fieldValues.lastName,
      fieldValues.email,
      fieldValues.phone,
      fieldValues.organisationUnit,
      fieldValues.role,
      fieldValues.period,
    );
  };

  async function validatePeriod(): Promise<boolean | string> {
    const email = form.getValues("email").trim();
    const period = form.getValues("period");
    const accessOrganisationUnitId = form.getValues("organisationUnit");

    if (
      await isTemporaryUserPeriodValid(
        email,
        accessOrganisationUnitId,
        period as DateRange,
      )
    ) {
      return true;
    }

    return t("detailsPopup.body.form.period.periodInputIsInvalidText");
  }

  useEffect(() => {
    readRolesForTemporaryAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      detailsViewingMode === "creation" &&
      readRolesForTemporaryAccessData &&
      readRolesForTemporaryAccessData.length === 1
    ) {
      form.setValue("role", readRolesForTemporaryAccessData[0].idpId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readRolesForTemporaryAccessData]);

  useEffect(() => {
    if (
      readOrganisationUnitsForTemporaryAccessData &&
      readOrganisationUnitsForTemporaryAccessData.length === 1
    ) {
      form.setValue(
        "organisationUnit",
        readOrganisationUnitsForTemporaryAccessData[0].id,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOrganisationUnitsForTemporaryAccessData]);

  return (
    <Dialog
      open={temporaryUserDetailsPopupIsOpen}
      onClose={closeTemporaryUserDetailsPopup}
      slotProps={{
        paper: { classes: { root: "temporary-user-details-popup-paper" } },
      }}
      maxWidth="xl"
    >
      <FormProvider {...form}>
        <div className="temporary-user-details-container">
          {detailsViewingMode === "viewing" && readTemporaryUserIsFetching ? (
            <div className="loading-indicator">
              <SonevoLoadingIndicator size={50} />
            </div>
          ) : (
            <>
              <div className="temporary-user-details-header-container">
                <Typography variant="h3">
                  {t("detailsPopup.header.title")}
                </Typography>
                <IconButton
                  aria-label="close"
                  className="close-button"
                  onClick={closeTemporaryUserDetailsPopup}
                >
                  <Close />
                </IconButton>
              </div>
              <Divider className="temporary-user-details-header-divider" />
              <div
                className={`temporary-user-details-body-container ${
                  detailsViewingMode === "viewing" ? "viewing-mode-padding" : ""
                }`}
              >
                <form
                  onSubmit={form.handleSubmit(submit)}
                  className="temporary-user-details-form-container"
                  data-testid="detailPopupForm"
                >
                  <div className="temporary-user-details-form-group">
                    <Typography variant="h5">
                      {t("detailsPopup.body.form.details.title")}
                    </Typography>
                    <Controller
                      name="firstName"
                      rules={registerOptions.firstName}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="firstNameInput"
                          label={t(
                            "detailsPopup.body.form.details.firstNameInput",
                          )}
                          variant="outlined"
                          error={!!errors.firstName}
                          helperText={errors.firstName?.message?.toString()}
                          disabled={detailsViewingMode === "viewing"}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      rules={registerOptions.lastName}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="lastNameInput"
                          label={t(
                            "detailsPopup.body.form.details.lastNameInput",
                          )}
                          variant="outlined"
                          error={!!errors.lastName}
                          helperText={errors.lastName?.message?.toString()}
                          disabled={detailsViewingMode === "viewing"}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      rules={registerOptions.email}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="emailInput"
                          label={t("detailsPopup.body.form.details.emailInput")}
                          variant="outlined"
                          error={!!errors.email}
                          helperText={errors.email?.message?.toString()}
                          disabled={detailsViewingMode === "viewing"}
                        />
                      )}
                    />
                    <Controller
                      name="phone"
                      rules={registerOptions.phone}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          id="phoneInput"
                          label={t("detailsPopup.body.form.details.phoneInput")}
                          variant="outlined"
                          error={!!errors.phone}
                          helperText={errors.phone?.message?.toString()}
                          disabled={detailsViewingMode === "viewing"}
                        />
                      )}
                    />
                  </div>
                  <div className="temporary-user-details-form-group">
                    <Typography variant="h5">
                      {t("detailsPopup.body.form.access.title")}
                    </Typography>
                    <Controller
                      name="organisationUnit"
                      control={control}
                      rules={registerOptions.organisationUnit}
                      render={({ field }) => (
                        <SelectField
                          {...field}
                          id="organisationUnit"
                          label={t(
                            "detailsPopup.body.form.access.organisationUnitInput",
                          )}
                          variant="outlined"
                          error={!!errors.organisationUnit}
                          helperText={errors?.organisationUnit?.message?.toString()}
                          onClick={() => form.trigger("organisationUnit")}
                          startAdornment={
                            <InputAdornment position="start">
                              <BusinessOutlined fontSize="small" />
                            </InputAdornment>
                          }
                          disabled={detailsViewingMode === "viewing"}
                        >
                          {readOrganisationUnitsForTemporaryAccessData &&
                            readOrganisationUnitsForTemporaryAccessIsSuccess &&
                            readOrganisationUnitsForTemporaryAccessData.map(
                              (organisationUnit) => (
                                <MenuItem
                                  key={`organisation-unit-${organisationUnit.id}`}
                                  value={organisationUnit.id}
                                >
                                  {organisationUnit.name}
                                </MenuItem>
                              ),
                            )}
                        </SelectField>
                      )}
                    />
                    <Controller
                      name="role"
                      control={control}
                      rules={registerOptions.role}
                      render={({ field }) => (
                        <SelectField
                          {...field}
                          id="roleInput"
                          label={t("detailsPopup.body.form.access.roleInput")}
                          variant="outlined"
                          error={!!errors.role}
                          helperText={errors?.role?.message?.toString()}
                          onClick={() => form.trigger("role")}
                          disabled={detailsViewingMode === "viewing"}
                        >
                          {readRolesForTemporaryAccessData &&
                            readRolesForTemporaryAccessIsSuccess &&
                            readRolesForTemporaryAccessData.map((role) => (
                              <MenuItem
                                key={`role-${role.id}`}
                                value={role.idpId}
                              >
                                {role.name}
                              </MenuItem>
                            ))}
                        </SelectField>
                      )}
                    />
                  </div>
                  <div className="temporary-user-details-form-group">
                    <Typography variant="h5">
                      {t("detailsPopup.body.form.period.title")}
                    </Typography>
                    <Controller
                      name="period"
                      control={control}
                      rules={registerOptions.period}
                      render={({ field }) => (
                        <DateRangePicker
                          {...field}
                          label={t("detailsPopup.body.form.period.periodInput")}
                          placeholder={t(
                            "detailsPopup.body.form.period.periodInput",
                          )}
                          error={!!errors.period}
                          helperText={errors.period?.message?.toString()}
                          name="period"
                          maxRangeInDays={7}
                          disablePast={true}
                          disabled={detailsViewingMode === "viewing"}
                        />
                      )}
                    />
                  </div>
                  {detailsViewingMode === "creation" && (
                    <Button
                      data-testid="submitButton"
                      startIcon={<EmailOutlined />}
                      variant="contained"
                      type="submit"
                      loading={
                        createTemporaryUserIsLoading &&
                        !(
                          createTemporaryUserIsError ||
                          createTemporaryUserIsSuccess
                        )
                      }
                      loadingPosition="start"
                    >
                      {t("detailsPopup.body.form.submitButtonText")}
                    </Button>
                  )}
                </form>
                <Alert
                  title={t("detailsPopup.body.explanation.title")}
                  body={t("detailsPopup.body.explanation.text")}
                />
              </div>
            </>
          )}
        </div>
      </FormProvider>
    </Dialog>
  );
}

export default TemporaryUserDetailsPopup;
