import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Constants from "style/constants";
import { JSX } from "react";

interface IProps {
  header: JSX.Element;
  details: JSX.Element;
  isExpanded: boolean;
  handleAccordionExpandedChange: (expanded: boolean) => void;
}

const IS32ConfigurationAccordion = ({
  header,
  details,
  isExpanded,
  handleAccordionExpandedChange,
}: IProps) => {
  const accordionStyle = {
    "&.Mui-expanded": {
      margin: 0,
    },
  };

  const accordionSummaryStyle = {
    minHeight: 0,
    padding: 0,

    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "&.Mui-expanded": {
      margin: 0,
      minHeight: 0,

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
  };

  const accordionDetailStyle = {
    padding: 0,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderColor: alpha(Constants.Colors.primary, 0.16),
  };

  return (
    <Accordion
      sx={accordionStyle}
      expanded={isExpanded}
      onChange={(_, expanded) => handleAccordionExpandedChange(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={accordionSummaryStyle}>
        {header}
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailStyle}>{details}</AccordionDetails>
    </Accordion>
  );
};

export default IS32ConfigurationAccordion;
