import { CommentOutlined } from "@mui/icons-material";
import FullPageLoadingIndicator from "components/loading-indicator/full-page-loading-indicator.component";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { AggregatedType } from "features/tenants/domain/models/aggregated-monitor-data";
import Tenant from "features/tenants/domain/models/tenant";
import { useTenantsContextProvider } from "features/tenants/providers/tenants-provider";
import TenantDetails from "features/tenants/tenant-details/views/tenant-details";
import MonitoringBar from "features/tenants/views/monitoring-bar";
import styles from "features/tenants/views/tenants.module.scss";
import ObjectSort from "models/object-sort";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "usehooks-ts";
import { NestedKeyof } from "utils/nested-keyof-utils";

const Tenants = () => {
  const sessionStorageSortingKey = "tenants-sorting";
  const { t } = useTranslation("tenants");

  const { hasPermission } = useAuth();
  const {
    readTenantsData,
    readTenantsIsSuccess,
    readTenantsIsLoading,
    isTenantPopupOpen,
    accessTenantIsLoading,
    onAccessTenant,
    openDetails,
    allowOnAccessTenant,
  } = useTenantsContextProvider();

  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<Tenant>
  >(sessionStorageSortingKey, { property: "displayName", isAscending: true });

  const lastUpdate =
    readTenantsData?.lastUpdatedOn &&
    `${t("tenantDetails.lastUpdated")} ${moment(
      readTenantsData.lastUpdatedOn,
    ).format("DD-MM-YYYY HH:mm")}`;

  const columns: ColumnDefinition<Tenant, NestedKeyof<Tenant>>[] = [
    { key: "displayName", label: t("tenantTable.column.name") },
    {
      key: "gatewayMonitorings",
      label: t("tenantTable.column.gatewaysAndSystems"),
      renderCustomContentProvider: ({
        gatewayMonitorings,
        systemMonitorings,
      }) =>
        gatewayMonitorings || systemMonitorings ? (
          <MonitoringBar
            monitoring={gatewayMonitorings.concat(systemMonitorings)}
            aggregatedType={AggregatedType.GatewaysAndSystems}
          />
        ) : (
          <>{t("tenantTable.monitoring.noData")}</>
        ),
      disableSort: true,
    },
    {
      key: "devicesMonitorings",
      label: t("tenantTable.column.devices"),
      renderCustomContentProvider: ({ devicesMonitorings }) =>
        devicesMonitorings ? (
          <MonitoringBar
            monitoring={devicesMonitorings}
            aggregatedType={AggregatedType.Devices}
          />
        ) : (
          <>{t("tenantTable.monitoring.noData")}</>
        ),
      disableSort: true,
    },
    {
      key: "note",
      label: "",
      renderCustomContentProvider: ({ note }) => (
        <CommentOutlined
          sx={{ visibility: note ? "visible" : "hidden", fontSize: 16 }}
        />
      ),
      disableSort: true,
      tableCellProps: {
        align: "left",
        width: "40px",
      },
    },
  ];

  const handleOnSortChanged = (
    property: NestedKeyof<Tenant>,
    isAscending: boolean,
  ) => {
    setSortFromSessionStorage({
      isAscending,
      property,
    });
  };

  const getOverflowMenuItems = ():
    | Array<TableOverflowMenuItem<Tenant>>
    | undefined => {
    let menuItems: Array<TableOverflowMenuItem<Tenant>> = [
      {
        label: t("tenantDetails.accessTenantButton"),
        action: (tenant) => onAccessTenant(tenant),
        isVisible: (tenant) => allowOnAccessTenant(tenant),
      },
      {
        label: t("tenantTable.action.view"),
        action: (tenant) => openDetails("viewing", tenant),
      },
    ];

    if (hasPermission(Permission.UpdateTenant)) {
      menuItems.push({
        label: t("tenantTable.action.edit"),
        action: (tenant) => openDetails("editing", tenant),
      });
    }

    return menuItems;
  };

  const getSortedTenants = (tenants: Tenant[]): Tenant[] => {
    const sortedTenants: Tenant[] = Object.assign([], tenants);

    if (sortFromSessionStorage.isAscending) {
      return sortedTenants.sort((a, b) => (a.realmName < b.realmName ? -1 : 1));
    }

    return sortedTenants.sort((a, b) => (a.realmName > b.realmName ? -1 : 1));
  };

  return (
    <AutorisationWrapper
      atLeastOnePermissionOf={[Permission.ReadTenant]}
      showForTenantManagerOnly
    >
      <div className={styles.container}>
        <PageHeader title={t("pageTitle")} description={lastUpdate} />
        {readTenantsIsLoading && <LoadingIndicator />}
        {readTenantsIsSuccess && readTenantsData && (
          <Table
            data={getSortedTenants(readTenantsData.tenants).map<
              IRowItem<Tenant>
            >((tenant) => ({ data: tenant }))}
            onItemClick={(item: Tenant) => openDetails("viewing", item)}
            columns={columns}
            overflowMenuOptions={getOverflowMenuItems()}
            rowIdentifier={(item: Tenant) => item.realmName}
            onSortChanged={handleOnSortChanged}
            initialOrderBy={sortFromSessionStorage.property}
            initialOrderDirection={
              sortFromSessionStorage.isAscending ? "asc" : "desc"
            }
          />
        )}
        {isTenantPopupOpen && <TenantDetails />}
      </div>
      <FullPageLoadingIndicator
        isLoading={accessTenantIsLoading}
        useTimeout={false}
      />
    </AutorisationWrapper>
  );
};

export default Tenants;
