import styles from "features/systems/IS32/views/IS32-configuration.module.scss";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { DeviceDetailsProvider } from "features/device/device-details/providers/device-details-provider/device-details.provider";
import DeviceDetailsPopup from "features/device/device-details/views/device-details-popup.component";
import { useIS32ContextProvider } from "features/systems/IS32/context/IS32-provider";
import IS32ConfigurationAccordionItem from "./IS32-configuration-accordion-item";
import { IS32DetailsProvider } from "features/systems/IS32/providers/IS32-details.provider";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const IS32Configuration = () => {
  const { t } = useTranslation("IS32");
  const {
    readIS32ConfigurationIsLoading,
    readIS32ConfigurationData,
    selectedDeviceId,
  } = useIS32ContextProvider();

  const {
    systemsDetailsHook: { refreshSystem },
  } = useSystemsContextProvider();

  return (
    <div className={styles.configurationContainer}>
      {readIS32ConfigurationIsLoading && <LoadingIndicator />}
      <Typography variant="h5" className={styles.title}>
        {t("title")}
      </Typography>
      {!readIS32ConfigurationIsLoading &&
        readIS32ConfigurationData?.units &&
        [...(readIS32ConfigurationData?.units ?? [])]
          .sort((unitA, unitB) => Number(unitA.address) - Number(unitB.address))
          .map((unit) => {
            return (
              <IS32DetailsProvider key={unit.address} unit={unit}>
                <IS32ConfigurationAccordionItem unit={unit} />
              </IS32DetailsProvider>
            );
          })}
      {selectedDeviceId && (
        <DeviceDetailsProvider
          deviceId={selectedDeviceId}
          initialViewingMode={"viewing"}
        >
          <DeviceDetailsPopup onCloseCallback={() => refreshSystem()} />
        </DeviceDetailsProvider>
      )}
    </div>
  );
};

export default IS32Configuration;
