import { Divider, Typography } from "@mui/material";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { SystemType } from "features/systems/domain/models/system";
import IS32Configuration from "features/systems/IS32/views/IS32-configuration";
import SystemOrganisation from "features/systems/views/details/configuration-settings/system-organisation";
import styles from "features/systems/views/details/system-configuration.module.scss";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const SystemConfiguration = () => {
  const { t } = useTranslation("systems");

  const { systemsDetailsHook } = useSystemsContextProvider();

  const { watch } = useFormContext();
  const watchSystemType = watch("type");

  const isViewing = systemsDetailsHook.viewingMode === "viewing";
  const hasSelectedType = SystemType.Unknown !== watchSystemType;

  if (!hasSelectedType) return <></>;

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      <div className={styles.settingsItem}>
        <Typography variant="h5">
          {t("details.configuration.organisationUnitLabel")}
        </Typography>
        <div className={styles.settingsItemContent}>
          <SystemOrganisation />
        </div>
      </div>
      <Divider sx={{ width: "100%" }} />
      {systemsDetailsHook.selectedSystem?.type === SystemType.IS32 && (
        <IS32Configuration />
      )}
    </div>
  );
};

export default SystemConfiguration;
