import { TextField, Typography } from "@mui/material";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import Gateway from "features/gateway/domain/models/gateway";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import useGatewayValidation from "features/gateway/hooks/gateway-validation-hook";
import GatewayMonitoringAlert from "features/gateway/views/components/gateway-monitoring-alert";
import styles from "features/gateway/views/gateway-details/gateway-information.module.scss";
import GatewaySettingsType from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-type";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const GatewayInformation = () => {
  const { t } = useTranslation("gateway");

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { validateName, validateVirtualIpAddress } = useGatewayValidation();

  const watchShouldCreateDevicesAutomatically = gatewayDetailsHook.form.watch(
    "shouldCreateDevicesAutomatically",
  );
  const watchGatewayType = gatewayDetailsHook.form.watch("type");

  const registerOptions = {
    name: {
      required: t("details.information.requiredHelperText"),
      validate: validateName,
    },
    ipAddress: {
      required: t("details.information.requiredHelperText"),
      validate: validateVirtualIpAddress,
    },
  };

  const isViewing = gatewayDetailsHook.viewingMode === "viewing";
  const isCreating = gatewayDetailsHook.viewingMode === "creation";
  const isEditing = gatewayDetailsHook.viewingMode === "editing";

  const hasSelectedType = GatewayType.None !== watchGatewayType;
  const showIPAddress =
    watchGatewayType === GatewayType.IoTEdge ||
    watchGatewayType === GatewayType.IoTEdgeCluster;
  const showCommunicationStatus =
    watchGatewayType !== GatewayType.NFC &&
    watchGatewayType !== GatewayType.BLEBeacon &&
    (isViewing || isEditing);
  const isIoTEdgeCluster = watchGatewayType === GatewayType.IoTEdgeCluster;

  const selectedGateway = gatewayDetailsHook.currentSelectedGateway;

  const showImageVersion =
    watchGatewayType === GatewayType.IoTEdge ||
    watchGatewayType === GatewayType.IoTEdgeCluster ||
    watchGatewayType === GatewayType.ESPA;
  const showHostModuleVersion =
    watchGatewayType === GatewayType.IoTEdge ||
    watchGatewayType === GatewayType.IoTEdgeCluster;

  const formattedDeviceCreationTime = moment(
    selectedGateway?.createDevicesAutomaticallySince,
  )
    ?.add(8, "hour")
    .format("HH:mm");

  const textField = (name: string, label: string, multiline = false) => (
    <Controller
      name={name}
      control={control}
      rules={registerOptions[name as keyof typeof registerOptions]}
      render={({ field }) => (
        <TextField
          {...field}
          slotProps={{
            htmlInput: {
              "data-testid": `${name}Input`,
              maxLength: 255,
            },
          }}
          id={`${name}Input`}
          label={label}
          variant="outlined"
          error={!!errors[name]}
          helperText={errors[name]?.message?.toString()}
          multiline={multiline}
          minRows={multiline ? 8 : undefined}
          maxRows={multiline ? 12 : undefined}
          fullWidth
        />
      )}
    />
  );

  const readOnlyField = (
    label: string,
    value: string,
    respectTextFormat = false,
  ) => (
    <div className={styles.informationItem}>
      <Typography variant="h5">{label}</Typography>
      <Typography
        variant="subtitle1"
        className={`${respectTextFormat && styles.textFormat}`}
      >
        {value}
      </Typography>
    </div>
  );

  const communicationDate = (lastCommunicationDate?: Date) =>
    lastCommunicationDate
      ? t("details.information.statusLastCommunicationDateLabel", {
          lastCommunicationDate: moment(lastCommunicationDate)
            .format("DD-MM-YYYY HH:mm")
            .toString(),
        })
      : t("details.information.statusUnknownLabel");

  const clusterGatewayStatus = (gateway: Gateway) => {
    const { id, name, isActiveInCluster, lastCommunicationDate } = gateway;
    return (
      <div key={id} className={styles.statusItem}>
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="subtitle1">
          {isActiveInCluster
            ? t("details.information.active")
            : t("details.information.passive")}
        </Typography>
        <Typography variant="subtitle1">
          {communicationDate(lastCommunicationDate)}
        </Typography>
        <Typography variant="subtitle1">
          {t("details.information.imageVersion", {
            imageVersion:
              gateway.imageVersion ?? t("details.information.versionUnknown"),
          })}
        </Typography>
        <Typography variant="subtitle1">
          {t("details.information.hostModuleVersion", {
            hostModuleVersion:
              gateway.hostModuleVersion ??
              t("details.information.versionUnknown"),
          })}
        </Typography>
      </div>
    );
  };

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      {(isEditing || isCreating) && (
        <>
          <Typography variant="h2">
            {isCreating && t("details.createGatewayTitle")}
            {isEditing && t("details.editGatewayTitle")}
          </Typography>

          <GatewayMonitoringAlert gateway={selectedGateway} />

          <div className={styles.informationItem}>
            <GatewaySettingsType />
          </div>

          {hasSelectedType && (
            <>
              {textField("name", t("details.information.nameLabel"))}

              {showIPAddress &&
                textField("ipAddress", t("details.information.ipAddressLabel"))}

              {textField("note", t("details.information.noteLabel"), true)}
            </>
          )}
        </>
      )}
      {isViewing && (
        <>
          <Typography variant="h2" data-testid="gatewayInformationName">
            {selectedGateway?.name}
          </Typography>

          <GatewayMonitoringAlert gateway={selectedGateway} />

          {selectedGateway?.type &&
            readOnlyField(
              t("details.settings.typeLabel"),
              t(`details.settings.type.${selectedGateway?.type}`),
            )}

          {selectedGateway?.ipAddress &&
            readOnlyField(
              t("details.information.ipAddressLabel"),
              selectedGateway?.ipAddress,
            )}

          {selectedGateway?.note &&
            readOnlyField(
              t("details.information.noteLabel"),
              selectedGateway.note,
              true,
            )}

          {watchShouldCreateDevicesAutomatically && (
            <Typography variant="subtitle1">
              {t("details.information.automaticDeviceCreationActiveUntill", {
                createDevicesAutomaticallySince: formattedDeviceCreationTime,
              })}
            </Typography>
          )}
        </>
      )}

      {showCommunicationStatus && (
        <div className={styles.informationItem}>
          <Typography variant="h5">
            {t("details.information.statusLabel")}
          </Typography>
          {isIoTEdgeCluster && selectedGateway?.childGateways ? (
            selectedGateway?.childGateways.map(clusterGatewayStatus)
          ) : (
            <>
              <Typography variant="subtitle1">
                {communicationDate(selectedGateway?.lastCommunicationDate)}
              </Typography>
              {showImageVersion && (
                <Typography variant="subtitle1">
                  {t("details.information.imageVersion", {
                    imageVersion:
                      selectedGateway?.imageVersion ??
                      t("details.information.versionUnknown"),
                  })}
                </Typography>
              )}
              {showHostModuleVersion && (
                <Typography variant="subtitle1">
                  {t("details.information.hostModuleVersion", {
                    hostModuleVersion:
                      selectedGateway?.hostModuleVersion ??
                      t("details.information.versionUnknown"),
                  })}
                </Typography>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GatewayInformation;
