import { Link as LinkIcon, LinkOff as LinkOffIcon } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import PopUp from "components/pop-up/pop-up.component";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-organisation.module.scss";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import OrganisationPicker from "features/organisation/views/organisation-picker/organisation-picker";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import useSystemOrganisation from "features/systems/hooks/system-organisation-hook";
import { useTranslation } from "react-i18next";

const SystemOrganisation = () => {
  const { t } = useTranslation("systems");

  const {
    systemsDetailsHook: { viewingMode, selectedSystem },
  } = useSystemsContextProvider();

  const {
    isLinkErrorPopupOpen,
    isOrganisationPickerOpen,
    upstreamParentBranch,
    organisationUnitField,
    setOrganisationUnit,
    linkOrganisationUnit,
    unlinkOrganisationUnit,
    closeLinkErrorPopup,
    closeOrganisationPicker,
  } = useSystemOrganisation();

  const parentGatewayId = selectedSystem?.gateway.id;
  const selectedOrganisationUnit = organisationUnitField.value;
  const isViewing = viewingMode === "viewing";

  return (
    <OrganisationUnitTreeProvider.ForLinkedIntegration
      gatewayId={parentGatewayId}
    >
      {selectedOrganisationUnit ? (
        <div className={styles.container}>
          <OrganisationBreadCrumb
            rootNodeId={selectedOrganisationUnit}
            upstreamParentBranch={upstreamParentBranch}
            maxVisibleBreadCrumbs={2}
          />
        </div>
      ) : (
        <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
          {t("details.configuration.noOrganisationUnitLinkedLabel")}
        </Typography>
      )}

      {!isViewing &&
        (selectedOrganisationUnit ? (
          <Button
            variant="outlined"
            onClick={unlinkOrganisationUnit}
            startIcon={<LinkOffIcon />}
          >
            {t("details.configuration.unlinkOrganisationUnitButton")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={linkOrganisationUnit}
            startIcon={<LinkIcon />}
          >
            {t("details.configuration.linkOrganisationUnitButton")}
          </Button>
        ))}

      <OrganisationPicker
        isOpen={isOrganisationPickerOpen}
        onClose={closeOrganisationPicker}
        onOrganisationUnitSelected={(selectedNode, upstreamParentBranch) =>
          setOrganisationUnit(
            organisationUnitField,
            selectedNode,
            upstreamParentBranch,
          )
        }
      />

      <PopUp
        isOpen={isLinkErrorPopupOpen}
        title={t("details.linkSystemError.title", {
          systemName: selectedSystem?.name,
        })}
        body={t("details.linkSystemError.body", {
          systemName: selectedSystem?.name,
        })}
        primaryButtonText={t("details.linkSystemError.confirm")}
        handleOnClose={closeLinkErrorPopup}
        primaryButtonAction={closeLinkErrorPopup}
      />
    </OrganisationUnitTreeProvider.ForLinkedIntegration>
  );
};

export default SystemOrganisation;
