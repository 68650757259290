import { CheckRounded } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useEmergencyAlarmSettingsContextProvider } from "features/emergency-alarm-settings/context/emergency-alarm-settings-provider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import "./emergency-alarm-setting-details-header.scss";
import AccountTreeOutlined from "@mui/icons-material/AccountTreeOutlined";
import AlarmSettingIndicator from "../alarm-setting-indicator";
import OfficeBuildingMarker from "components/custom-icons/office-building-marker";

function EmergencyAlarmSettingDetailsHeader(): ReactElement {
  const { t } = useTranslation("emergencyAlarm");
  const { emergencyAlarmSettingsDetailsHook } =
    useEmergencyAlarmSettingsContextProvider();

  const { organisationUnitId, name, isDefaultTemplateSetting } =
    emergencyAlarmSettingsDetailsHook?.currentSelectedEmergencyAlarmSettings ||
    {};

  const { upsertIsLoading, deleteIsLoading, form } =
    emergencyAlarmSettingsDetailsHook;

  return (
    <div className="emergency-alarm-settings-details-header">
      <div className="header-buttons">
        {organisationUnitId && (
          <AlarmSettingIndicator isDefault={isDefaultTemplateSetting} />
        )}
        {emergencyAlarmSettingsDetailsHook.viewingMode === "editing" && (
          <>
            <Button
              data-testid="gatewayDetailCancelButton"
              variant="text"
              onClick={() => {
                emergencyAlarmSettingsDetailsHook.cancelEditing();
              }}
              disabled={upsertIsLoading || deleteIsLoading}
            >
              {t("details.cancelFormButton")}
            </Button>
            <Button
              data-testid="gatewayDetailSubmitButton"
              startIcon={<CheckRounded />}
              variant="contained"
              type="submit"
              loading={upsertIsLoading || deleteIsLoading}
              disabled={
                !form.watch("divergentSettingEnabled") &&
                isDefaultTemplateSetting
              }
            >
              {t("details.saveFormButton")}
            </Button>
          </>
        )}
        {emergencyAlarmSettingsDetailsHook.viewingMode === "viewing" && (
          <Button
            data-testid="gatewayDetailEditButton"
            startIcon={<ModeEditOutlineOutlinedIcon className="icon-on-text" />}
            variant="text"
            onClick={() =>
              emergencyAlarmSettingsDetailsHook.switchToEditingMode()
            }
          >
            {t("details.editFormButton")}
          </Button>
        )}
      </div>
      <div className="title">
        <Typography variant="h3">{t("emergencyAlarmSettings")}</Typography>
        <div className="location">
          {organisationUnitId ? (
            <OfficeBuildingMarker />
          ) : (
            <AccountTreeOutlined />
          )}
          <Typography variant="body1">
            {organisationUnitId ? name : t("organisationSettings")}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default EmergencyAlarmSettingDetailsHeader;
