import { useEffect, useState } from "react";
import useTableHook, { ITableHook } from "hooks/table-hook";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { useSessionStorage } from "usehooks-ts";
import { useGetMonitorFiltersQuery } from "features/gateway/domain/reducers/gateway.reducer";
import { MonitoringType } from "components/monitoring/monitoring-type";
import Gateway from "features/gateway/domain/models/gateway";
import GatewayFilter from "features/gateway/domain/models/gateway-filter";
import GatewayMonitoringFilter from "features/gateway/domain/models/gateway-monitoring-filter";
import { ReadGatewaysQuery } from "features/gateway/domain/models/read-gateways-query";
import { FilterValueType } from "features/gateway/domain/models/gateway-filter-value";

export interface IGatewayFiltersHook {
  activeFilters: GatewayFilter[];
  searchBarValue: string;
  selectedOrganisationUnits: SelectedOrganisationTreeNode[];
  changeSelectedOrganisationUnits: (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => void;

  submitSearch: (query: string) => void;
  selectOption: (filterValueType: FilterValueType, keys: string[]) => void;
  clearFilters: () => void;

  monitorFilters: GatewayMonitoringFilter[];
  monitorFiltersIsSuccess: boolean;
  getMonitorFilter: (monitoringType: MonitoringType) => GatewayMonitoringFilter;
  loadMonitorFilters: () => void;
  selectMonitorFilter: (
    monitoringType: MonitoringType,
    selected: boolean,
  ) => void;
  isActiveMonitorFilter: (monitoringType: MonitoringType) => boolean;

  readGatewaysQuery: ReadGatewaysQuery;

  table: ITableHook<Gateway>;
}

const useGatewayFilters = (): IGatewayFiltersHook => {
  const [activeFilters, setActiveFilters] = useState<GatewayFilter[]>([]);
  const [readGatewaysQuery, setReadGatewaysQuery] = useState<ReadGatewaysQuery>(
    {
      filters: Array<GatewayFilter>(),
    } as ReadGatewaysQuery,
  );

  const table = useTableHook<Gateway>({
    defaultSort: {
      isAscending: true,
      property: "name",
    },
    sessionStorageKey: "gateway-sort",
  });

  const {
    data: monitorFiltersData,
    isSuccess: monitorFiltersIsSuccess,
    refetch: refetchMonitorFilters,
  } = useGetMonitorFiltersQuery();

  const [
    gatewayFiltersFromSessionStorage,
    setGatewayFiltersFromSessionStorage,
  ] = useSessionStorage<GatewayFilter[]>("gateway-filters", []);
  const [
    selectedOrganisationUnitsFromSessionStorage,
    setSelectedOrganisationUnitsFromSessionStorage,
  ] = useSessionStorage<SelectedOrganisationTreeNode[]>(
    "selected-organisation-units-gateway-filters",
    [],
  );
  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-gateway-filters", "");

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    table.currentPage,
    table.currentRowsPerPage,
    table.sortFromSessionStorage,
    gatewayFiltersFromSessionStorage,
    searchBarValueFromSessionStorage,
  ]);

  const submitSearch = (query: string) => {
    setSearchBarFromSessionStorage(query);
    table.resetPaging();
  };

  const selectOption = (filterValueType: FilterValueType, keys: string[]) => {
    let updatedFiltersList = [...readGatewaysQuery.filters];
    if (
      updatedFiltersList.findIndex(
        (x) => x.filterValueType === filterValueType,
      ) !== -1
    ) {
      updatedFiltersList[
        updatedFiltersList.findIndex(
          (x) => x.filterValueType === filterValueType,
        )
      ] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setGatewayFiltersFromSessionStorage(updatedFiltersList);

    table.resetPaging();
  };

  const changeSelectedOrganisationUnits = (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) =>
    setSelectedOrganisationUnitsFromSessionStorage(selectedOrganisationUnits);

  const clearFilters = () => {
    setActiveFilters([]);
    setGatewayFiltersFromSessionStorage([]);
    setSelectedOrganisationUnitsFromSessionStorage([]);
    setReadGatewaysQuery((prevState) => ({
      ...prevState,
      filters: [],
    }));

    table.resetPaging();
  };

  const getMonitorFilter = (monitoringType: MonitoringType) => {
    let filter = monitorFiltersData?.find(
      (filter) => filter.monitoringFilterType === monitoringType,
    );
    if (filter) {
      return filter;
    }

    return {
      monitoringFilterType: MonitoringType.All,
      count: monitorFiltersData?.reduce(
        (total, filter) => total + filter.count,
        0,
      ),
    } as GatewayMonitoringFilter;
  };

  const isActiveMonitorFilter = (monitoringType: MonitoringType) => {
    let filter = activeFilters.find(
      (x) => x.filterValueType === FilterValueType.Monitor,
    );
    if (!filter) {
      return false;
    }

    return filter.values.find((x) => x === monitoringType) !== undefined;
  };

  const loadMonitorFilters = () => {
    refetchMonitorFilters();
  };

  const selectMonitorFilter = (
    monitoringType: MonitoringType,
    selected: boolean,
  ) => {
    let activeMonitorFilters = [
      ...(activeFilters.find(
        (x) => x.filterValueType === FilterValueType.Monitor,
      )?.values ?? []),
    ];

    if (
      selected &&
      activeMonitorFilters.find((x) => x === monitoringType) === undefined
    ) {
      activeMonitorFilters = [...activeMonitorFilters, monitoringType];
    } else if (
      !selected &&
      activeMonitorFilters.find((x) => x === monitoringType) !== undefined
    ) {
      let activeMonitorFilterIndex = activeMonitorFilters.findIndex(
        (x) => x === monitoringType,
      );
      if (activeMonitorFilterIndex >= 0) {
        activeMonitorFilters.splice(activeMonitorFilterIndex, 1);
      }
    }

    selectOption(FilterValueType.Monitor, activeMonitorFilters);
    loadMonitorFilters();
  };

  function buildAndSaveQuery() {
    setActiveFilters(gatewayFiltersFromSessionStorage);
    setReadGatewaysQuery((prevState) => ({
      ...prevState,
      pagination: table.currentPagination[0],
      sort: table.sortFromSessionStorage ?? prevState.sort,
      filters: gatewayFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
    }));
  }

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,
    selectedOrganisationUnits: selectedOrganisationUnitsFromSessionStorage,
    changeSelectedOrganisationUnits,

    readGatewaysQuery,

    selectOption,
    submitSearch,
    clearFilters,

    monitorFiltersIsSuccess,
    monitorFilters: monitorFiltersData ?? [],
    getMonitorFilter,
    loadMonitorFilters,
    selectMonitorFilter,
    isActiveMonitorFilter,

    table,
  };
};

export default useGatewayFilters;
