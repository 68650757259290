import { Divider as MuiDivider, Typography } from "@mui/material";
import styles from "./divider.component.module.scss";
import { ReactElement } from "react";
import { Variant } from "@mui/material/styles/createTypography";

interface IProps {
  className?: string;
  startText?: string;
  endElement?: ReactElement;
  variant?: Variant;
}

function Divider({
  className,
  startText,
  endElement,
  variant,
}: Readonly<IProps>): ReactElement {
  return (
    <div className={`${styles.dividerContainer} ${className}`}>
      {startText && (
        <Typography className={styles.startText} variant={variant ?? "h5"}>
          {startText}
        </Typography>
      )}
      <MuiDivider />
      {endElement}
    </div>
  );
}

export default Divider;
