import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "components/bread-crumb/bread-crumb-tooltip.module.scss";
import { Tooltip, Typography, Zoom } from "@mui/material";
import React, { ReactElement } from "react";

interface Props {
  children: ReactElement;
  allElementNames: string | Array<string>;
}

const BreadCrumbTooltip = ({ children, allElementNames }: Readonly<Props>) => {
  const getTooltipElement = () => {
    const allElementNamesArray = Array.isArray(allElementNames)
      ? allElementNames
      : [allElementNames];

    return (
      <div className={styles.tooltip}>
        {allElementNamesArray.map((name, index, { length }) => (
          <React.Fragment key={`Breadcrumb_Tooltip_Child_${name}`}>
            <Typography className={styles.typography} variant="caption">
              {name}
            </Typography>
            {index + 1 < length && (
              <ChevronRightIcon className={styles.chevronRightIcon} />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <Tooltip
      title={getTooltipElement()}
      followCursor
      slots={{
        transition: Zoom,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default BreadCrumbTooltip;
