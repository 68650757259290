import { Switch, Typography } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-acoustic-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Divider from "components/divider/divider.component";
import { Controller, useFormContext } from "react-hook-form";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";
import { IS32AccordionType } from "features/systems/IS32/domain/models/is32-accordion-type";
import IS32ConfigurationAccordion from "features/systems/IS32/views/configuration-components/IS32-configuration-accordion.component";

interface Props {
  accordionEnabled: boolean;
}

const IS32AcousticConfiguration = ({ accordionEnabled }: Props) => {
  const { t } = useTranslation("IS32");

  const {
    selectedIS32ConfigurationUnit,
    viewingMode,
    is32AccordionHook: { acousticExpanded, handleAccordionExpandedChange },
  } = useIS32DetailsContextProvider();
  const { control } = useFormContext();

  const disabled =
    selectedIS32ConfigurationUnit === undefined || viewingMode === "viewing";

  const header = () => (
    <Divider
      className={styles.divider}
      startText={t("configuration.acousticConfiguration.title")}
      variant="h6"
    />
  );

  const details = () => (
    <div className={styles.contentContainer}>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t("configuration.acousticConfiguration.acousticDelay")}
        </Typography>
        <Controller
          name="acousticMonitoringWait30Seconds"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="acousticMonitoringWait30SecondsInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <>
      {accordionEnabled ? (
        <IS32ConfigurationAccordion
          header={header()}
          details={details()}
          isExpanded={acousticExpanded}
          handleAccordionExpandedChange={(expanded) =>
            handleAccordionExpandedChange(IS32AccordionType.Acoustic, expanded)
          }
        />
      ) : (
        <>
          {header()}
          {details()}
        </>
      )}
    </>
  );
};

export default IS32AcousticConfiguration;
