import MonitoringAlert from "components/monitoring/monitoring-alert.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import Gateway from "features/gateway/domain/models/gateway";
import { GatewayStatus } from "features/gateway/domain/models/gateway-status";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface Props {
  gateway?: Gateway;
}

const GatewayMonitoringAlert = ({ gateway }: Props) => {
  const { t } = useTranslation("monitoring");

  switch (gateway?.status) {
    case GatewayStatus.offline:
      return (
        <MonitoringAlert
          text={t("messages.gatewayOffline")}
          type={MonitoringType.GatewayOffline}
          color={Constants.Colors.error}
        />
      );
    case GatewayStatus.serviceOfflineOnly:
      return (
        <MonitoringAlert
          text={t("messages.gatewayServiceOffline")}
          type={MonitoringType.GatewayServiceOffline}
          color={Constants.Colors.brightOrange}
        />
      );
    case GatewayStatus.passiveOfflineOnly: {
      const offlineChildGateway = gateway?.childGateways?.find(
        (item) => item.status === GatewayStatus.offline,
      );
      if (offlineChildGateway) {
        return (
          <MonitoringAlert
            text={t("messages.passiveGatewayOfflineWithName", {
              activeOrPassive: offlineChildGateway.isActiveInCluster
                ? t("messages.active")
                : t("messages.passive"),
              gatewayName: offlineChildGateway.name || "",
            })}
            type={MonitoringType.PassiveGatewayOffline}
            color={Constants.Colors.brightOrange}
          />
        );
      } else return <></>;
    }
    default:
      return <></>;
  }
};

export default GatewayMonitoringAlert;
