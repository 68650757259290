import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import {
  IconButton,
  InputAdornment,
  InputLabelProps,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CopyButton from "components/copy-button/copy-button.component";
import SelectField from "components/formFields/select-field.component";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import useDeviceValidation from "features/device/device-details/hooks/device-validation-hook";
import DeviceMonitoringAlert from "features/device/device-details/views/components/device-monitoring-alert";
import "features/device/device-details/views/device-details-popup-information.component.scss";
import DeviceSettingsType from "features/device/device-details/views/device-details-settings/device-settings-type";
import { BatteryStatus } from "features/device/domain/models/battery-status";
import { useGetLinkableGatewaysQuery } from "features/device/domain/reducers/device.reducer";
import { DeviceType } from "features/device/models/device-type";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { ReadSystemsQuery } from "features/systems/domain/models/read-systems-query";
import { useReadSystemsQuery } from "features/systems/domain/reducers/systems.reducer";
import { SystemType } from "features/systems/domain/models/system";
import moment from "moment";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux-base/store";
import Constants from "style/constants";

const DeviceDetailsPopupInformation = () => {
  const { t } = useTranslation("deviceDetails");
  const dispatch = useAppDispatch();

  const {
    getValues,
    formState: { errors },
    control,
    trigger,
    watch,
  } = useFormContext();
  const watchGatewayId = watch("gatewayId");
  const watchDeviceType = watch("deviceType");
  const { validateName, validateExternalId } = useDeviceValidation();

  const registerOptions = {
    name: {
      required: t("info.requiredHelperText"),
      validate: validateName,
    },
    externalId: {
      required: t("info.requiredHelperText"),
      validate: validateExternalId,
    },
    gatewayId: {
      required: t("info.requiredHelperText"),
    },
    systemId: {
      required: t("info.requiredHelperText"),
    },
  };

  const {
    currentViewingMode,
    currentDevice,
    currentDeviceType,
    generateExternalId,
  } = useDeviceDetailsHook();

  const isConnectedToSystem = currentDevice?.systemId;
  const isIs32Device = currentDevice?.system?.type === SystemType.IS32;

  const { data: gatewaysResponse, error: gatewaysResponseError } =
    useGetLinkableGatewaysQuery(currentDeviceType ?? "");

  let systemQuery = {} as ReadSystemsQuery;
  const { data: systemsResponse, error: systemsResponseError } =
    useReadSystemsQuery(systemQuery);

  useEffect(() => {
    if (gatewaysResponseError) {
      dispatch(setErrorMessage({ error: gatewaysResponseError }));
    }
  }, [gatewaysResponseError, dispatch]);

  useEffect(() => {
    if (systemsResponseError) {
      dispatch(
        setErrorMessage({
          error: systemsResponseError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemsResponseError]);

  const inputLabelProps: Partial<InputLabelProps> =
    currentDevice?.importDate != null ? { shrink: true } : {};

  useEffect(() => {
    if (currentViewingMode === "creation" || currentViewingMode === "editing") {
      const currentNameValue = getValues("name");
      if (currentNameValue && currentNameValue.length > 0) {
        trigger("name");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchGatewayId, watchDeviceType, currentViewingMode]);

  function showField(field: string): boolean {
    if (currentViewingMode !== "viewing") {
      return true;
    }

    const value = getValues(field)?.toString();
    return value && value.trim() !== "";
  }

  const showMonitoringContainer =
    currentDevice?.isOutdated ||
    currentDevice?.deviceMonitoring?.isOffline ||
    currentDevice?.deviceMonitoring?.batteryStatus === BatteryStatus.Critical ||
    currentDevice?.deviceMonitoring?.batteryStatus === BatteryStatus.Empty ||
    currentDevice?.deviceMonitoring?.batteryStatus === BatteryStatus.Low;

  return (
    <div className="device-details-information-container">
      <Typography
        variant="h2"
        className="device-title"
        data-testid="devicePopupTitle"
      >
        {currentViewingMode === "creation" && t("createDeviceTitle")}
        {currentViewingMode === "editing" && t("editDeviceTitle")}
        {currentViewingMode === "viewing" && currentDevice?.name}
      </Typography>

      {showMonitoringContainer && (
        <div className="monitoring-container">
          {currentDevice?.isOutdated && <DeviceMonitoringAlert.Outdated />}
          {currentDevice?.deviceMonitoring?.isOffline && (
            <DeviceMonitoringAlert.Offline
              lastChangedOn={
                currentDevice.deviceMonitoring.lastMessageReceivedAt
              }
            />
          )}
          {(currentDevice?.deviceMonitoring?.batteryStatus ===
            BatteryStatus.Critical ||
            currentDevice?.deviceMonitoring?.batteryStatus ===
              BatteryStatus.Empty) && (
            <DeviceMonitoringAlert.BatteryCritical
              lastChangedOn={
                currentDevice.deviceMonitoring.batteryStatusChangedAt
              }
              message={currentDevice.deviceMonitoring.message}
            />
          )}
          {currentDevice?.deviceMonitoring?.batteryStatus ===
            BatteryStatus.Low && (
            <DeviceMonitoringAlert.BatteryLow
              lastChangedOn={
                currentDevice.deviceMonitoring.batteryStatusChangedAt
              }
              message={currentDevice.deviceMonitoring.message}
            />
          )}
        </div>
      )}

      <div
        className="information-part-container"
        data-testid="deviceTypeContainer"
      >
        {currentViewingMode !== "creation" && (
          <div className="icon-text">
            <Typography variant="h5">{t("info.typeLabel")}</Typography>
          </div>
        )}
        <DeviceSettingsType />
      </div>

      {currentDeviceType && currentDeviceType !== DeviceType.Unknown && (
        <>
          <div className="information-part-container">
            <ViewingModeController
              viewingMode={currentViewingMode}
              name="name"
              control={control}
              rules={registerOptions.name}
              label={t("info.nameLabel")}
              render={({ field }) => (
                <TextField
                  {...field}
                  slotProps={{
                    input: {
                      disabled: currentDevice?.importDate != null,
                    },
                    htmlInput: {
                      "data-testid": "nameInput",
                      maxLength: 255,
                    },
                  }}
                  id="nameInput"
                  label={t("info.nameLabel")}
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors.name?.message?.toString()}
                  className="form-field"
                />
              )}
            />
          </div>
          <div
            className="information-part-container"
            data-testid="devicePopupExternalIdContainer"
          >
            <ViewingModeController
              viewingMode={currentViewingMode}
              name="externalId"
              control={control}
              rules={registerOptions.externalId}
              label={t("info.externalId")}
              render={({ field }) => (
                <TextField
                  {...field}
                  slotProps={{
                    input: {
                      endAdornment: currentDeviceType !== DeviceType.NFC &&
                        !isIs32Device && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                generateExternalId();
                                trigger("externalId");
                              }}
                              edge="end"
                            >
                              {currentDevice?.importDate == null && (
                                <AutorenewRoundedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      disabled:
                        currentDevice?.importDate != null || isIs32Device,
                    },
                  }}
                  variant="outlined"
                  id="externalIdInput"
                  label={t("info.externalId")}
                  error={!!errors.externalId}
                  helperText={errors.externalId?.message?.toString()}
                  className="form-field"
                />
              )}
              display={
                <div className="functional-id-container">
                  <Typography
                    variant="subtitle1"
                    data-testid="viewingModeControllerDisplayValue"
                  >
                    {currentDevice?.externalId}
                  </Typography>
                  <CopyButton contentToCopy={currentDevice?.externalId} />
                </div>
              }
            />
          </div>
          {isConnectedToSystem ? (
            <div
              className="information-part-container"
              data-testid="devicePopupSystemContainer"
            >
              <ViewingModeController
                viewingMode={currentViewingMode}
                name="systemId"
                rules={registerOptions.systemId}
                control={control}
                displayValue={
                  systemsResponse?.systems.find(
                    (system) => system.id === currentDevice?.systemId,
                  )?.name ?? t("info.notFound")
                }
                label={t("info.systemLabel")}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    disabled={
                      currentDevice?.importDate != null ||
                      currentViewingMode === "editing"
                    }
                    inputProps={{
                      "data-testid": "systemInput",
                      disabled:
                        currentDevice?.importDate != null ||
                        currentViewingMode === "editing",
                    }}
                    id="systemInput"
                    label={t("info.systemLabel")}
                    variant="outlined"
                    error={!!errors.systemId}
                    helperText={errors?.systemId?.message?.toString()}
                    className="form-field"
                    data-testid="systemSelect"
                    onClick={() => trigger("systemId")}
                  >
                    {systemsResponse?.systems.map((system) => (
                      <MenuItem
                        data-testid={`systemOption${system.id}`}
                        key={`gateway-${system.id}`}
                        value={system.id}
                      >
                        {system.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              />
            </div>
          ) : (
            <div
              className="information-part-container"
              data-testid="devicePopupGatewayContainer"
            >
              <ViewingModeController
                viewingMode={currentViewingMode}
                name="gatewayId"
                rules={registerOptions.gatewayId}
                control={control}
                displayValue={
                  currentDevice?.gateway?.name ?? t("info.notFound")
                }
                label={t("info.gatewayLabel")}
                render={({ field }) => (
                  <SelectField
                    {...field}
                    disabled={
                      currentDevice?.importDate != null ||
                      currentViewingMode === "editing"
                    }
                    inputProps={{
                      "data-testid": "gatewayInput",
                      disabled:
                        currentDevice?.importDate != null ||
                        currentViewingMode === "editing",
                    }}
                    id="gatewayInput"
                    label={t("info.gatewayLabel")}
                    variant="outlined"
                    error={!!errors.gatewayId}
                    helperText={errors?.gatewayId?.message?.toString()}
                    className="form-field"
                    data-testid="gatewaySelect"
                    onClick={() => trigger("gatewayId")}
                  >
                    {gatewaysResponse?.gateways.map((gateway) => (
                      <MenuItem
                        data-testid={`gatewayOption${gateway.id}`}
                        key={`gateway-${gateway.id}`}
                        value={gateway.id}
                      >
                        {gateway.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              />
            </div>
          )}
          {showField("note") && (
            <div
              className="information-part-container"
              data-testid="devicePopupNoteContainer"
            >
              <ViewingModeController
                viewingMode={currentViewingMode}
                name="note"
                control={control}
                label={t("info.noteLabel")}
                hideOnViewingWhenEmpty
                render={({ field }) => (
                  <TextField
                    {...field}
                    slotProps={{
                      htmlInput: {
                        "data-testid": "noteInput",
                        maxLength: 255,
                      },
                    }}
                    id="noteInput"
                    label={t("info.noteLabel")}
                    variant="outlined"
                    error={!!errors.note}
                    helperText={errors.note?.message?.toString()}
                    multiline
                    minRows={8}
                    maxRows={12}
                    className="form-field"
                  />
                )}
              />
            </div>
          )}
          {currentViewingMode !== "creation" &&
            currentDevice?.source &&
            currentDevice?.source !== undefined &&
            currentDevice?.source !== "" && (
              <div
                className="information-part-container"
                data-testid="devicePopupAddressContainer"
              >
                <ViewingModeController
                  viewingMode={currentViewingMode}
                  name="source"
                  control={control}
                  label={t("info.sourceLabel")}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        input: {
                          disabled:
                            currentDevice?.importDate != null || isIs32Device,
                        },
                        htmlInput: {
                          "data-testid": "sourceInput",
                          maxLength: 255,
                        },
                      }}
                      id="sourceInput"
                      label={t("info.sourceLabel")}
                      variant="outlined"
                      error={!!errors.source}
                      helperText={errors.source?.message?.toString()}
                      className="form-field"
                    />
                  )}
                />
              </div>
            )}
          {currentDeviceType !== DeviceType.NFC &&
            currentDeviceType !== DeviceType.BLEBeacon &&
            (currentDevice?.address || currentViewingMode !== "viewing") && (
              <div
                className="information-part-container"
                data-testid="devicePopupAddressContainer"
              >
                <ViewingModeController
                  viewingMode={currentViewingMode}
                  hideOnViewingWhenEmpty
                  name="address"
                  control={control}
                  label={t("info.addressLabel")}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      slotProps={{
                        inputLabel: inputLabelProps,
                        input: {
                          disabled:
                            currentDevice?.importDate != null || isIs32Device,
                        },
                        htmlInput: {
                          "data-testid": "addressInput",
                          maxLength: 255,
                        },
                      }}
                      id="addressInput"
                      label={t("info.addressLabel")}
                      variant="outlined"
                      error={!!errors.address}
                      helperText={errors.address?.message?.toString()}
                      className="form-field"
                    />
                  )}
                />
              </div>
            )}
          {currentDevice?.deviceMonitoring?.lastMessageReceivedAt && (
            <div className="information-part-container">
              <Typography
                variant="h5"
                sx={{ color: Constants.Colors.onSurface }}
              >
                {t("info.status")}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: Constants.Colors.onSurface }}
              >
                {`${t("info.lastSeenAt")} ${moment(
                  currentDevice.deviceMonitoring.lastMessageReceivedAt,
                )
                  .format("DD-MM-YYYY HH:mm")
                  .toString()}`}
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DeviceDetailsPopupInformation;
