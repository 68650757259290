import { Link as LinkIcon, LinkOff as LinkOffIcon } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import PopUp from "components/pop-up/pop-up.component";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import OrganisationPicker from "features/organisation/views/organisation-picker/organisation-picker";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-organisation.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import useGatewayOrganisation from "features/gateway/hooks/gateway-settings-organisation-hook";

const GatewaySettingsOrganisation = () => {
  const { t } = useTranslation("gateway");
  const { gatewayDetailsHook } = useGatewaysContextProvider();
  const {
    isLinkErrorPopupOpen,
    isOrganisationPickerOpen,
    upstreamParentBranch,
    organisationUnitField,
    setOrganisationUnit,
    linkOrganisationUnit,
    unlinkOrganisationUnit,
    closeLinkErrorPopup,
    closeOrganisationPicker,
  } = useGatewayOrganisation();

  const isEditing = gatewayDetailsHook.viewingMode === "editing";
  const isCreating = gatewayDetailsHook.viewingMode === "creation";
  const showActionButtons = isEditing || isCreating;

  const organisationUnitId = showActionButtons
    ? organisationUnitField.value
    : gatewayDetailsHook.currentSelectedGateway?.organisationUnitId;

  const hasOrganisationUnit = organisationUnitId != null;
  const gatewayName = gatewayDetailsHook.currentSelectedGateway?.name ?? "";

  return (
    <>
      {hasOrganisationUnit ? (
        <div className={styles.container}>
          <OrganisationBreadCrumb
            rootNodeId={organisationUnitId}
            upstreamParentBranch={upstreamParentBranch}
          />
        </div>
      ) : (
        <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
          {t("details.settings.noOrganisationUnitLinkedLabel")}
        </Typography>
      )}

      {showActionButtons &&
        (hasOrganisationUnit ? (
          <Button
            variant="outlined"
            onClick={unlinkOrganisationUnit}
            startIcon={<LinkOffIcon />}
            data-testid="unlinkOrganisationButton"
          >
            {t("details.settings.unlinkOrganisationUnitButton")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            onClick={linkOrganisationUnit}
            startIcon={<LinkIcon />}
            data-testid="linkOrganisationButton"
          >
            {t("details.settings.linkOrganisationUnitButton")}
          </Button>
        ))}

      <OrganisationPicker
        isOpen={isOrganisationPickerOpen}
        onClose={closeOrganisationPicker}
        onOrganisationUnitSelected={(selectedNode, upstreamParentBranch) =>
          setOrganisationUnit(
            organisationUnitField,
            selectedNode,
            upstreamParentBranch,
          )
        }
      />

      <PopUp
        isOpen={isLinkErrorPopupOpen}
        title={t("linkGatewayError.title", { gatewayName })}
        body={t("linkGatewayError.bodyText", { gatewayName })}
        primaryButtonText={t("linkGatewayError.confirmButton")}
        handleOnClose={closeLinkErrorPopup}
        primaryButtonAction={closeLinkErrorPopup}
      />
    </>
  );
};

export default GatewaySettingsOrganisation;
