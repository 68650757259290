import { ReactElement, useEffect, useState } from "react";
import "./history-filters.scss";
import FilterChip from "components/filter-chip/filter-chip.component";
import KeyValuePair from "models/key-value-pair";
import { useTranslation } from "react-i18next";
import { HistoryItem } from "../domain/models/history-item";
import { HistoryItemType } from "../domain/models/history-item-type";
import FilterChipDateTime from "components/filter-chip/filter-chip-date-time.component";
import { DateFilters } from "components/filter-chip/models/date-filters";
import { Typography } from "@mui/material";
import Constants from "style/constants";
import { useSessionStorage } from "usehooks-ts";
import FilterChipOrganisationUnit from "components/organisation-unit-filter-chip/filter-chip-organisation-unit.component";
import { AlarmStatus } from "../domain/models/alarm-status";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import { FilterOption } from "../domain/models/read-history-response";
import { FilterChipOption } from "components/multi-value-filter-chip/models/filter-chip-option";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";

interface IProps {
  historyItems: HistoryItem[];
  onFilterChanged: (filters: HistoryFilterValues) => void;
  filterOptions: FilterOption[];
  allMessageTypes?: string[];
}

export interface HistoryFilterValues {
  categoryFilter?: string;
  typeFilters?: string[];
  organisationUnitFilters?: SelectedOrganisationTreeNode[];
  dateFilters?: DateFilters;
  diversionFilter?: string;
  statusFilter?: string;
}

function HistoryFilters(props: Readonly<IProps>): ReactElement {
  const sessionStorageFiltersKey = "history-filters";
  const defaultDateFilters = {
    dateFrom: null,
    dateTo: null,
  };

  const { t } = useTranslation("history");
  const [filtersFromSessionStorage, setFiltersFromSessionStorage] =
    useSessionStorage<HistoryFilterValues>(sessionStorageFiltersKey, {});

  const [filters, setFilters] = useState<HistoryFilterValues>(
    filtersFromSessionStorage,
  );

  function getCategoryFilterValues(): KeyValuePair[] {
    return [
      {
        key: HistoryItemType.Alarm,
        value: t("category.Alarm"),
      },
      {
        key: HistoryItemType.Status,
        value: t("category.Status"),
      },
    ];
  }

  function getStatusFilterValues(): KeyValuePair[] {
    return [
      {
        key: AlarmStatus.Reported,
        value: t("status.Reported"),
      },
      {
        key: AlarmStatus.Accepted,
        value: t("status.Accepted"),
      },
      {
        key: AlarmStatus.Handled,
        value: t("status.Handled"),
      },
      {
        key: AlarmStatus.Cancelled,
        value: t("status.Cancelled"),
      },
    ];
  }

  function getTypeFilterValues(): FilterChipOption[] {
    return getTypeFiltersWhichShouldShowType()
      .concat(getTypeFiltersWhichShouldShowMessage())
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  function getTypeFiltersWhichShouldShowMessage(): FilterChipOption[] {
    const distinctValues =
      props.allMessageTypes?.filter(
        (value, index, self) => self.indexOf(value) === index,
      ) ?? [];

    return distinctValues.map((x) => ({
      key: x,
      value: [x],
      label: x,
    }));
  }

  function getTypeFiltersWhichShouldShowType(): FilterChipOption[] {
    return props.filterOptions.map((option) => ({
      key: option.key,
      value: option.value,
      label: t(`type.filters.${option.key}`, option.key),
    }));
  }

  function getDiversionFilterValues(): KeyValuePair[] {
    return [
      {
        key: "true",
        value: t("diversion.true"),
      },
      {
        key: "false",
        value: t("diversion.false"),
      },
    ];
  }

  function getSelectedFilterOptions(
    selectedKeys?: string[],
  ): FilterChipOption[] {
    if (!selectedKeys) return [];

    const allOptions = getTypeFilterValues();
    return allOptions.filter((option) => selectedKeys.includes(option.key));
  }

  useEffect(() => {
    if (filters !== filtersFromSessionStorage) {
      props.onFilterChanged(filters);
      setFiltersFromSessionStorage(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function clearFilters(): void {
    setFilters({});
  }

  return (
    <div className="history-filters">
      <FilterChip
        key={`history-filter-chip-category`}
        options={getCategoryFilterValues()}
        onOptionSelected={(key?: string) =>
          setFilters({ ...filters, categoryFilter: key })
        }
        placeHolder={t("historyTable.column.category")}
        initialSelectedOption={getCategoryFilterValues().find(
          (x) => x.key === filters.categoryFilter,
        )}
      />
      <MultiValueFilterChip
        key={`history-filter-chip-type`}
        options={getTypeFilterValues()}
        onOptionsSelected={(keys: string[]) =>
          setFilters({ ...filters, typeFilters: keys })
        }
        placeHolder={t("historyTable.column.type")}
        selectedOptions={getSelectedFilterOptions(filters.typeFilters)}
      />
      <OrganisationUnitTreeProvider.AccessRightsOnly>
        <FilterChipOrganisationUnit
          key={`history-filter-chip-organisation-unit`}
          onOptionsSelected={(keys) =>
            setFilters({ ...filters, organisationUnitFilters: keys })
          }
          selectedOptions={filters.organisationUnitFilters ?? []}
          placeHolder={t("historyTable.column.breadCrumbs")}
        />
      </OrganisationUnitTreeProvider.AccessRightsOnly>
      <FilterChipDateTime
        key={"history-filter-chip-createdOn"}
        placeHolder={t("historyTable.column.createdOn")}
        currentDateFilters={filters.dateFilters ?? defaultDateFilters}
        onDateFiltersChanged={(newDateFilters) =>
          setFilters({ ...filters, dateFilters: newDateFilters })
        }
        includeTime={true}
        onlyHistory={true}
      />
      <FilterChip
        key={`history-filter-chip-diversion`}
        options={getDiversionFilterValues()}
        onOptionSelected={(key?: string) =>
          setFilters({ ...filters, diversionFilter: key })
        }
        placeHolder={t("isDiverted")}
        initialSelectedOption={getDiversionFilterValues().find(
          (x) => x.key === filters.diversionFilter,
        )}
      />
      <FilterChip
        key={`history-filter-chip-status`}
        options={getStatusFilterValues()}
        onOptionSelected={(key?: string) =>
          setFilters({ ...filters, statusFilter: key })
        }
        placeHolder={t("alarmStatus")}
        initialSelectedOption={getStatusFilterValues().find(
          (x) => x.key === filters.statusFilter,
        )}
      />

      <div className="clear-filters">
        <Typography
          variant="body1"
          sx={{ color: Constants.Colors.primary }}
          onClick={clearFilters}
        >
          {t("clearFilters")}
        </Typography>
      </div>
    </div>
  );
}

export default HistoryFilters;
