import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-kadex.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import useGatewayValidation from "features/gateway/hooks/gateway-validation-hook";

const GatewaySettingsKadex = () => {
  const { t } = useTranslation("gateway");

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { validateIpAddress } = useGatewayValidation();
  const [showPassword, setShowPassword] = useState(false);

  const registerOptions = {
    kadexIpAddress: {
      validate: validateIpAddress,
    },
  };

  const isViewing = gatewayDetailsHook.viewingMode === "viewing";
  const currentGateway = gatewayDetailsHook.currentSelectedGateway;

  const hasNoIpAddress =
    !currentGateway?.kadexIpAddress || currentGateway?.kadexIpAddress === "";
  const hasNoUsername =
    !gatewayDetailsHook.currentSelectedGateway?.kadexUsername ||
    gatewayDetailsHook.currentSelectedGateway?.kadexUsername === "";
  const hasNoPassword =
    !gatewayDetailsHook.currentSelectedGateway?.kadexPassword ||
    gatewayDetailsHook.currentSelectedGateway?.kadexPassword === "";

  const hiddenPassword = "**********";

  return (
    <>
      {isViewing && (
        <div className={`${styles.container} ${styles.viewing}`}>
          <Typography variant="subtitle1" data-testid="ipAddressLabel">
            {t("details.settings.kadex.ipAddress")}:{" "}
            {hasNoIpAddress
              ? t(`details.settings.kadex.noIpAddressDefined`)
              : currentGateway?.kadexIpAddress}
          </Typography>
          <Typography variant="subtitle1" data-testid="usernameLabel">
            {t("details.settings.kadex.username")}:{" "}
            {hasNoUsername
              ? t(`details.settings.kadex.noUsernameDefined`)
              : currentGateway?.kadexUsername}
          </Typography>
          <Typography variant="subtitle1" data-testid="passwordLabel">
            {t("details.settings.kadex.password")}:{" "}
            {hasNoPassword
              ? t(`details.settings.kadex.noPasswordDefined`)
              : hiddenPassword}
          </Typography>
        </div>
      )}
      {!isViewing && (
        <div className={styles.container}>
          <Controller
            name={"kadexIpAddress"}
            control={control}
            rules={registerOptions.kadexIpAddress}
            render={({ field }) => (
              <TextField
                {...field}
                slotProps={{
                  htmlInput: { "data-testid": `kadexIpAddress` },
                }}
                id="kadexIpAddress"
                label={t("details.settings.kadex.ipAddress")}
                variant="outlined"
                error={!!errors.kadexIpAddress}
                helperText={errors.kadexIpAddress?.message?.toString()}
                className={styles.textfield}
              />
            )}
          />
          <Controller
            name={"kadexUsername"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                slotProps={{
                  htmlInput: { "data-testid": `kadexUsername` },
                }}
                id="kadexUsername"
                label={t("details.settings.kadex.username")}
                variant="outlined"
                className={styles.textfield}
              />
            )}
          />
          <Controller
            name={"kadexPassword"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                slotProps={{
                  htmlInput: { "data-testid": `kadexPassword` },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                id="kadexPassword"
                label={t("details.settings.kadex.password")}
                variant="outlined"
                className={styles.textfield}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default GatewaySettingsKadex;
