import MonitoringAlert from "components/monitoring/monitoring-alert.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { System } from "features/systems/domain/models/system";
import { SystemStatus } from "features/systems/domain/models/system-status";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface Props {
  system?: System;
}

const SystemMonitoringAlert = ({ system }: Props) => {
  const { t } = useTranslation("monitoring");

  switch (system?.status) {
    case SystemStatus.offline:
      return (
        <MonitoringAlert
          text={t("messages.systemOffline")}
          type={MonitoringType.SystemOffline}
          color={Constants.Colors.error}
        />
      );
    default:
      return <></>;
  }
};

export default SystemMonitoringAlert;
