import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckRounded from "@mui/icons-material/CheckRounded";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import "./header.component.scss";
import { ViewingMode } from "utils/viewing-utils";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Permission from "features/autorisation/domain/models/permission";
import { ReactElement } from "react";

interface IProps {
  hasErrors: boolean;
  viewingMode: ViewingMode;
  selectedNodeIsroot: boolean;
  headerText?: string;
  isScrolling?: boolean;
  isSubmitting: boolean;
  onCancelButtonClicked: () => void;
  onRemoveButtonClicked: () => void;
  onViewingModeChanged: (viewingMode: ViewingMode) => void;
}

export function Header(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("organisation");

  const creationMode = (
    <div className="top-header-container">
      {props.hasErrors && (
        <Typography variant="caption" className="error">
          {t("validationErrorsSummaryText")}
        </Typography>
      )}
      <div className="actions-container">
        <Button
          data-testid="cancelButton"
          variant="text"
          onClick={props.onCancelButtonClicked}
        >
          {t("cancelFormButton")}
        </Button>
        <Button
          data-testid="submitButton"
          startIcon={<CheckRounded />}
          variant="contained"
          type="submit"
          loading={props.isSubmitting}
          loadingPosition="start"
        >
          {t("saveFormButton")}
        </Button>
      </div>
    </div>
  );

  const viewingMode = (
    <div className="actions-container">
      {!props.selectedNodeIsroot && (
        <>
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.DeleteOrganisationUnit]}
          >
            <Button
              data-testid="removeButton"
              startIcon={<DeleteForeverOutlinedIcon />}
              variant="text"
              onClick={() => props.onRemoveButtonClicked()}
            >
              {t("removeFormButton")}
            </Button>
          </AutorisationWrapper>
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.UpdateOrganisationUnit]}
          >
            <Button
              data-testid="editButton"
              startIcon={<ModeEditOutlineOutlinedIcon />}
              variant="text"
              onClick={() => props.onViewingModeChanged("editing")}
            >
              {t("editFormButton")}
            </Button>
          </AutorisationWrapper>
        </>
      )}
    </div>
  );

  return (
    <div className={`header-container ${props.isScrolling && "is-scrolling"}`}>
      {(props.viewingMode === "creation" || props.viewingMode === "editing") &&
        creationMode}
      {props.viewingMode === "viewing" && viewingMode}
      <Typography
        variant="h4"
        className="title ellipsis"
        data-testid="organisationDetailHeader"
      >
        {props.viewingMode === "creation" && t("organisationUnitHeaderTitle")}
        {(props.viewingMode === "viewing" || props.viewingMode === "editing") &&
          props.headerText}
      </Typography>
    </div>
  );
}

export default Header;
