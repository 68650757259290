export enum MonitoringType {
  Offline = "Offline",
  Online = "Online",
  BatteryCritical = "BatteryCritical",
  BatteryLow = "BatteryLow",
  OutDated = "OutDated",
  GatewayOffline = "GatewayOffline",
  GatewayOnline = "GatewayOnline",
  All = "All",
  NotMonitored = "NotMonitored",
  PassiveGatewayOffline = "PassiveGatewayOffline",
  GatewayServiceOffline = "GatewayServiceOffline",
  SystemOffline = "SystemOffline",
  SystemOnline = "SystemOnline",
  SystemWarning = "SystemWarning",
}
