import KeyValuePair from "models/key-value-pair";

export default interface SystemFilterValue {
  filterValueType: SystemFilterValueType;
  values: KeyValuePair[];
}

export enum SystemFilterValueType {
  Type = "Type",
  OrganisationUnit = "OrganisationUnit",
  Monitor = "Monitor",
}
