import { useTranslation } from "react-i18next";
import PopUp from "components/pop-up/pop-up.component";
import { useIS32ContextProvider } from "../context/IS32-provider";

const IS32SystemEventPopup = () => {
  const { t } = useTranslation("IS32");

  const {
    inProgressPopupIsOpen,
    succeededPopupIsOpen,
    failedPopupIsOpen,
    closePopup,
    lastScanSucceededResult,
  } = useIS32ContextProvider();

  return (
    <>
      <PopUp
        isOpen={inProgressPopupIsOpen}
        title={t("inProgressPopup.title")}
        body={t("inProgressPopup.body")}
        showLoadingIndicator={true}
      />
      <PopUp
        isOpen={succeededPopupIsOpen}
        title={t("succeededPopup.title")}
        body={
          lastScanSucceededResult?.numberOfNewDevices === 1
            ? t("succeededPopup.bodySingular", {
                numberOfNewDevices: lastScanSucceededResult?.numberOfNewDevices,
              })
            : t("succeededPopup.bodyPlural", {
                numberOfNewDevices: lastScanSucceededResult?.numberOfNewDevices,
              })
        }
        primaryButtonAction={() => closePopup()}
        primaryButtonText={t("succeededPopup.primaryActionButtonText")}
      />
      <PopUp
        isOpen={failedPopupIsOpen}
        title={t("failedPopup.title")}
        body={t("failedPopup.body")}
        primaryButtonAction={() => closePopup()}
        primaryButtonText={t("failedPopup.primaryActionButtonText")}
      />
    </>
  );
};

export default IS32SystemEventPopup;
