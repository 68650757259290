import AcousticConfiguration from "features/residents/residents-settings/domain/models/configurations/acoustic-configuration";
import BaseAlarmScenarionConfiguration from "features/residents/residents-settings/domain/models/configurations/base-alarm-scenario-configuration";
import BaseSettingScenarioConfiguration from "features/residents/residents-settings/domain/models/configurations/base-setting-scenario-configuration";
import VideoConfiguration from "features/residents/residents-settings/domain/models/configurations/video-configuration";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import AcousticScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/acoustic-scenario-configuration.component";
import AlarmScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/alarm-scenario-configuration.component";
import BedScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/bed-scenario-configuration.component";
import ConfigurationRow from "features/residents/residents-settings/views/resident-scenario-row/configuration-row";
import AlarmOnlyScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/alarm-only-scenario-configuration";
import SettingScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/setting-scenario-configuration.component";
import VideoScenarioConfiguration from "features/residents/residents-settings/views/resident-scenario-row/video-scenario-configuration.component";
import { ScenarioType } from "models/scenario-type";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import { ViewingMode } from "utils/viewing-utils";
import BaseAlarmOnlyScenarioConfiguration from "../../domain/models/configurations/base-alarm-only-scenario-configuration";

interface Props {
  viewingMode: ViewingMode;
  index: number;
  organisationUnitScenario: OrganisationUnitScenario;
  originalOrganisationUnitScenario?: OrganisationUnitScenario;
  intentionOutOfBedEnabled: boolean;
}

interface ComponentProps {
  viewingMode: ViewingMode;
  index: number;
  organisationUnitScenario: OrganisationUnitScenario;
  originalOrganisationUnitScenario?: OrganisationUnitScenario;
  title: string;
  intentionOutOfBedEnabled: boolean;
  //Passing the translation function as prop is necessary to avoid calling the translation hook in the conditional components which causes https://react.dev/errors/300?invariant=300
  translationFunction: (key: string) => string;
}

const getParsedConfiguration = (configuration: string | undefined): any =>
  jsonParseWithLowerCaseKeys(configuration);

const GetRoomScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const baseConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseAlarmScenarionConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={baseConfiguration.sendAlarmAllDay}
      timeFrames={baseConfiguration.timeFrames}
      alarmTimeOut={baseConfiguration.alarmTimeOut}
      isAlarmActive={baseConfiguration.isAlarmActive}
      isStatusActive={baseConfiguration.isStatusActive}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <AlarmScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetBedScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const baseConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseAlarmScenarionConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={baseConfiguration.sendAlarmAllDay}
      timeFrames={baseConfiguration.timeFrames}
      alarmTimeOut={baseConfiguration.alarmTimeOut}
      isAlarmActive={baseConfiguration.isAlarmActive}
      isStatusActive={baseConfiguration.isStatusActive}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <BedScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
        intentionOutOfBedEnabled={props.intentionOutOfBedEnabled}
      />
    </ConfigurationRow>
  );
};

const GetSettingScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const settingConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseSettingScenarioConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={false}
      scenarioActiveAllDay={settingConfiguration.activeAllDay}
      timeFrames={settingConfiguration.timeFrames}
      subtitlePrefix={`${props.translationFunction(
        "scenarioConfiguration.scenarioAvailable",
      )} ${
        settingConfiguration.whenActiveAlarm
          ? props.translationFunction(
              "scenarioConfiguration.settingScenarioWhenActiveAlarm",
            )
          : ""
      }`}
      whenActiveAlarm={settingConfiguration.whenActiveAlarm}
      isAlarmActive={true}
    >
      <SettingScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetVideoScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const settingConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as VideoConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={false}
      scenarioActiveAllDay={settingConfiguration.activeAllDay}
      timeFrames={settingConfiguration.timeFrames}
      subtitlePrefix={`${props.translationFunction(
        "scenarioConfiguration.scenarioAvailable",
      )} ${
        settingConfiguration.whenActiveAlarm
          ? props.translationFunction(
              "scenarioConfiguration.settingScenarioWhenActiveAlarm",
            )
          : ""
      }`}
      whenActiveAlarm={settingConfiguration.whenActiveAlarm}
      isAlarmActive={true}
    >
      <VideoScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetAcousticScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const acousticConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as AcousticConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={acousticConfiguration.sendAlarmAllDay}
      temporaryAcousticConfiguration={
        acousticConfiguration.temporaryConfigurationEnabled
          ? (acousticConfiguration.temporaryConfiguration ?? undefined)
          : undefined
      }
      timeFrames={acousticConfiguration.timeFrames}
      isAlarmActive={true}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <AcousticScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetAlarmOnlyScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const configuration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseAlarmOnlyScenarioConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      isAlarmActive={true}
      scenarioActiveAllDay={configuration.sendAlarmAllDay}
      timeFrames={configuration.timeFrames}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <AlarmOnlyScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

const GetBathroomScenarioConfiguration = (props: Readonly<ComponentProps>) => {
  const baseConfiguration = getParsedConfiguration(
    props.organisationUnitScenario.configuration,
  ) as BaseAlarmScenarionConfiguration;
  return (
    <ConfigurationRow
      {...props}
      originalOrganisationUnitScenario={props.originalOrganisationUnitScenario!}
      scenarioTitle={props.title}
      showAlarmInfo={true}
      scenarioActiveAllDay={baseConfiguration.sendAlarmAllDay}
      timeFrames={baseConfiguration.timeFrames}
      alarmTimeOut={baseConfiguration.alarmTimeOut}
      isAlarmActive={baseConfiguration.isAlarmActive}
      isStatusActive={baseConfiguration.isStatusActive}
      subtitlePrefix={props.translationFunction(
        "scenarioConfiguration.scenarioActiveAllDay",
      )}
    >
      <AlarmScenarioConfiguration
        organisationUnitScenario={props.originalOrganisationUnitScenario!}
        scenarioTitle={props.title}
        index={props.index}
      />
    </ConfigurationRow>
  );
};

export function ResidentScenarioRow(props: Readonly<Props>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  return (
    <div className="resident-scenario-row-container">
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType ===
          ScenarioType.CurrentLocation &&
        GetSettingScenarioConfiguration({
          ...props,
          title: t("details.currentLocation"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Bed &&
        GetBedScenarioConfiguration({
          ...props,
          title: t("details.leftBed"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Room &&
        GetRoomScenarioConfiguration({
          ...props,
          title: t("details.leftRoom"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Intruder &&
        GetAlarmOnlyScenarioConfiguration({
          ...props,
          title: t("details.intruder"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Bathroom &&
        GetBathroomScenarioConfiguration({
          ...props,
          title: t("details.bathroom"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Door &&
        GetAlarmOnlyScenarioConfiguration({
          ...props,
          title: t("details.door"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType ===
          ScenarioType.FallDetection &&
        GetAlarmOnlyScenarioConfiguration({
          ...props,
          title: t("details.fallDetection"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Video &&
        GetVideoScenarioConfiguration({
          ...props,
          title: t("details.video"),
          translationFunction: t,
        })}
      {props.originalOrganisationUnitScenario &&
        props.organisationUnitScenario.scenarioType === ScenarioType.Acoustic &&
        GetAcousticScenarioConfiguration({
          ...props,
          title: t("details.acoustic"),
          translationFunction: t,
        })}
    </div>
  );
}

export default ResidentScenarioRow;
