import {
  setShouldShowConfirmation,
  showConfirmationPopup,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Gateway from "features/gateway/domain/models/gateway";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import {
  useCreateGatewayMutation,
  useDeleteGatewayMutation,
  useUpdateGatewayMutation,
} from "features/gateway/domain/reducers/gateway.reducer";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { isBelowMinimumVersion } from "utils/version-utils";
import { ViewingMode } from "utils/viewing-utils";

export interface GatewayDetailsHook {
  viewingMode: ViewingMode;
  mutationIsLoading: boolean;

  currentSelectedGateway: Gateway | undefined;
  changeCurrentSelectedGateway: (gateway: Gateway | undefined) => void;

  deleteGateway: (gateway: Gateway) => void;

  submitGateway: (gateway: Gateway) => void;

  cancelMutation: () => void;

  isDetailsOpen: boolean;
  openDetailsInCreationMode: () => void;
  openDetailsInViewingMode: (gateway: Gateway) => void;
  openDetailsInEditingMode: (gateway: Gateway) => void;
  closeDetails: (isFormStateDirty: boolean) => void;

  deleteErrorPopupTextKey: string;
  isDeleteErrorPopupOpen: boolean;
  closeDeleteErrorPopup: () => void;

  isDeleteConfirmationPopupOpen: boolean;
  closeDeleteConfirmationPopup: () => void;
  deleteConfirmationAction: () => void;

  isIpAddressPopupOpen: boolean;
  closeIpAddressPopup: () => void;
  ipAddressConfirmationAction: () => void;

  isTogglingSonevoApiDisabled: (gateway: Gateway | undefined) => boolean;
  isTogglingMediaserverDisabled: (gateway: Gateway | undefined) => boolean;

  form: UseFormReturn<Partial<Gateway>, any>;
}

const useGatewayDetails = (): GatewayDetailsHook => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {} as Partial<Gateway>,
  });

  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [isDeleteErrorPopupOpen, setIsDeleteErrorPopupOpen] =
    useState<boolean>(false);
  const [deleteErrorPopupTextKey, setDeleteErrorPopupTextKey] =
    useState<string>("");
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );
  const [isIpAddressPopupOpen, setIsIpAddressPopupOpen] =
    useState<boolean>(false);
  const [ipAddressConfirmationAction, setIpAddressConfirmationAction] =
    useState(() => () => {});
  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");
  const [currentSelectedGateway, setCurrentSelectedGateway] =
    useState<Gateway>();

  const [
    deleteGatewayMutation,
    {
      isSuccess: deleteGatewayIsSuccess,
      isLoading: deleteGatewayIsLoading,
      isError: deleteGatewayIsError,
      error: deleteGatewayError,
    },
  ] = useDeleteGatewayMutation();

  const [
    createGateway,
    {
      isLoading: createGatewayIsLoading,
      isSuccess: createGatewayIsSuccess,
      isError: createGatewayIsError,
      error: createGatewayError,
      data: createGatewayData,
    },
  ] = useCreateGatewayMutation();

  const [
    updateGateway,
    {
      isLoading: updateGatewayIsLoading,
      isSuccess: updateGatewayIsSuccess,
      isError: updateGatewayIsError,
      error: updateGatewayError,
      data: updateGatewayData,
    },
  ] = useUpdateGatewayMutation();

  const setFormValuesWithCurrentGateway = () => {
    form.reset({
      name: currentSelectedGateway?.name ?? "",
      ipAddress: currentSelectedGateway?.ipAddress ?? "",
      note: currentSelectedGateway?.note ?? "",
      type: currentSelectedGateway?.type ?? GatewayType.None,
      organisationUnitId:
        currentSelectedGateway?.organisationUnitId ?? undefined,
      shouldCreateDevicesAutomatically:
        currentSelectedGateway?.shouldCreateDevicesAutomatically ?? false,
      offlinePortalUsername:
        currentSelectedGateway?.offlinePortalUsername ?? "",
      offlinePortalPassword:
        currentSelectedGateway?.offlinePortalPassword ?? "",
      childGateways: currentSelectedGateway?.childGateways ?? [],
      kadexIpAddress: currentSelectedGateway?.kadexIpAddress ?? "",
      kadexUsername: currentSelectedGateway?.kadexUsername ?? "",
      kadexPassword: currentSelectedGateway?.kadexPassword ?? "",
      isSonevoApiEnabled: currentSelectedGateway?.isSonevoApiEnabled ?? false,
      isAlarmmonitorEnabled:
        currentSelectedGateway?.isAlarmmonitorEnabled ?? false,
      isAudioEnabled: currentSelectedGateway?.isAudioEnabled ?? false,
      isMediaserverEnabled:
        currentSelectedGateway?.isMediaserverEnabled ?? false,
      isKadexApiEnabled: currentSelectedGateway?.isKadexApiEnabled ?? false,
      isIS32ApiEnabled: currentSelectedGateway?.isIS32ApiEnabled ?? false,
      isRetryMechanismEnabled:
        currentSelectedGateway?.isRetryMechanismEnabled ?? false,
    });
  };

  const sonevoApiEnabledWatch = form.watch("isSonevoApiEnabled");
  const selectedSystemType = form.watch("type");
  const hasSelectedType = GatewayType.None !== selectedSystemType;

  useEffect(() => {
    if (!sonevoApiEnabledWatch) {
      form.setValue("isIS32ApiEnabled", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sonevoApiEnabledWatch]);

  useEffect(() => {
    if (hasSelectedType && !currentSelectedGateway) {
      resetForm(selectedSystemType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSystemType]);

  useEffect(() => {
    setFormValuesWithCurrentGateway();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedGateway]);

  useEffect(() => {
    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: form.formState.isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty]);

  useEffect(() => {
    if (createGatewayIsSuccess && createGatewayData) {
      setCurrentSelectedGateway(createGatewayData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGatewayIsSuccess]);

  useEffect(() => {
    if (updateGatewayIsSuccess && updateGatewayData) {
      setCurrentSelectedGateway(updateGatewayData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGatewayIsSuccess]);

  useEffect(() => {
    if (createGatewayIsError && createGatewayError) {
      dispatch(
        setErrorMessage({
          error: createGatewayError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGatewayIsError]);

  useEffect(() => {
    if (updateGatewayIsError && updateGatewayError) {
      dispatch(
        setErrorMessage({
          error: updateGatewayError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGatewayIsError]);

  useEffect(() => {
    if (deleteGatewayIsError && deleteGatewayError) {
      dispatch(
        setErrorMessage({
          error: deleteGatewayError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGatewayIsError]);

  useEffect(() => {
    if (deleteGatewayIsSuccess) {
      setIsDeleteConfirmationPopupOpen(false);
      setIsDetailsOpen(false);
    }
  }, [deleteGatewayIsSuccess]);

  useEffect(() => {
    setMutationIsLoading(
      updateGatewayIsLoading ||
        createGatewayIsLoading ||
        deleteGatewayIsLoading,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGatewayIsLoading, createGatewayIsLoading, deleteGatewayIsLoading]);

  const changeCurrentSelectedGateway = (gateway: Gateway | undefined) => {
    setCurrentSelectedGateway(gateway);
  };

  const submitGateway = (gateway: Gateway) => {
    const hasChangedIpAddress =
      (currentSelectedGateway?.type === GatewayType.IoTEdge ||
        currentSelectedGateway?.type === GatewayType.IoTEdgeCluster) &&
      currentSelectedGateway?.id &&
      currentSelectedGateway?.ipAddress !== gateway.ipAddress?.trim();

    if (hasChangedIpAddress) {
      setIsIpAddressPopupOpen(true);

      setIpAddressConfirmationAction(() => () => {
        saveGateway(gateway);
        setIsIpAddressPopupOpen(false);
      });
      return;
    }

    saveGateway(gateway);
  };

  const saveGateway = (gateway: Gateway) => {
    viewingMode === "creation"
      ? createGateway(gateway)
      : updateGateway(gateway);
  };

  const deleteGateway = (gateway: Gateway) => {
    setCurrentSelectedGateway(gateway);
    const hasDeviceWithOrganisationUnitId = gateway.devices?.some(
      (device) => !!device.organisationUnitId,
    );

    const hasSystemWithOrganisationUnitId = gateway.systems?.some(
      (system) => !!system.organisationUnitId,
    );

    const hasSystemWithDeviceWithOrganisationUnitId = gateway.systems?.some(
      (system) => system.devices?.some((device) => !!device.organisationUnitId),
    );

    if (
      hasDeviceWithOrganisationUnitId ||
      hasSystemWithOrganisationUnitId ||
      hasSystemWithDeviceWithOrganisationUnitId
    ) {
      let errorBodyTextKey = "";

      switch (true) {
        case hasDeviceWithOrganisationUnitId:
          errorBodyTextKey = "linkedDevicesBodyText";
          break;
        case hasSystemWithOrganisationUnitId:
          errorBodyTextKey = "linkedSystemsBodyText";
          break;
        default:
          errorBodyTextKey = "linkedDevicesOnSystemsBodyText";
          break;
      }

      setDeleteErrorPopupTextKey(errorBodyTextKey);
      setIsDeleteErrorPopupOpen(true);
      return;
    }

    setIsDeleteConfirmationPopupOpen(true);
    setDeleteConfirmationAction(() => () => confirmDeleteGateway(gateway));
  };

  const confirmDeleteGateway = (gateway: Gateway) => {
    deleteGatewayMutation(gateway.id!);
    setDeleteConfirmationAction(() => () => {});
    setCurrentSelectedGateway(undefined);
  };

  const openDetailsInCreationMode = () => {
    setCurrentSelectedGateway(undefined);
    setIsDetailsOpen(true);
    setViewingMode("creation");
  };

  const openDetailsInEditingMode = (gateway: Gateway | undefined) => {
    setCurrentSelectedGateway(gateway);
    setIsDetailsOpen(true);
    setViewingMode("editing");
    setFormValuesWithCurrentGateway();
  };

  const openDetailsInViewingMode = (gateway: Gateway | undefined) => {
    setCurrentSelectedGateway(gateway);
    setIsDetailsOpen(true);
    setViewingMode("viewing");
  };

  const closeDetails = (isFormStateDirty: boolean) => {
    const showConfirmation =
      (viewingMode === "creation" || viewingMode === "editing") &&
      isFormStateDirty;

    if (showConfirmation) {
      dispatch(
        showConfirmationPopup({
          showInstantly: true,
          confirmActionNextAction: () => {
            setIsDetailsOpen(false);
            setCurrentSelectedGateway(undefined);
            resetForm();
          },
        }),
      );
    } else {
      setIsDetailsOpen(false);
      setCurrentSelectedGateway(undefined);
      resetForm();
    }
  };

  const cancelMutation = () => {
    if (viewingMode === "creation") {
      closeDetails(false);
    } else if (viewingMode === "editing") {
      setViewingMode("viewing");
    }
    setFormValuesWithCurrentGateway();
  };

  const closeDeleteConfirmationPopup = () => {
    setIsDeleteConfirmationPopupOpen(false);
  };

  const closeDeleteErrorPopup = () => {
    setIsDeleteErrorPopupOpen(false);
  };

  const closeIpAddressPopup = () => {
    setIsIpAddressPopupOpen(false);
  };

  const resetForm = (type?: GatewayType) => {
    form.reset({
      name: "",
      ipAddress: "",
      note: "",
      type: type ?? GatewayType.None,
      organisationUnitId: undefined,
      shouldCreateDevicesAutomatically: false,
      isSonevoApiEnabled: false,
      isAlarmmonitorEnabled: false,
      isAudioEnabled: false,
      isMediaserverEnabled: false,
      isKadexApiEnabled: false,
      isIS32ApiEnabled: false,
      offlinePortalUsername: "",
      offlinePortalPassword: "",
      childGateways: [],
      kadexIpAddress: "",
      kadexUsername: "",
      kadexPassword: "",
      isRetryMechanismEnabled: false,
    });
  };

  const isTogglingSonevoApiDisabled = (
    gateway: Gateway | undefined,
  ): boolean => {
    const minimumVersion = "1.28.0";

    return (
      !gateway ||
      (gateway.type === GatewayType.IoTEdgeCluster &&
        gateway.childGateways?.some((x) =>
          isBelowMinimumVersion(x.hostModuleVersion, minimumVersion),
        )) ||
      (gateway.type === GatewayType.IoTEdge &&
        isBelowMinimumVersion(gateway.hostModuleVersion, minimumVersion))
    );
  };

  const isTogglingMediaserverDisabled = (
    gateway: Gateway | undefined,
  ): boolean => {
    const minimumVersion = "1.36.0";

    return (
      !gateway ||
      (gateway.type === GatewayType.IoTEdgeCluster &&
        gateway.childGateways?.some(
          (x) =>
            !x.imageVersion ||
            isBelowMinimumVersion(x.imageVersion, minimumVersion),
        )) ||
      (gateway.type === GatewayType.IoTEdge && !gateway.imageVersion) ||
      isBelowMinimumVersion(gateway.imageVersion, minimumVersion)
    );
  };

  return {
    viewingMode,
    mutationIsLoading,

    currentSelectedGateway,
    changeCurrentSelectedGateway,

    deleteGateway,

    submitGateway,

    cancelMutation,

    isDetailsOpen,
    openDetailsInCreationMode,
    openDetailsInEditingMode,
    openDetailsInViewingMode,
    closeDetails,

    deleteErrorPopupTextKey,
    isDeleteErrorPopupOpen,
    closeDeleteErrorPopup,

    isDeleteConfirmationPopupOpen,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,

    isIpAddressPopupOpen,
    closeIpAddressPopup,
    ipAddressConfirmationAction,

    isTogglingSonevoApiDisabled,
    isTogglingMediaserverDisabled,

    form,
  };
};

export default useGatewayDetails;
