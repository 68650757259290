import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import styles from "features/connectors/views/connector-details/connector-settings/locations-modal/locations-modal.module.scss";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import Table, { IRowItem } from "components/table/table";
import { ColumnDefinition } from "components/table/columnDefinition";
import { NestedKeyof } from "utils/nested-keyof-utils";
import LocationRow from "../../../../../../components/location/location-row.component";
import useConnectorOrganisation from "features/connectors/hooks/connector-settings-organisation-hook";
import SearchBar from "components/search/searchbar";

const LocationsModal = () => {
  const { t } = useTranslation("connectors");

  const { connectorDetailsHook } = useConnectorsContextProvider();
  const {
    selectedLocations,
    onLocationSelected,
    onLocationDeselected,
    readLocationsIsLoading,
    readLocationsIsSuccess,
    locations,
    searchBarValue,
    onSearchValueChanged,
  } = useConnectorOrganisation();

  const columns: ColumnDefinition<
    OrganisationUnit,
    NestedKeyof<OrganisationUnit>
  >[] = [
    {
      key: "name",
      label: t("locationsModal.column.name"),
      disableSort: true,
      renderCustomContentProvider: (location) => (
        <LocationRow name={location.name} />
      ),
    },
  ];

  const handleOnClose = (): void => {
    connectorDetailsHook.closeLocationPicker();
  };

  const handleOnSaveButtonClicked = (): void => {
    connectorDetailsHook.updateSelectedLocations(selectedLocations);
    connectorDetailsHook.closeLocationPicker();
  };

  const selectedRows = locations
    .filter((x) => selectedLocations.some((y) => y.id === x.id))
    .map<IRowItem<OrganisationUnit>>((locations) => ({
      data: locations,
    }));

  return (
    <Dialog
      className={styles.modal}
      open={connectorDetailsHook.isLocationPickerOpen}
      onClose={handleOnClose}
      slotProps={{
        paper: {
          classes: { root: styles.locationsModalPaper },
          sx: { overflow: "visible" },
        },
      }}
    >
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <Typography className={styles.headerTitle} variant="h3">
            {t("locationsModal.title")}
          </Typography>
          <div className={styles.headerActionsContainer}>
            <Button
              className={styles.linkButton}
              variant="contained"
              onClick={handleOnSaveButtonClicked}
              startIcon={<CheckIcon className={styles.checkIcon} />}
              data-testid="locationsModalSaveButton"
            >
              {t("locationsModal.saveButtonText")}
            </Button>
            <IconButton
              aria-label="close"
              className={styles.closeButton}
              onClick={handleOnClose}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <div className={styles.searchBarContainer}>
          <SearchBar
            value={searchBarValue}
            onSearchValueChanged={onSearchValueChanged}
          />
        </div>
        {readLocationsIsLoading && <LoadingIndicator />}
        {readLocationsIsSuccess && locations && (
          <Table
            className={styles.heading}
            data={locations.map<IRowItem<OrganisationUnit>>((locations) => ({
              data: locations,
            }))}
            columns={columns}
            rowsAreSelectable
            onRowDeselected={onLocationDeselected}
            onRowSelected={onLocationSelected}
            rowIdentifier={(item: OrganisationUnit) => item.id}
            selectedRows={selectedRows}
          />
        )}
      </div>
    </Dialog>
  );
};

export default LocationsModal;
