import { useLazyGetSubscriptionInfoQuery } from "features/subscription-info/domain/reducers/subscription-queries";
import { SubscriptionInfo } from "features/subscription-info/domain/models/subscription-info";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { useExpireNoficationsMutation } from "features/notification/domain/notification-queries/notification-queries";

export interface ISubscriptionInfoHook {
  subscriptionInfo: SubscriptionInfo | undefined;
  isFinishedExpireNotifications: boolean;
}

const useSubscriptionInfo = (): ISubscriptionInfoHook => {
  const { user } = useAuth();
  const userRef = useRef(user);

  const { data: subscriptionInfo, refetch: fetchSubscriptionInfo } = useLazyGetSubscriptionInfoQuery();
  const { mutate: executeExpireNotificationsMutation, isSuccess: isSuccessExpireNotifications, isError: isErrorExpireNotifications, reset: resetExpireNotificationsMutation } = useExpireNoficationsMutation();
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    userRef.current = user;

    if (user) {
      fetchSubscriptionInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (subscriptionInfo) {
      setIsFinished(false);
      resetExpireNotificationsMutation();
      executeExpireNotificationsMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionInfo]);

  useEffect(() => {
    if (isSuccessExpireNotifications || isErrorExpireNotifications) {
      setIsFinished(true);
    }
  }, [isSuccessExpireNotifications, isErrorExpireNotifications]);

  return {
    subscriptionInfo,
    isFinishedExpireNotifications: isFinished
  };
};

export default useSubscriptionInfo;
