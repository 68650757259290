import useSystems, { SystemsHook } from "features/systems/context/systems-hook";
import React, { ReactNode } from "react";

const SystemsContext = React.createContext<SystemsHook>({} as never);

interface Props {
  children: ReactNode;
}

export const SystemsProvider = ({ children }: Readonly<Props>) => {
  return (
    <SystemsContext.Provider value={useSystems()}>
      {children}
    </SystemsContext.Provider>
  );
};

export const useSystemsContextProvider = (): SystemsHook =>
  React.useContext<SystemsHook>(SystemsContext);
