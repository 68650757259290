import { Divider, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import GatewaySettingsOrganisation from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-organisation";
import GatewaySettingsEspaClient from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-clients/gateway-settings-espa-client";
import GatewaySettingsIoTEdgeClient from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-clients/gateway-settings-iot-edge-client";
import GatewaySettingsIoTEdgeClusterClient from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-clients/gateway-settings-iot-edge-cluster-client";
import GatewaySettingsViewpointClient from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-clients/gateway-settings-viewpoint-client";
import GatewaySettingsConfiguration from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-configuration";
import GatewaySettingsKadex from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-kadex";
import GatewaySettingsOfflinePortalCredentials from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-offline-portal-credentials";
import GatewaySettingsSonevoApi from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-sonevo-api";

const GatewaySettings = () => {
  const { t } = useTranslation("gateway");

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const { watch } = useFormContext();
  const watchGatewayType = watch("type");
  const watchIsAlarmmonitorEnabled = watch("isAlarmmonitorEnabled");
  const watchIsKadexApiEnabled = watch("isKadexApiEnabled");
  const watchIsSonevoApiEnabled = watch("isSonevoApiEnabled");

  const isViewing = gatewayDetailsHook.viewingMode === "viewing";
  const isCreating = gatewayDetailsHook.viewingMode === "creation";
  const selectedGateway = gatewayDetailsHook.currentSelectedGateway;

  const hasSelectedType = GatewayType.None !== watchGatewayType;
  const isViewpoint = selectedGateway?.type === GatewayType.Viewpoint;
  const isIoTEdge = selectedGateway?.type === GatewayType.IoTEdge;
  const isIoTEdgeCluster = watchGatewayType === GatewayType.IoTEdgeCluster;
  const showIoTEdgeFields =
    watchGatewayType === GatewayType.IoTEdge ||
    watchGatewayType === GatewayType.IoTEdgeCluster;
  const isESPA = watchGatewayType === GatewayType.ESPA;
  const showGatewaySettings =
    ((isCreating && isIoTEdgeCluster) || !isCreating) &&
    watchGatewayType !== GatewayType.NFC &&
    watchGatewayType !== GatewayType.BLEBeacon;

  if (!hasSelectedType) return <></>;

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      <div
        className={styles.settingsItem}
        data-testid="gatewayOrganisationContainer"
      >
        <Typography variant="h5">
          {t("details.settings.organisationUnitLabel")}
        </Typography>
        <div className={styles.settingsItemContent}>
          <GatewaySettingsOrganisation />
        </div>
      </div>
      <Divider />
      {showGatewaySettings && (
        <div className={styles.settingsItem}>
          <Typography variant="h5" className={styles.settingsTitle}>
            {t("details.settings.clientSettingsLabel")}
          </Typography>
          {isViewpoint && <GatewaySettingsViewpointClient />}
          {isIoTEdge && <GatewaySettingsIoTEdgeClient />}
          {isIoTEdgeCluster && <GatewaySettingsIoTEdgeClusterClient />}
          {isESPA && <GatewaySettingsEspaClient />}
        </div>
      )}
      {showIoTEdgeFields && (
        <>
          {!isCreating && <Divider />}
          <div className={styles.settingsItem}>
            <Typography variant="h5">
              {t("details.settings.configurationLabel")}
            </Typography>
            <GatewaySettingsConfiguration />
          </div>
          <Divider />
          {watchIsSonevoApiEnabled && (
            <>
              <div className={styles.settingsItem}>
                <Typography variant="h5" className={styles.settingsTitle}>
                  {t("details.settings.sonevoApiLabel")}
                </Typography>
                <GatewaySettingsSonevoApi />
              </div>
              <Divider />
            </>
          )}
          {watchIsAlarmmonitorEnabled && (
            <>
              <div className={styles.settingsItem}>
                <Typography variant="h5" className={styles.settingsTitle}>
                  {t("details.settings.offlinePortalCredentialsLabel")}
                </Typography>
                <GatewaySettingsOfflinePortalCredentials />
              </div>
              <Divider />
            </>
          )}
          {watchIsKadexApiEnabled && (
            <div className={styles.settingsItem}>
              <Typography variant="h5" className={styles.settingsTitle}>
                {t("details.settings.kadexLabel")}
              </Typography>
              <GatewaySettingsKadex />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GatewaySettings;
