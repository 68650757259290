import { RefreshRounded } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Typography } from "@mui/material";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import FilterChipOrganisationUnit from "components/organisation-unit-filter-chip/filter-chip-organisation-unit.component";
import SearchBar from "components/search/searchbar";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import styles from "features/systems/views/components/system-filters.module.scss";
import SystemMonitoringFilter from "features/systems/views/components/system-monitoring-filter";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { SystemType } from "features/systems/domain/models/system";
import { SystemFilterValueType } from "features/systems/domain/models/system-filter-value";
import { FilterChipOption } from "components/multi-value-filter-chip/models/filter-chip-option";

export default function SystemFilters(): ReactElement {
  const { t } = useTranslation("systems");

  const { refresh, systemsFilterHook } = useSystemsContextProvider();

  const getTypeFilterValues = (): FilterChipOption[] =>
    Object.entries(SystemType)
      .filter(([_, value]) => value !== SystemType.Unknown)
      .map(([key, value]) => ({
        key,
        value: [key],
        label: t(`filterValues.type.${value}`),
      }));

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.systemFilters}>
          <SearchBar
            onSearchValueChanged={systemsFilterHook.submitSearch}
            value={systemsFilterHook.searchBarValue}
          />
          <FilterListIcon />
          <MultiValueFilterChip
            options={getTypeFilterValues()}
            onOptionsSelected={(keys: string[]) =>
              systemsFilterHook.selectOption(SystemFilterValueType.Type, keys)
            }
            placeHolder={t("filters.type")}
            selectedOptions={getTypeFilterValues().filter((x) =>
              systemsFilterHook.activeFilters
                .find((x) => x.filterValueType === SystemFilterValueType.Type)
                ?.values.includes(x.key),
            )}
          />
          <OrganisationUnitTreeProvider.All>
            <FilterChipOrganisationUnit
              onOptionsSelected={(keys: SelectedOrganisationTreeNode[]) => {
                systemsFilterHook.changeSelectedOrganisationUnits(keys);
                systemsFilterHook.selectOption(
                  SystemFilterValueType.OrganisationUnit,
                  keys.map((x) => x.id),
                );
              }}
              selectedOptions={systemsFilterHook.selectedOrganisationUnits}
              placeHolder={t("filters.organisationUnits")}
            />
          </OrganisationUnitTreeProvider.All>

          <div className={styles.clearFilters}>
            <Typography
              variant="body1"
              sx={{ color: Constants.Colors.primary }}
              onClick={systemsFilterHook.clearFilters}
            >
              {t("filters.clearFilters")}
            </Typography>
          </div>
        </div>

        {refresh && (
          <IconButton className={styles.refreshButton} onClick={refresh}>
            <RefreshRounded />
          </IconButton>
        )}
      </div>

      {systemsFilterHook.monitorFiltersIsSuccess &&
        systemsFilterHook.monitorFilters.length > 0 && (
          <div className={styles.monitoringFilters}>
            <SystemMonitoringFilter.Offline />
            <SystemMonitoringFilter.Warning />
            <SystemMonitoringFilter.Online />
          </div>
        )}
    </div>
  );
}
