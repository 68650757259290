import { Switch, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-sonevo-api.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";

const GatewaySettingsSonevoApi = () => {
  const { t } = useTranslation("gateway");

  const {
    gatewayDetailsHook: { viewingMode },
  } = useGatewaysContextProvider();

  const { control } = useFormContext();

  const isViewing = viewingMode === "viewing";

  return (
    <div className={styles.container}>
      <div className={styles.configurationRow}>
        <Typography variant="subtitle1">
          {t("details.settings.sonevoApi.retryMechanism")}
        </Typography>
        <Controller
          name="isRetryMechanismEnabled"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              type="checkbox"
              checked={field.value}
              disabled={isViewing}
            />
          )}
        />
      </div>
    </div>
  );
};

export default GatewaySettingsSonevoApi;
