import IS32LocalBusUnits from "features/systems/IS32/views//configuration-components/IS32-local-bus-units.component";
import IS32GeneralConfiguration from "features/systems/IS32/views//configuration-components/IS32-general-configuration.component";
import IS32AcousticConfiguration from "features/systems/IS32/views//configuration-components/IS32-acoustic-configuration.component";
import IS32ButtonsConfiguration from "features/systems/IS32/views//configuration-components/IS32-buttons-configuration.component";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import IS32LocationConfiguration from "features/systems/IS32/views/configuration-components/IS32-location-configuration.component";
import { IS32ConfigurationDeviceType } from "features/systems/IS32/domain/models/IS32-configuration-device-type";
import { FormProvider } from "react-hook-form";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";

interface Props {
  unit: IS32ConfigurationUnit;
}

const IS32ConfigurationAccordionDetailsItem = ({ unit }: Props) => {
  const { configurationForm } = useIS32DetailsContextProvider();

  const supportsAcousticConfiguration = (unit: IS32ConfigurationUnit) =>
    unit.type === IS32ConfigurationDeviceType.Sec0104 ||
    unit.type === IS32ConfigurationDeviceType.Sec0105 ||
    unit.type === IS32ConfigurationDeviceType.Sec0106;

  const supportsButtonConfiguration = (unit: IS32ConfigurationUnit) =>
    unit.type === IS32ConfigurationDeviceType.S0304 ||
    unit.type === IS32ConfigurationDeviceType.Sec0106;

  return (
    <FormProvider {...configurationForm}>
      {(unit.localBusUnits?.length ?? 0) > 0 && (
        <IS32LocalBusUnits unit={unit} />
      )}
      <IS32GeneralConfiguration unit={unit} accordionEnabled />
      {supportsAcousticConfiguration(unit) && (
        <IS32AcousticConfiguration accordionEnabled />
      )}
      {supportsButtonConfiguration(unit) && (
        <IS32ButtonsConfiguration accordionEnabled />
      )}
      <IS32LocationConfiguration />
    </FormProvider>
  );
};

export default IS32ConfigurationAccordionDetailsItem;
