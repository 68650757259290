import { Typography } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-local-bus-units.module.scss";
import {
  IS32ConfigurationLocalBusUnit,
  IS32ConfigurationUnit,
} from "features/systems/IS32/domain/models/IS32-configuration-unit";

interface IProps {
  unit: IS32ConfigurationUnit;
}

const IS32LocalBusUnits = ({ unit }: IProps) => {
  const formatLocalBusUnitName = (unit: IS32ConfigurationLocalBusUnit) => {
    const displayType =
      unit.displayType && unit.displayType.trim() !== ""
        ? unit.displayType
        : null;
    const type = unit.type && unit.type.trim() !== "" ? unit.type : "";
    const serial = unit.serial ? `-${unit.serial}` : "";
    return `${displayType ?? type}${serial}`;
  };

  return (
    <div className={styles.localBusUnitsContainer}>
      {unit.localBusUnits?.map((localBusUnit) => {
        return (
          <Typography
            key={`${localBusUnit.serial}${localBusUnit.type}`}
            variant="subtitle1"
          >
            {formatLocalBusUnitName(localBusUnit)}
          </Typography>
        );
      })}
    </div>
  );
};

export default IS32LocalBusUnits;
