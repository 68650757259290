import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  IconButton,
  Typography,
} from "@mui/material";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import styles from "features/systems/IS32/views/IS32-configuration-accordion-item.module.scss";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import IS32ConfigurationAccordionDetailsItem from "features/systems/IS32/views/IS32-configuration-accordion-details-item";
import IS32EditConfigurationPopup from "features/systems/IS32/views/IS32-edit-configuration.component";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";
import { IS32AccordionType } from "features/systems/IS32/domain/models/is32-accordion-type";

interface Props {
  unit: IS32ConfigurationUnit;
}

const IS32ConfigurationAccordionItem = ({ unit }: Props) => {
  const { t } = useTranslation("IS32");

  const { systemsDetailsHook } = useSystemsContextProvider();

  const {
    openConfigurationDetailsInEditingMode,
    is32AccordionHook: { roomUnitExpanded, handleAccordionExpandedChange },
  } = useIS32DetailsContextProvider();

  const { viewingMode } = systemsDetailsHook;

  const accordionStyle = {
    border: "1px solid",
    borderColor: alpha(Constants.Colors.primary, 0.16),
    borderRadius: "16px",
    mb: 2,

    "&:first-of-type": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },

    "&:last-of-type": {
      borderBottomLeftRadius: "16px",
      borderBottomRightRadius: "16px",
    },
  };

  const accordionSummaryStyle = {
    minHeight: 0,
    padding: "8px 16px",

    "& .MuiAccordionSummary-content": {
      paddingTop: "8px",
      paddingBottom: "8px",
      margin: 0,
    },
    "&.Mui-expanded": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      margin: 0,
      minHeight: 0,
      backgroundColor: Constants.Colors.secondaryContainer,

      "& .MuiAccordionSummary-content": {
        paddingTop: "8px",
        paddingBottom: "8px",
        margin: 0,
      },
    },
  };

  const formatUnitName = (unit: IS32ConfigurationUnit) => {
    const displayType =
      unit.displayType && unit.displayType.trim() !== ""
        ? unit.displayType
        : null;
    const type = unit.type && unit.type.trim() !== "" ? unit.type : "";
    const serial = unit.serial ? `-${unit.serial}` : "";
    const room = unit.room && unit.room.trim() !== "" ? `-${unit.room}` : "";
    return `${displayType ?? type}${serial}${room}`;
  };

  const showNewLabel = !unit.room || unit.room.trim() === "";

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    openConfigurationDetailsInEditingMode();
  };

  return (
    <>
      <Accordion
        key={unit.address}
        sx={accordionStyle}
        expanded={roomUnitExpanded}
        onChange={(_, expanded) =>
          handleAccordionExpandedChange(IS32AccordionType.RoomUnit, expanded)
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={accordionSummaryStyle}
        >
          <div className={styles.headerContainer}>
            {unit.localBusUnits?.length === 1 ? (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    color: roomUnitExpanded
                      ? Constants.Colors.onSecondaryContainer
                      : "",
                  }}
                >
                  {t("configuration.unitTitleSingularLocalBusUnits", {
                    address: unit.address,
                    name: formatUnitName(unit),
                    amountOfLocalBusUnits: unit.localBusUnits?.length,
                  })}
                </Typography>
                {showNewLabel && (
                  <Typography
                    variant="overline"
                    color="primary"
                    className={styles.newLabel}
                  >
                    {t("configuration.newLabelRoom")}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    color: roomUnitExpanded
                      ? Constants.Colors.onSecondaryContainer
                      : "",
                  }}
                >
                  {t("configuration.unitTitlePluralLocalBusUnits", {
                    address: unit.address,
                    name: formatUnitName(unit),
                    amountOfLocalBusUnits: unit.localBusUnits
                      ? unit.localBusUnits.length
                      : 0,
                  })}
                </Typography>
                {showNewLabel && (
                  <Typography
                    variant="overline"
                    color="primary"
                    className={styles.newLabel}
                  >
                    {t("configuration.newLabelRoom")}
                  </Typography>
                )}
              </>
            )}
            <AutorisationWrapper
              atLeastOnePermissionOf={[
                Permission.UpdateSystemsGatewaysConnectors,
              ]}
            >
              <IconButton
                onClick={(event) => handleButtonClick(event)}
                disabled={viewingMode === "editing"}
                className={styles.editButton}
                sx={{
                  "&.Mui-disabled": {
                    backgroundColor: alpha(Constants.Colors.primary, 0.38),
                  },
                }}
              >
                <ModeEditOutlineOutlinedIcon
                  className={
                    viewingMode === "editing" ? styles.disabledIcon : ""
                  }
                />
              </IconButton>
            </AutorisationWrapper>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px 24px" }}>
          <IS32ConfigurationAccordionDetailsItem unit={unit} />
        </AccordionDetails>
      </Accordion>
      <IS32EditConfigurationPopup />
    </>
  );
};

export default IS32ConfigurationAccordionItem;
