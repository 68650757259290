import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckRounded from "@mui/icons-material/CheckRounded";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "./header.component.scss";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Permission from "features/autorisation/domain/models/permission";
import { ReactElement } from "react";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";

interface IProps {
  hasErrors: boolean;
  headerText?: string;
  isScrolling?: boolean;
  isSubmitting: boolean;
  showEditButton?: boolean;
  isLoading: boolean;
  onCancelButtonClicked: () => void;
}

export function Header(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const creationMode = (
    <div className="top-header-container">
      {props.hasErrors && (
        <Typography variant="caption" className="error">
          {t("validationErrorsSummaryText")}
        </Typography>
      )}
      <div className="actions-container">
        <Button
          data-testid="cancelButton"
          variant="text"
          onClick={props.onCancelButtonClicked}
        >
          {t("details.cancelFormButton")}
        </Button>
        <Button
          data-testid="submitButton"
          startIcon={<CheckRounded />}
          variant="contained"
          type="submit"
          loading={props.isSubmitting}
          loadingPosition="start"
        >
          {t("details.saveFormButton")}
        </Button>
      </div>
    </div>
  );

  const viewingModeComponent = (
    <div className="actions-container">
      <AutorisationWrapper
        atLeastOnePermissionOf={[Permission.UpdateResidentsSettings]}
      >
        <Button
          data-testid="editButton"
          startIcon={<ModeEditOutlineOutlinedIcon />}
          variant="text"
          onClick={() => residentDetailsHook.setViewingMode("editing")}
        >
          {t("details.editFormButton")}
        </Button>
      </AutorisationWrapper>
    </div>
  );

  return (
    <div className={`header-container ${props.isScrolling && "is-scrolling"}`}>
      {(residentDetailsHook.viewingMode === "creation" ||
        residentDetailsHook.viewingMode === "editing") &&
        creationMode}
      {residentDetailsHook.viewingMode === "viewing" && (
        <>
          {props.showEditButton === undefined || !!props.showEditButton ? (
            viewingModeComponent
          ) : (
            <div className="actions-container" />
          )}
        </>
      )}
      {!props.isLoading && (
        <Typography
          variant="h4"
          className="title ellipsis"
          data-testid="residentDetailHeader"
        >
          {props.headerText}
        </Typography>
      )}
    </div>
  );
}

export default Header;
