import { Link as LinkIcon } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import CopyButton from "components/copy-button/copy-button.component";
import PopUp from "components/pop-up/pop-up.component";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { Controller, useFormContext } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import styles from "./gateway-settings-client.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import Gateway from "features/gateway/domain/models/gateway";
import useGatewaySettingsIoTEdgeClusterClient from "features/gateway/hooks/gateway-settings-iot-edge-cluster-client-hook";

const GatewaySettingsIoTEdgeClusterDevice = (
  childGateway: Gateway | undefined,
  index: number,
) => {
  const { t } = useTranslation("gateway");

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const {
    ioTEdgeConnectionString,
    generateIoTEdgeConnectionStringIsLoading,
    isGenerateConfirmationOpen,
    defaultIoTEdgeConnectionString,
    generateIoTEdgeClusterDeviceConnectionString:
      generateIoTEdgeConnectionString,
    submitGenerateIoTEdgeClusterDeviceConnectionString:
      submitGenerateIoTEdgeConnectionString,
    closeGenerateConfirmationPopup,
  } = useGatewaySettingsIoTEdgeClusterClient(childGateway);

  const { control } = useFormContext();

  return (
    <div className={styles.clusterItem}>
      {gatewayDetailsHook.viewingMode === "editing" ||
      gatewayDetailsHook.viewingMode === "creation" ? (
        <Controller
          name={`childGateways[${index}].name`}
          control={control}
          rules={{ required: t("details.information.requiredHelperText") }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              slotProps={{
                htmlInput: {
                  "data-testid": "nameInput",
                  maxLength: 255,
                },
              }}
              id={`nameInput-${index}`}
              label={t("details.information.nameLabel")}
              variant="outlined"
              error={!!error}
              helperText={error?.message?.toString()}
              className={styles.textfield}
            />
          )}
        />
      ) : (
        <Typography variant="subtitle1">{childGateway?.name ?? ""}</Typography>
      )}
      <div className={styles.container}>
        <div className={styles.infoItem}>
          <Typography variant="subtitle1">
            {t("details.settings.ioTEdgeDeviceIdLabel")}:
          </Typography>
          <Typography variant="subtitle1">
            {t("details.settings.ioTEdgeConnectionStringLabel")}:
          </Typography>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.secret}>
            <Typography variant="subtitle1">
              {childGateway?.deviceId ?? "-"}
            </Typography>
            {childGateway?.deviceId !== undefined && (
              <CopyButton contentToCopy={childGateway.deviceId} />
            )}
          </div>
          <div className={styles.secret}>
            <Typography variant="subtitle1">
              {ioTEdgeConnectionString}
            </Typography>
            {ioTEdgeConnectionString !== defaultIoTEdgeConnectionString && (
              <CopyButton contentToCopy={ioTEdgeConnectionString} />
            )}
          </div>
        </div>
      </div>
      <AutorisationWrapper
        atLeastOnePermissionOf={[
          Permission.CreateSystemsGatewaysConnectors,
          Permission.CreateExternalSystem,
        ]}
      >
        <>
          {gatewayDetailsHook.viewingMode === "viewing" &&
            (ioTEdgeConnectionString === defaultIoTEdgeConnectionString ? (
              <Button
                variant="outlined"
                onClick={generateIoTEdgeConnectionString}
                startIcon={<LinkIcon />}
                loading={generateIoTEdgeConnectionStringIsLoading}
                loadingPosition="start"
                data-testid="generate-secret-button"
              >
                {t("details.settings.clientSecret.generateButton")}
              </Button>
            ) : (
              <Typography variant="subtitle1">
                {t("details.settings.clientSecret.warningIoTEdge")}
              </Typography>
            ))}
          <PopUp
            isOpen={isGenerateConfirmationOpen}
            title={t("details.settings.clientSecret.confirmation.title")}
            body={
              <Trans
                t={t}
                i18nKey="details.settings.clientSecret.confirmation.bodyText"
              />
            }
            primaryButtonText={t(
              "details.settings.clientSecret.confirmation.confirmButton",
            )}
            secondaryButtonText={t(
              "details.settings.clientSecret.confirmation.cancelButton",
            )}
            handleOnClose={closeGenerateConfirmationPopup}
            secondaryButtonAction={closeGenerateConfirmationPopup}
            primaryButtonAction={submitGenerateIoTEdgeConnectionString}
          />
        </>
      </AutorisationWrapper>
    </div>
  );
};

const GatewaySettingsIoTEdgeClusterClient = () => {
  const { gatewayDetailsHook } = useGatewaysContextProvider();

  return (
    <>
      {GatewaySettingsIoTEdgeClusterDevice(
        gatewayDetailsHook.currentSelectedGateway?.childGateways?.find(
          (x) => x.isActiveInCluster,
        ),
        0,
      )}
      {GatewaySettingsIoTEdgeClusterDevice(
        gatewayDetailsHook.currentSelectedGateway?.childGateways?.find(
          (x) => !x.isActiveInCluster,
        ),
        1,
      )}
    </>
  );
};

export default GatewaySettingsIoTEdgeClusterClient;
