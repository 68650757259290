import { TextField } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-general-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Divider from "components/divider/divider.component";
import { SonevoSlider } from "components/slider/sonevo-slider.component";
import { MicNoneOutlined } from "@mui/icons-material";
import SoundDetectionLoudSoundOutline from "components/custom-icons/sound-detection-loud-sound-outline";
import { IS32ConfigurationUnit } from "features/systems/IS32/domain/models/IS32-configuration-unit";
import { Controller, useFormContext } from "react-hook-form";
import { IS32ConfigurationDeviceType } from "features/systems/IS32/domain/models/IS32-configuration-device-type";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { IS32AccordionType } from "features/systems/IS32/domain/models/is32-accordion-type";
import IS32ConfigurationAccordion from "features/systems/IS32/views/configuration-components/IS32-configuration-accordion.component";

interface IProps {
  unit: IS32ConfigurationUnit;
  accordionEnabled: boolean;
}

const IS32GeneralConfiguration = ({ unit, accordionEnabled }: IProps) => {
  const { t } = useTranslation("IS32");

  const {
    selectedIS32ConfigurationUnit,
    viewingMode,
    is32AccordionHook: { generalExpanded, handleAccordionExpandedChange },
  } = useIS32DetailsContextProvider();

  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const registerOptions = {
    room: {
      validate: (value: string) =>
        value.trim().length <= 8 || t("validation.room"),
    },
  };

  const disabled =
    selectedIS32ConfigurationUnit === undefined || viewingMode === "viewing";

  const supportsMicAndSpeakerConfiguration = () =>
    unit.type === IS32ConfigurationDeviceType.Sec0105 ||
    unit.type === IS32ConfigurationDeviceType.Sec0106;

  const updateMicLevelInForm = (
    _event: Event,
    value: number | number[],
    _activeThumb: number,
  ): void => {
    setValue("micLevel", value as number, {
      shouldDirty: true,
    });
  };

  const updateSpeakerLevelInForm = (
    _event: Event,
    value: number | number[],
    _activeThumb: number,
  ): void => {
    setValue("speakerLevel", value as number, {
      shouldDirty: true,
    });
  };

  const header = () => (
    <Divider
      className={styles.divider}
      startText={t("configuration.generalConfiguration.title")}
      variant="h6"
    />
  );

  const details = () => (
    <div className={styles.contentContainer}>
      <div className={styles.textFields}>
        <ViewingModeController
          viewingMode={viewingMode}
          name={"room"}
          control={control}
          rules={registerOptions.room}
          alwaysShowHeader
          label={t("configuration.generalConfiguration.roomName")}
          labelVariant="subtitle1"
          valueVariant="caption"
          displayValue={
            unit.room && unit.room?.trim() !== ""
              ? unit.room
              : t("configuration.generalConfiguration.noRoomNameSet")
          }
          render={({ field }) => (
            <TextField
              {...field}
              id="roomInput"
              variant="outlined"
              error={!!errors["room"]}
              helperText={errors["room"]?.message?.toString()}
              value={field.value}
              disabled={disabled}
              sx={{ width: 160 }}
            />
          )}
        />

        <ViewingModeController
          viewingMode={viewingMode}
          name={"note"}
          control={control}
          alwaysShowHeader
          hideOnViewingWhenEmpty
          label={t("configuration.generalConfiguration.note")}
          labelVariant="subtitle1"
          valueVariant="caption"
          render={({ field }) => (
            <div className={styles.roomTextField}>
              <TextField
                {...field}
                id="noteInput"
                variant="outlined"
                error={!!errors["note"]}
                helperText={errors["note"]?.message?.toString()}
                value={field.value}
                disabled={disabled}
                multiline
                fullWidth
                minRows={2}
                maxRows={6}
                slotProps={{
                  htmlInput: { maxLength: 255 },
                }}
              />
            </div>
          )}
        />
      </div>

      {supportsMicAndSpeakerConfiguration() && (
        <>
          <Controller
            name={"micLevel"}
            control={control}
            render={({ field }) => (
              <SonevoSlider
                label={t("configuration.generalConfiguration.micLevel")}
                value={field.value}
                disabled={disabled}
                onChange={updateMicLevelInForm}
                sliderIconStart={
                  <MicNoneOutlined
                    className={styles.sliderIconSmall}
                    color={disabled ? "disabled" : "primary"}
                  />
                }
                sliderIconEnd={
                  <MicNoneOutlined
                    className={styles.sliderIcon}
                    color={disabled ? "disabled" : "primary"}
                  />
                }
              />
            )}
          />

          <Controller
            name={"speakerLevel"}
            control={control}
            render={({ field }) => (
              <SonevoSlider
                label={t("configuration.generalConfiguration.speakerLevel")}
                value={field.value}
                disabled={disabled}
                onChange={updateSpeakerLevelInForm}
                sliderIconStart={
                  <SoundDetectionLoudSoundOutline
                    className={styles.sliderIconSmall}
                    disabled={disabled}
                  />
                }
                sliderIconEnd={
                  <SoundDetectionLoudSoundOutline
                    className={styles.sliderIcon}
                    disabled={disabled}
                  />
                }
              />
            )}
          />
        </>
      )}
    </div>
  );

  return (
    <>
      {accordionEnabled ? (
        <IS32ConfigurationAccordion
          header={header()}
          details={details()}
          isExpanded={generalExpanded}
          handleAccordionExpandedChange={(expanded) =>
            handleAccordionExpandedChange(IS32AccordionType.General, expanded)
          }
        />
      ) : (
        <>
          {header()}
          {details()}
        </>
      )}
    </>
  );
};

export default IS32GeneralConfiguration;
