import { RefreshRounded } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Typography } from "@mui/material";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import FilterChipOrganisationUnit from "components/organisation-unit-filter-chip/filter-chip-organisation-unit.component";
import SearchBar from "components/search/searchbar";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import "./gateway-filters.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import { FilterValueType } from "features/gateway/domain/models/gateway-filter-value";
import GatewayMonitoringFilter from "features/gateway/views/components/gateway-monitoring-filter";
import { FilterChipOption } from "components/multi-value-filter-chip/models/filter-chip-option";

export default function GatewayFilters(): ReactElement {
  const { t } = useTranslation("gateway");

  const { refresh, gatewayFilterHook } = useGatewaysContextProvider();

  const getTypeFilterValues = (): FilterChipOption[] =>
    Object.entries(GatewayType)
      .filter(([_, value]) => value !== GatewayType.None)
      .map(([key, value]) => ({
        key,
        value: [key],
        label: t(`filterValues.type.${value}`),
      }));

  return (
    <div className="gateway-filters-container">
      <div className="top-row">
        <div className="gateway-filters">
          <SearchBar
            onSearchValueChanged={gatewayFilterHook.submitSearch}
            value={gatewayFilterHook.searchBarValue}
          />
          <FilterListIcon />
          <MultiValueFilterChip
            options={getTypeFilterValues()}
            onOptionsSelected={(keys: string[]) =>
              gatewayFilterHook.selectOption(FilterValueType.Type, keys)
            }
            placeHolder={t("filters.type")}
            selectedOptions={getTypeFilterValues().filter((x) =>
              gatewayFilterHook.activeFilters
                .find((x) => x.filterValueType === FilterValueType.Type)
                ?.values.includes(x.key),
            )}
          />
          <OrganisationUnitTreeProvider.All>
            <FilterChipOrganisationUnit
              onOptionsSelected={(keys: SelectedOrganisationTreeNode[]) => {
                gatewayFilterHook.changeSelectedOrganisationUnits(keys);
                gatewayFilterHook.selectOption(
                  FilterValueType.OrganisationUnit,
                  keys.map((x) => x.id),
                );
              }}
              selectedOptions={gatewayFilterHook.selectedOrganisationUnits}
              placeHolder={t("filters.organisationUnits")}
            />
          </OrganisationUnitTreeProvider.All>

          <div className="clear-filters">
            <Typography
              variant="body1"
              sx={{ color: Constants.Colors.primary }}
              onClick={gatewayFilterHook.clearFilters}
            >
              {t("filters.clearFilters")}
            </Typography>
          </div>
        </div>

        {refresh && (
          <IconButton className="refresh-button" onClick={refresh}>
            <RefreshRounded />
          </IconButton>
        )}
      </div>

      {gatewayFilterHook.monitorFiltersIsSuccess &&
        gatewayFilterHook.monitorFilters.length > 0 && (
          <div className="monitoring-filters">
            <GatewayMonitoringFilter.Offline />
            <GatewayMonitoringFilter.PassiveOffline />
            <GatewayMonitoringFilter.ServiceOffline />
            <GatewayMonitoringFilter.Online />
          </div>
        )}
    </div>
  );
}
