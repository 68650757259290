import { Dialog } from "@mui/material";
import { FormProvider } from "react-hook-form";
import IS32EditConfigurationHeader from "features/systems/IS32/views/IS32-edit-configuration-header.component";
import IS32GeneralConfiguration from "features/systems/IS32/views/configuration-components/IS32-general-configuration.component";
import styles from "features/systems/IS32/views/IS32-edit-configuration.module.scss";
import { IS32ConfigurationDeviceType } from "features/systems/IS32/domain/models/IS32-configuration-device-type";
import IS32AcousticConfiguration from "features/systems/IS32/views/configuration-components/IS32-acoustic-configuration.component";
import IS32ButtonsConfiguration from "features/systems/IS32/views/configuration-components/IS32-buttons-configuration.component";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";

const IS32EditConfigurationPopup = () => {
  const {
    configurationForm,
    isConfigurationDetailsOpen,
    cancelIS32ConfigurationMutation,
    submitForm,
    selectedIS32ConfigurationUnit,
  } = useIS32DetailsContextProvider();

  const supportsAcousticConfiguration = () =>
    selectedIS32ConfigurationUnit?.type ===
      IS32ConfigurationDeviceType.Sec0104 ||
    selectedIS32ConfigurationUnit?.type ===
      IS32ConfigurationDeviceType.Sec0105 ||
    selectedIS32ConfigurationUnit?.type === IS32ConfigurationDeviceType.Sec0106;

  const supportsButtonConfiguration = () =>
    selectedIS32ConfigurationUnit?.type === IS32ConfigurationDeviceType.S0304 ||
    selectedIS32ConfigurationUnit?.type === IS32ConfigurationDeviceType.Sec0106;

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    configurationForm.handleSubmit(submitForm)(event);
  };

  return (
    <Dialog
      open={isConfigurationDetailsOpen}
      onClose={() => cancelIS32ConfigurationMutation()}
      className={styles.popup}
      slotProps={{
        paper: {
          classes: { root: styles.popupPaper },
        },
      }}
    >
      {selectedIS32ConfigurationUnit && (
        <FormProvider {...configurationForm}>
          <form onSubmit={handleSubmitForm} className={styles.detailsForm}>
            <div className={styles.headerContainer}>
              <IS32EditConfigurationHeader />
            </div>
            <fieldset className={styles.fieldset}>
              <IS32GeneralConfiguration
                unit={selectedIS32ConfigurationUnit}
                accordionEnabled={false}
              />
              {supportsAcousticConfiguration() && (
                <IS32AcousticConfiguration accordionEnabled={false} />
              )}
              {supportsButtonConfiguration() && (
                <IS32ButtonsConfiguration accordionEnabled={false} />
              )}
            </fieldset>
          </form>
        </FormProvider>
      )}
    </Dialog>
  );
};

export default IS32EditConfigurationPopup;
