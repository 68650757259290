import PageTab from "components/page-tab/views/page-tab";
import routes from "features/routing/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const IntegrationsNavigation = () => {
  const { t } = useTranslation("integrations");

  const navigate = useNavigate();

  return (
    <PageTab
      onPageChanged={(link: string) => navigate(link)}
      pageTabs={[
        {
          link: routes.devices.path,
          title: t("pageTab.devicesLabel"),
          atLeastOnePermissionOf: routes.devices.atLeastOnePermissionOf,
        },
        {
          link: routes.systems.path,
          title: t("pageTab.systemsLabel"),
          atLeastOnePermissionOf: routes.systems.atLeastOnePermissionOf,
        },
        {
          link: routes.gateways.path,
          title: t("pageTab.gatewaysLabel"),
          atLeastOnePermissionOf: routes.gateways.atLeastOnePermissionOf,
        },
        {
          link: routes.connectors.path,
          title: t("pageTab.connectorsLabel"),
          atLeastOnePermissionOf: routes.connectors.atLeastOnePermissionOf,
        },
      ]}
    />
  );
};

export default IntegrationsNavigation;
