import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "features/connectors/views/connector-details/connector-settings/connector-settings.module.scss";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";
import ConnectorSettingsOrganisation from "./connector-settings-organisation";
import Divider from "components/divider/divider.component";

const ConnectorSettings = () => {
  const { t } = useTranslation("connectors");

  const { connectorDetailsHook } = useConnectorsContextProvider();

  const isViewing = connectorDetailsHook.viewingMode === "viewing";

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      <div
        className={styles.settingsItem}
        data-testid="connectorOrganisationContainer"
      >
        <Typography variant="h5">
          {t("details.settings.locationsLabel")}
        </Typography>
        <div className={styles.settingsItemContent}>
          <ConnectorSettingsOrganisation />
        </div>
      </div>
      <Divider className={styles.divider} />
    </div>
  );
};

export default ConnectorSettings;
