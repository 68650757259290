import { useAxiosClientsContextProvider } from "shared/axios/axios-clients-provider";
import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

const baseUrl = 'api/v1/Notifications';

async function expireNotifications(axiosInstance: AxiosInstance) {
	try {
		await axiosInstance.post(`${baseUrl}/Expire`, {
			timeout: 5000
		});
	} catch (error) {
		// Ignore this because we just want to fire the expire notifications without any interuption
	}
}

export const  useExpireNoficationsMutation = () => {
  const { notificationProcessorInstance } = useAxiosClientsContextProvider();
  return useMutation({
    mutationKey: ['ExpireNotifications'],
    mutationFn: () => expireNotifications(notificationProcessorInstance),
  });
};