import {
  Hearing,
  PinDropOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { SxProps, Theme, Tooltip } from "@mui/material";
import { InBathroomIcon } from "components/custom-icons/in-bathroom-icon";
import IntruderIcon from "components/custom-icons/intruder-icon";
import { OpenDoorIcon } from "components/custom-icons/open-door-icon";
import OutOfBedIcon from "components/custom-icons/out-of-bed-icon";
import { OutOfRoomIcon } from "components/custom-icons/out-of-room-icon";
import SolidPersonFallingIcon from "components/custom-icons/solid-person-falling-icon";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import { ScenarioType } from "models/scenario-type";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface Props {
  organisationUnitScenario?: OrganisationUnitScenario;
  scenarioType?: ScenarioType;
  sx?: SxProps<Theme>;
  isVisible?: boolean;
}

const OrganisationUnitScenarioIcon = ({
  organisationUnitScenario,
  scenarioType,
  sx,
  isVisible,
}: Readonly<Props>) => {
  const { t } = useTranslation("residentsSettings");

  const type = organisationUnitScenario?.scenarioType ?? scenarioType;

  const getColor = (): string => {
    if (isVisible === undefined || !!isVisible) {
      return organisationUnitScenario !== undefined &&
        !organisationUnitScenario.isActive
        ? Constants.Colors.inactive
        : Constants.Colors.primary;
    }

    return "transparent";
  };

  const getTooltipText = () => {
    if (isVisible) {
      switch (type) {
        case ScenarioType.Bed:
          return t("scenarioName.Bed");
        case ScenarioType.Room:
          return t("scenarioName.Room");
        case ScenarioType.Door:
          return t("scenarioName.Door");
        case ScenarioType.Bathroom:
          return t("scenarioName.Bathroom");
        case ScenarioType.Intruder:
          return t("scenarioName.Intruder");
        case ScenarioType.FallDetection:
          return t("scenarioName.FallDetection");
        case ScenarioType.Video:
          return t("scenarioName.Video");
        case ScenarioType.Acoustic:
          return t("scenarioName.Acoustic");
        case ScenarioType.CurrentLocation:
          return t("scenarioName.CurrentLocation");
        default:
          return;
      }
    }
  };

  switch (type) {
    case ScenarioType.Bed:
      return (
        <OutOfBedIcon
          sx={sx}
          color={getColor()}
          tooltipText={getTooltipText()}
        />
      );
    case ScenarioType.Room:
      return (
        <OutOfRoomIcon
          sx={sx}
          color={getColor()}
          tooltipText={getTooltipText()}
        />
      );
    case ScenarioType.Door:
      return (
        <OpenDoorIcon
          sx={sx}
          color={getColor()}
          tooltipText={getTooltipText()}
        />
      );
    case ScenarioType.Bathroom:
      return (
        <InBathroomIcon
          sx={sx}
          color={getColor()}
          tooltipText={getTooltipText()}
        />
      );
    case ScenarioType.Intruder:
      return (
        <IntruderIcon
          sx={sx}
          color={getColor()}
          tooltipText={getTooltipText()}
        />
      );
    case ScenarioType.FallDetection:
      return (
        <SolidPersonFallingIcon
          sx={sx}
          color={getColor()}
          tooltipText={getTooltipText()}
        />
      );
    case ScenarioType.Video:
      return (
        <Tooltip title={getTooltipText()}>
          <VideocamOutlined
            sx={{
              ...sx,
              color: getColor(),
            }}
          />
        </Tooltip>
      );
    case ScenarioType.Acoustic:
      return (
        <Tooltip title={getTooltipText()}>
          <Hearing
            sx={{
              ...sx,
              color: getColor(),
            }}
          />
        </Tooltip>
      );
    case ScenarioType.CurrentLocation:
      return (
        <Tooltip title={getTooltipText()}>
          <PinDropOutlined
            sx={{
              ...sx,
              color: getColor(),
            }}
          />
        </Tooltip>
      );
    default:
      return <></>;
  }
};

export default OrganisationUnitScenarioIcon;
