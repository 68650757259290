import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./alarm-scenario-configuration.component.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import BaseAlarmScenarionConfiguration from "../../domain/models/configurations/base-alarm-scenario-configuration";
import TimeFrame from "../../domain/models/configurations/time-frame";
import { useResidentSettingsContextProvider } from "../../context/resident-settings-provider";
import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import OrganisationUnitScenario from "../../domain/models/organisation-unit-scenario";
import TimeFrames from "./time-frames.component";

enum ActiveOption {
  StatusAndAlarms = 0,
  AlarmsOnly = 1,
  StatusOnly = 2,
}

interface IProps {
  organisationUnitScenario: OrganisationUnitScenario;
  scenarioTitle: string;
  intentionOutOfBedEnabled: boolean;
  index: number;
}

export function BedScenarioConfiguration(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const [configuration, setConfiguration] = useState(
    jsonParseWithLowerCaseKeys(
      props.organisationUnitScenario.configuration,
    ) as BaseAlarmScenarionConfiguration,
  );

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const alarmTimeOutOptions = [
    ...(props.intentionOutOfBedEnabled
      ? [
          <MenuItem
            data-testid={`alarm-timeout-item-intentionOutOfBed`}
            key={`alarm-timeout-item-intentionOutOfBed`}
            value={-1}
          >
            {t("scenarioConfiguration.intentionOutOfBed")}
          </MenuItem>,
        ]
      : []),
    <MenuItem
      data-testid={`alarm-timeout-item-direct`}
      key={`alarm-timeout-item-direct`}
      value={0}
    >
      {t("scenarioConfiguration.alarmTimeOutDirect")}
    </MenuItem>,
    <MenuItem
      data-testid={`alarm-timeout-item-one-minute`}
      key={`alarm-timeout-item-one-minute`}
      value={60}
    >
      {t("scenarioConfiguration.alarmTimeOutOneMinute")}
    </MenuItem>,
    <MenuItem
      data-testid={`alarm-timeout-item-five-minutes`}
      key={`alarm-timeout-item-five-minutes`}
      value={300}
    >
      {t("scenarioConfiguration.alarmTimeOutFiveMinutes")}
    </MenuItem>,
    <MenuItem
      data-testid={`alarm-timeout-item-fifteen-minutes`}
      key={`alarm-timeout-item-fifteen-minutes`}
      value={900}
    >
      {t("scenarioConfiguration.alarmTimeOutFifteenMinutes")}
    </MenuItem>,
    <MenuItem
      data-testid={`alarm-timeout-item-thirty-minutes`}
      key={`alarm-timeout-item-thirty-minutes`}
      value={1800}
    >
      {t("scenarioConfiguration.alarmTimeOutThirtyMinutes")}
    </MenuItem>,
    <MenuItem
      data-testid={`alarm-timeout-item-sixty-minutes`}
      key={`alarm-timeout-item-sixty-minutes`}
      value={3600}
    >
      {t("scenarioConfiguration.alarmTimeOutSixtyMinutes")}
    </MenuItem>,
  ];

  const activeOptions = [
    <MenuItem
      data-testid={`status-and-alarms-active`}
      key={`status-and-alarms-active`}
      value={ActiveOption.StatusAndAlarms}
    >
      {t("scenarioConfiguration.statusAndAlarmsActive")}
    </MenuItem>,
    <MenuItem
      data-testid={`alarm-active`}
      key={`alarm-active`}
      value={ActiveOption.AlarmsOnly}
    >
      {t("scenarioConfiguration.alarmActive")}
    </MenuItem>,
    <MenuItem
      data-testid={`status-active`}
      key={`status-active`}
      value={ActiveOption.StatusOnly}
    >
      {t("scenarioConfiguration.statusActive")}
    </MenuItem>,
  ];

  function getAlarmTimeOutSelectValueOrDefault(value: number | null): number {
    const possibleValues = [
      ...(props.intentionOutOfBedEnabled ? [-1] : []),
      0,
      60,
      300,
      900,
      1800,
      3600,
    ];
    if (value && possibleValues.find((x) => x === value)) {
      return value;
    }

    return 0;
  }

  function updateActiveOption(selectedOption: string | number): void {
    let newConfiguration;
    switch (selectedOption) {
      case ActiveOption.StatusAndAlarms:
        newConfiguration = {
          ...configuration,
          isAlarmActive: true,
          isStatusActive: true,
        };
        residentDetailsHook.scenarioConfigurationUpdated(
          newConfiguration,
          props.index,
        );
        setConfiguration(newConfiguration);
        break;
      case ActiveOption.AlarmsOnly:
        newConfiguration = {
          ...configuration,
          isAlarmActive: true,
          isStatusActive: false,
        };
        residentDetailsHook.scenarioConfigurationUpdated(
          newConfiguration,
          props.index,
        );
        setConfiguration(newConfiguration);
        break;
      case ActiveOption.StatusOnly:
        newConfiguration = {
          ...configuration,
          isAlarmActive: false,
          isStatusActive: true,
        };
        residentDetailsHook.scenarioConfigurationUpdated(
          newConfiguration,
          props.index,
        );
        setConfiguration(newConfiguration);
        break;
    }
  }

  useEffect(() => {
    residentDetailsHook.clearValidationErrorForScenario(props.index);
    setConfiguration(
      jsonParseWithLowerCaseKeys(
        props.organisationUnitScenario.configuration,
      ) as BaseAlarmScenarionConfiguration,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organisationUnitScenario, residentDetailsHook.viewingMode]);

  function determineSelectedValue(): ActiveOption {
    if (configuration.isAlarmActive && !configuration.isStatusActive) {
      return ActiveOption.AlarmsOnly;
    } else if (configuration.isStatusActive && !configuration.isAlarmActive) {
      return ActiveOption.StatusOnly;
    }

    return ActiveOption.StatusAndAlarms;
  }

  function timeFrameValuesChanged(
    newTimeFrames: TimeFrame[] | null,
    newActiveAllDay: boolean,
  ): void {
    const newConfiguration = {
      ...configuration,
      timeFrames: newTimeFrames,
      sendAlarmAllDay: newActiveAllDay,
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  return (
    <div className="alarm-scenario-configuration-container">
      <div className="restore-row">
        <Typography variant="subtitle1">
          {`${props.scenarioTitle} ${t(
            `scenarioConfiguration.scenarioDescription`,
          )}`}
        </Typography>
      </div>
      <div className="alarm-active-row">
        <FormControl sx={{ minWidth: "100%" }}>
          <InputLabel id="alarm-active-label">
            {t("scenarioConfiguration.alarmActiveLabel")}
          </InputLabel>
          <Select
            inputProps={{ "data-testid": "AlarmActiveInput" }}
            id="alarmActiveInput"
            variant="outlined"
            className="alarm-active-select"
            labelId="alarm-active-label"
            label={t("scenarioConfiguration.alarmActiveLabel")}
            value={determineSelectedValue()}
            defaultValue={0}
            disabled={residentDetailsHook.viewingMode === "viewing"}
            onChange={(event, _) => {
              updateActiveOption(event.target.value);
            }}
          >
            {activeOptions.map((menuItem) => menuItem)}
          </Select>
        </FormControl>
      </div>
      {configuration.isAlarmActive && (
        <>
          <TimeFrames
            activeAllDay={configuration.sendAlarmAllDay}
            index={props.index}
            timeFrames={configuration.timeFrames}
            valuesChanged={timeFrameValuesChanged}
            timeFramesDescription={t(
              "scenarioConfiguration.timeFramesDescriptionAlarm",
            )}
          />

          <div className="alarm-row">
            <FormControl sx={{ minWidth: "100%" }}>
              <InputLabel id="send-alarm-label">
                {t("scenarioConfiguration.sendAlarmLabel")}
              </InputLabel>
              <Select
                inputProps={{ "data-testid": "sendAlarmInput" }}
                id="sendAlarmInput"
                variant="outlined"
                className="send-alarm-select"
                labelId="send-alarm-label"
                label={t("scenarioConfiguration.sendAlarmLabel")}
                value={getAlarmTimeOutSelectValueOrDefault(
                  configuration.alarmTimeOut,
                )}
                defaultValue={0}
                disabled={residentDetailsHook.viewingMode === "viewing"}
                onChange={(event, _) => {
                  const newConfiguration = {
                    ...configuration,
                    alarmTimeOut: event.target.value as number,
                  };
                  residentDetailsHook.scenarioConfigurationUpdated(
                    newConfiguration,
                    props.index,
                  );
                  setConfiguration(newConfiguration);
                }}
              >
                {alarmTimeOutOptions.map((menuItem) => menuItem)}
              </Select>
            </FormControl>
          </div>
        </>
      )}
    </div>
  );
}

export default BedScenarioConfiguration;
