import { TextField, Typography } from "@mui/material";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import routes from "features/routing/routes";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { SystemType } from "features/systems/domain/models/system";
import IS32ScanConfiguration from "features/systems/IS32/views/IS32-scan-configuration";
import IS32SystemEventPopup from "features/systems/IS32/views/IS32-system-event-popup";
import styles from "features/systems/views/details/system-information.module.scss";
import moment from "moment";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SystemMonitoringAlert from "./system-monitoring-alert";

const SystemInformation = () => {
  const { t } = useTranslation("systems");

  const { systemsDetailsHook } = useSystemsContextProvider();

  const { hasPermission } = useAuth();

  const navigate = useNavigate();

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { form, selectedSystem, viewingMode } = systemsDetailsHook;

  const watchSystemType = form.watch("type");
  const isViewing = viewingMode === "viewing";
  const isEditing = viewingMode === "editing";

  const hasSelectedType = SystemType.Unknown !== watchSystemType;
  const showNoteInView = isViewing && selectedSystem?.note;
  const showStatusInView = isViewing || isEditing;

  const textField = (name: string, label: string, multiline = false) => (
    <div className={styles.informationItem}>
      <Typography variant="h5">{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            slotProps={{
              htmlInput: { maxLength: 255 },
            }}
            id={`${name}Input`}
            variant="outlined"
            error={!!errors[name]}
            helperText={errors[name]?.message?.toString()}
            multiline={multiline}
            minRows={multiline ? 8 : undefined}
            maxRows={multiline ? 12 : undefined}
            fullWidth
          />
        )}
      />
    </div>
  );

  const readOnlyField = (
    label: string,
    value: string,
    respectTextFormat = false,
  ) => (
    <div className={styles.informationItem}>
      <Typography variant="h5">{label}</Typography>
      <Typography
        variant="subtitle1"
        className={`${respectTextFormat && styles.textFormat}`}
      >
        {value}
      </Typography>
    </div>
  );

  const communicationDate = (lastCommunicationDate?: Date) =>
    lastCommunicationDate
      ? t("details.information.statusLastCommunicationDateLabel", {
          lastCommunicationDate: moment(lastCommunicationDate)
            .format("DD-MM-YYYY HH:mm")
            .toString(),
        })
      : t("details.information.statusUnknownLabel");

  return (
    <div className={`${styles.container} ${isViewing && styles.viewing}`}>
      <Typography variant="h2">
        {isViewing ? selectedSystem?.name : t("details.information.editTitle")}
      </Typography>

      <SystemMonitoringAlert system={selectedSystem} />

      {readOnlyField(
        t("details.information.typeLabel"),
        t(`details.information.type.${selectedSystem?.type}`),
      )}

      {isViewing && (
        <>
          <div className={styles.informationItem}>
            <Typography variant="h5">
              {t("details.information.connectionLabel")}
            </Typography>
            <Typography
              variant="subtitle1"
              className={styles.linkButton}
              onClick={() => {
                navigate(
                  `${routes.gateways.path}?id=${selectedSystem?.gateway.id}`,
                );
              }}
            >
              {selectedSystem?.gateway?.name ?? ""}
            </Typography>
          </div>

          {readOnlyField(
            t("details.information.audioLabel"),
            selectedSystem?.audioAvailable
              ? t("details.information.yes")
              : t("details.information.no"),
          )}
        </>
      )}

      {hasSelectedType && (
        <>
          {isEditing && textField("name", t("details.information.nameLabel"))}

          {readOnlyField(
            t("details.information.macAddressLabel"),
            selectedSystem?.macAddress ?? "",
          )}

          {readOnlyField(
            t("details.information.ipAddressLabel"),
            selectedSystem?.ipAddress ?? "",
          )}

          {isEditing &&
            textField("note", t("details.information.noteLabel"), true)}
          {showNoteInView &&
            readOnlyField(
              t("details.information.noteLabel"),
              selectedSystem.note ?? "",
              true,
            )}

          {showStatusInView &&
            readOnlyField(
              t("details.information.statusLabel"),
              communicationDate(selectedSystem?.lastCommunicationDate),
            )}

          {selectedSystem?.type === SystemType.IS32 &&
            isViewing &&
            hasPermission(Permission.UpdateSystemsGatewaysConnectors) && (
              <>
                <IS32ScanConfiguration />
                <IS32SystemEventPopup />
              </>
            )}
        </>
      )}
    </div>
  );
};

export default SystemInformation;
