import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { ReadSystemsQuery } from "features/systems/domain/models/read-systems-query";
import { System } from "features/systems/domain/models/system";
import { SystemFilterValueType } from "features/systems/domain/models/system-filter-value";
import SystemFilter from "features/systems/domain/models/system-filter";
import useTableHook, { ITableHook } from "hooks/table-hook";
import { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";
import SystemMonitoringFilter from "features/systems/domain/models/system-monitoring-filter";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { useGetMonitorFiltersQuery } from "features/systems/domain/reducers/systems.reducer";

export interface SystemFiltersHook {
  activeFilters: SystemFilter[];
  searchBarValue: string;
  selectedOrganisationUnits: SelectedOrganisationTreeNode[];
  changeSelectedOrganisationUnits: (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => void;

  submitSearch: (query: string) => void;
  selectOption: (
    filterValueType: SystemFilterValueType,
    keys: string[],
  ) => void;
  clearFilters: () => void;

  monitorFilters: SystemMonitoringFilter[];
  monitorFiltersIsSuccess: boolean;
  getMonitorFilter: (monitoringType: MonitoringType) => SystemMonitoringFilter;
  loadMonitorFilters: () => void;
  selectMonitorFilter: (
    monitoringType: MonitoringType,
    selected: boolean,
  ) => void;
  isActiveMonitorFilter: (monitoringType: MonitoringType) => boolean;

  readSystemsQuery: ReadSystemsQuery;

  table: ITableHook<System>;
}

const useSystemFilters = (): SystemFiltersHook => {
  const [activeFilters, setActiveFilters] = useState<SystemFilter[]>([]);
  const [readSystemsQuery, setReadSystemsQuery] = useState<ReadSystemsQuery>({
    filters: Array<SystemFilter>(),
  } as ReadSystemsQuery);

  const table = useTableHook<System>({
    defaultSort: {
      isAscending: true,
      property: "name",
    },
    sessionStorageKey: "systems-sort",
  });

  const {
    data: monitorFiltersData,
    isSuccess: monitorFiltersIsSuccess,
    refetch: refetchMonitorFilters,
  } = useGetMonitorFiltersQuery();

  const [systemFiltersFromSessionStorage, setSystemFiltersFromSessionStorage] =
    useSessionStorage<SystemFilter[]>("system-filters", []);
  const [
    selectedOrganisationUnitsFromSessionStorage,
    setSelectedOrganisationUnitsFromSessionStorage,
  ] = useSessionStorage<SelectedOrganisationTreeNode[]>(
    "selected-organisation-units-system-filters",
    [],
  );
  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-system-filters", "");

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    table.currentPage,
    table.currentRowsPerPage,
    table.sortFromSessionStorage,
    systemFiltersFromSessionStorage,
    searchBarValueFromSessionStorage,
  ]);

  const submitSearch = (query: string) => {
    setSearchBarFromSessionStorage(query);
    table.resetPaging();
  };

  const selectOption = (
    filterValueType: SystemFilterValueType,
    keys: string[],
  ) => {
    let updatedFiltersList = [...readSystemsQuery.filters];
    const filterIndex = updatedFiltersList.findIndex(
      (x) => x.filterValueType === filterValueType,
    );

    if (filterIndex !== -1) {
      updatedFiltersList[filterIndex] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setSystemFiltersFromSessionStorage(updatedFiltersList);
    table.resetPaging();
  };

  const changeSelectedOrganisationUnits = (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) =>
    setSelectedOrganisationUnitsFromSessionStorage(selectedOrganisationUnits);

  const clearFilters = () => {
    setActiveFilters([]);
    setSystemFiltersFromSessionStorage([]);
    setSelectedOrganisationUnitsFromSessionStorage([]);
    setReadSystemsQuery((prevState) => ({
      ...prevState,
      filters: [],
    }));

    table.resetPaging();
  };

  const getMonitorFilter = (monitoringType: MonitoringType) => {
    let filter = monitorFiltersData?.find(
      (filter) => filter.monitoringFilterType === monitoringType,
    );
    if (filter) {
      return filter;
    }

    return {
      monitoringFilterType: MonitoringType.All,
      count: monitorFiltersData?.reduce(
        (total, filter) => total + filter.count,
        0,
      ),
    } as SystemMonitoringFilter;
  };

  const isActiveMonitorFilter = (monitoringType: MonitoringType) => {
    let filter = activeFilters.find(
      (x) => x.filterValueType === SystemFilterValueType.Monitor,
    );
    if (!filter) {
      return false;
    }

    return filter.values.find((x) => x === monitoringType) !== undefined;
  };

  const loadMonitorFilters = () => {
    refetchMonitorFilters();
  };

  const selectMonitorFilter = (
    monitoringType: MonitoringType,
    selected: boolean,
  ) => {
    let activeMonitorFilters = [
      ...(activeFilters.find(
        (x) => x.filterValueType === SystemFilterValueType.Monitor,
      )?.values ?? []),
    ];

    if (
      selected &&
      activeMonitorFilters.find((x) => x === monitoringType) === undefined
    ) {
      activeMonitorFilters = [...activeMonitorFilters, monitoringType];
    } else if (
      !selected &&
      activeMonitorFilters.find((x) => x === monitoringType) !== undefined
    ) {
      let activeMonitorFilterIndex = activeMonitorFilters.findIndex(
        (x) => x === monitoringType,
      );
      if (activeMonitorFilterIndex >= 0) {
        activeMonitorFilters.splice(activeMonitorFilterIndex, 1);
      }
    }

    selectOption(SystemFilterValueType.Monitor, activeMonitorFilters);
    loadMonitorFilters();
  };

  const buildAndSaveQuery = () => {
    setActiveFilters(systemFiltersFromSessionStorage);
    setReadSystemsQuery((prevState) => ({
      ...prevState,
      pagination: table.currentPagination[0],
      sort: table.sortFromSessionStorage ?? prevState.sort,
      filters: systemFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
    }));
  };

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,
    selectedOrganisationUnits: selectedOrganisationUnitsFromSessionStorage,
    changeSelectedOrganisationUnits,

    readSystemsQuery,

    selectOption,
    submitSearch,
    clearFilters,

    monitorFiltersIsSuccess,
    monitorFilters: monitorFiltersData ?? [],
    getMonitorFilter,
    loadMonitorFilters,
    selectMonitorFilter,
    isActiveMonitorFilter,

    table,
  };
};

export default useSystemFilters;
