import { SvgIcon, SxProps, Theme } from "@mui/material";

interface Props {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
}

export const IntegrationsIcon = ({ sx }: Readonly<Props>) => {
  return (
    <SvgIcon sx={sx} viewBox="0 0 24 24">
      <path d="M18.8033 7.1967C19.1938 6.80618 19.197 6.16763 18.7634 5.82564C17.6953 4.9834 16.4366 4.40661 15.0909 4.15021C13.4165 3.83118 11.6848 4.02294 10.1208 4.70058C8.55676 5.37822 7.23258 6.51044 6.3202 7.95022C5.61136 9.06881 5.17652 10.3329 5.04359 11.6401L0.856797 11.6401C0.383601 11.6401 0 12.0237 0 12.4969C0 12.9701 0.383601 13.3537 0.856797 13.3537L5.04297 13.3537C5.19397 14.8497 5.73965 16.2827 6.62885 17.5037C7.63227 18.8816 9.02677 19.926 10.6313 20.5013C12.2358 21.0766 13.9762 21.1561 15.6265 20.7297C16.9529 20.387 18.1718 19.7301 19.1832 18.8207C19.5939 18.4514 19.5495 17.8144 19.1346 17.4499L19.095 17.4152C18.6801 17.0507 18.0524 17.0988 17.6282 17.4524C17.4457 17.6045 17.2552 17.7462 17.0575 17.877C16.133 18.5816 14.9787 19 13.7266 19C13.3387 19 12.9603 18.9599 12.5951 18.8835C12.164 18.8224 11.7381 18.7175 11.3241 18.5691C10.107 18.1327 9.04926 17.3405 8.28816 16.2954C7.52706 15.2503 7.09777 14.0004 7.05602 12.7082C7.01427 11.416 7.36199 10.141 8.05404 9.04895C8.74609 7.95685 9.7505 7.09806 10.9368 6.58406C12.1232 6.07006 13.4367 5.92461 14.7067 6.1666C15.6477 6.34589 16.5327 6.73174 17.2999 7.29148C17.7461 7.61699 18.3755 7.62446 18.7661 7.23394L18.8033 7.1967ZM17.2536 11.6399C16.863 9.92791 15.331 8.65039 13.5007 8.65039C11.3748 8.65039 9.65137 10.3738 9.65137 12.4997C9.65137 14.6256 11.3748 16.349 13.5007 16.349C15.3331 16.349 16.8665 15.0685 17.2549 13.3535H22.2772C22.7504 13.3535 23.134 12.9699 23.134 12.4967C23.134 12.0235 22.7504 11.6399 22.2772 11.6399H17.2536ZM15.64 12.4997C15.64 13.6812 14.6822 14.639 13.5007 14.639C12.3192 14.639 11.3614 13.6812 11.3614 12.4997C11.3614 11.3182 12.3192 10.3604 13.5007 10.3604C14.6822 10.3604 15.64 11.3182 15.64 12.4997Z" />
    </SvgIcon>
  );
};

export default IntegrationsIcon;
