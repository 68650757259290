import { RegexExpressions } from "constants/regex/regex-expressions";
import {
  useValidateBLEBeaconMutation,
  useValidateDeviceExternalIdMutation,
  useValidateDeviceNameMutation,
} from "features/device/domain/reducers/device.reducer";
import { DeviceFunction } from "features/device/models/device-function";
import { useTranslation } from "react-i18next";
import { ApiResponse, isOkResponse } from "redux-base/create-api-utils";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import { SystemType } from "features/systems/domain/models/system";

interface DeviceValidationHook {
  validateName: () => Promise<boolean | string>;
  validateExternalId: () => Promise<boolean | string>;
  validateBeacon: () => Promise<boolean | string>;
}

const useDeviceValidation = (): DeviceValidationHook => {
  const { t } = useTranslation("deviceDetails");
  const { currentDevice, currentViewingMode, form } = useDeviceDetailsHook();
  const [validateDeviceName] = useValidateDeviceNameMutation();
  const [validateDeviceExternalId] = useValidateDeviceExternalIdMutation();
  const [validateBLEBeacon] = useValidateBLEBeaconMutation();

  const isIs32Device = currentDevice?.system?.type === SystemType.IS32;

  const validateName = async (): Promise<boolean | string> => {
    if (currentDevice?.importDate) return true;

    const name = form.getValues("name")?.trim();
    if (!name) return true;

    const id = form.getValues("id");
    const gatewayId = isIs32Device
      ? currentDevice.system?.gatewayId
      : form.getValues("gatewayId");

    const result = (await validateDeviceName({
      id: currentViewingMode === "editing" ? id : undefined,
      deviceName: name,
      gatewayId: gatewayId !== "" ? gatewayId : undefined,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) return true;

    return t("info.uniqueNameHelperText");
  };

  const validateExternalId = async (): Promise<boolean | string> => {
    const externalId = form.getValues("externalId")?.trim();
    if (!externalId) return true;

    const id = form.getValues("id");

    let result = (await validateDeviceExternalId({
      id: currentViewingMode === "editing" ? id : undefined,
      externalId: externalId,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) return true;

    return t("info.uniqueExternalIdHelperText");
  };

  const validateBeacon = async (): Promise<boolean | string> => {
    if (currentDevice?.importDate) return true;

    const proximityId = form.getValues("deviceProximityId");
    if (!proximityId) return true;

    const regex = new RegExp(RegexExpressions.GUID);
    if (!regex.test(proximityId)) return t("BLEBeacon.invalidGuid");

    const functionValue = form.getValues("function");
    const response = (await validateBLEBeacon({
      deviceId: currentDevice?.id,
      deviceProximityId: proximityId,
      function: DeviceFunction[functionValue as keyof typeof DeviceFunction],
    })) as ApiResponse<void>;

    if (isOkResponse(response)) return true;

    return t("BLEBeacon.invalidDevice");
  };

  return {
    validateName,
    validateExternalId,
    validateBeacon,
  };
};

export default useDeviceValidation;
