import MonitoringFilter from "components/monitoring/monitoring-filter.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

const Offline = () => {
  const { t } = useTranslation("monitoring");
  const { systemsFilterHook } = useSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.systemOffline")}
      type={MonitoringType.SystemOffline}
      countColor={Constants.Colors.error}
      count={
        systemsFilterHook.getMonitorFilter(MonitoringType.SystemOffline).count
      }
      isSelected={systemsFilterHook.isActiveMonitorFilter(
        MonitoringType.SystemOffline,
      )}
      onSelected={(selected) => {
        systemsFilterHook.selectMonitorFilter(
          MonitoringType.SystemOffline,
          selected,
        );
      }}
    />
  );
};

const Warning = () => {
  const { t } = useTranslation("monitoring");
  const { systemsFilterHook } = useSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.systemWarning")}
      type={MonitoringType.SystemWarning}
      countColor={Constants.Colors.brightOrange}
      count={
        systemsFilterHook.getMonitorFilter(MonitoringType.SystemWarning).count
      }
      isSelected={systemsFilterHook.isActiveMonitorFilter(
        MonitoringType.SystemWarning,
      )}
      onSelected={(selected) => {
        systemsFilterHook.selectMonitorFilter(
          MonitoringType.SystemWarning,
          selected,
        );
      }}
    />
  );
};

const Online = () => {
  const { t } = useTranslation("monitoring");
  const { systemsFilterHook } = useSystemsContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.systemOnline")}
      type={MonitoringType.SystemOnline}
      countColor={Constants.Colors.success}
      count={
        systemsFilterHook.getMonitorFilter(MonitoringType.SystemOnline).count
      }
      isSelected={systemsFilterHook.isActiveMonitorFilter(
        MonitoringType.SystemOnline,
      )}
      onSelected={(selected) => {
        systemsFilterHook.selectMonitorFilter(
          MonitoringType.SystemOnline,
          selected,
        );
      }}
    />
  );
};

const SystemMonitoringFilter = {
  Offline: Offline,
  Warning: Warning,
  Online: Online,
};

export default SystemMonitoringFilter;
