import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckRounded from "@mui/icons-material/CheckRounded";
import styles from "./header.component.module.scss";
import { ReactElement } from "react";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import { useFormContext } from "react-hook-form";

interface IProps {
  headerText?: string;
  isSubmitting: boolean;
  onCancelButtonClicked: () => void;
}

export function Header(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("residentsSettings");
  const { getValues } = useFormContext();
  const { residentsBulkEditHook } = useResidentSettingsContextProvider();

  return (
    <div className={styles.container}>
      <div className={styles.topHeaderContainer}>
        <div className={styles.actionsContainer}>
          <Button
            data-testid="cancelButton"
            variant="text"
            onClick={props.onCancelButtonClicked}
          >
            {t("details.cancelFormButton")}
          </Button>
          <Button
            data-testid="submitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={props.isSubmitting}
            disabled={getValues("bulkScenarioActiveStates").length === 0}
            loadingPosition="start"
          >
            {`${residentsBulkEditHook.selectedResidents.length} ${t(
              "details.saveFormButton",
            )}`}
          </Button>
        </div>
      </div>
      <Typography variant="h4" data-testid="residentDetailHeader">
        {props.headerText}
      </Typography>
    </div>
  );
}

export default Header;
