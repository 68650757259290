import { useAuth } from "features/authentication/providers/authentication.provider";
import routes from "features/routing/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RedirectToIntegrationDetails = () => {
  const { hasAtLeastOnePermissionOf, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasAtLeastOnePermissionOf(routes.devices.atLeastOnePermissionOf)) {
      navigate(routes.devices.path, { replace: true });
    } else if (
      hasAtLeastOnePermissionOf(routes.systems.atLeastOnePermissionOf)
    ) {
      navigate(routes.systems.path, { replace: true });
    } else if (
      hasAtLeastOnePermissionOf(routes.gateways.atLeastOnePermissionOf)
    ) {
      navigate(routes.gateways.path, { replace: true });
    } else if (
      hasAtLeastOnePermissionOf(routes.connectors.atLeastOnePermissionOf)
    ) {
      navigate(routes.connectors.path, { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  }, [user, hasAtLeastOnePermissionOf, navigate]);

  return <></>;
};

export default RedirectToIntegrationDetails;
