import { Switch, Typography } from "@mui/material";
import styles from "features/systems/IS32/views/configuration-components/IS32-buttons-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Divider from "components/divider/divider.component";
import { Controller, useFormContext } from "react-hook-form";
import { useIS32DetailsContextProvider } from "features/systems/IS32/providers/IS32-details.provider";
import { IS32AccordionType } from "features/systems/IS32/domain/models/is32-accordion-type";
import IS32ConfigurationAccordion from "features/systems/IS32/views/configuration-components/IS32-configuration-accordion.component";
import { IS32ConfigurationDeviceType } from "features/systems/IS32/domain/models/IS32-configuration-device-type";

interface Props {
  accordionEnabled: boolean;
}

const IS32ButtonsConfiguration = ({ accordionEnabled }: Props) => {
  const { t } = useTranslation("IS32");

  const {
    selectedIS32ConfigurationUnit,
    viewingMode,
    is32AccordionHook: { buttonsExpanded, handleAccordionExpandedChange },
  } = useIS32DetailsContextProvider();
  const { control } = useFormContext();

  const disabled =
    selectedIS32ConfigurationUnit === undefined || viewingMode === "viewing";

  const supportsNightFindLightsOffline =
    selectedIS32ConfigurationUnit?.type === IS32ConfigurationDeviceType.Sec0106;

  const header = () => (
    <Divider
      className={styles.divider}
      startText={t("configuration.buttonsConfiguration.title")}
      variant="h6"
    />
  );

  const details = () => (
    <div className={styles.contentContainer}>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t("configuration.buttonsConfiguration.resetInRoomForToilets")}
        </Typography>
        <Controller
          name="resetInRoomForToilets"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="resetInRoomForToiletsInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t("configuration.buttonsConfiguration.resetInRoomForBeds")}
        </Typography>
        <Controller
          name="resetInRoomForBeds"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="resetInRoomForBedsInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t("configuration.buttonsConfiguration.onlyResetNoPresenceInToilets")}
        </Typography>
        <Controller
          name="onlyResetNoPresenceInToilets"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="onlyResetNoPresenceInToiletsInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t(
            "configuration.buttonsConfiguration.onlyResetNoPresenceInRoomBedsAndToilets",
          )}
        </Typography>
        <Controller
          name="onlyResetNoPresenceInRoomBedsAndToilets"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="onlyResetNoPresenceInRoomBedsAndToiletsInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t(
            "configuration.buttonsConfiguration.assistanceToiletByPresenceInRoom",
          )}
        </Typography>
        <Controller
          name="assistanceToiletByPresenceInRoom"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="assistanceToiletByPresenceInRoomInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className={styles.switchRow}>
        <Typography className={styles.switchRowText} variant="subtitle1">
          {t(
            "configuration.buttonsConfiguration.assistanceBedByPresenceInRoom",
          )}
        </Typography>
        <Controller
          name="assistanceBedByPresenceInRoom"
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              id="assistanceBedByPresenceInRoomInput"
              checked={field.value}
              disabled={disabled}
            />
          )}
        />
      </div>
      {supportsNightFindLightsOffline && (
        <div className={styles.switchRow}>
          <Typography className={styles.switchRowText} variant="subtitle1">
            {t("configuration.buttonsConfiguration.nightFindLightsOffline")}
          </Typography>
          <Controller
            name="nightFindLightsOffline"
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                id="nightFindLightsOfflineInput"
                checked={field.value}
                disabled={disabled}
              />
            )}
          />
        </div>
      )}
    </div>
  );

  return (
    <>
      {accordionEnabled ? (
        <IS32ConfigurationAccordion
          header={header()}
          details={details()}
          isExpanded={buttonsExpanded}
          handleAccordionExpandedChange={(expanded) =>
            handleAccordionExpandedChange(IS32AccordionType.Buttons, expanded)
          }
        />
      ) : (
        <>
          {header()}
          {details()}
        </>
      )}
    </>
  );
};

export default IS32ButtonsConfiguration;
