import { useTranslation } from "react-i18next";
import { ReactElement, useEffect, useRef, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useDebounceValue } from "usehooks-ts";
import { useConfirmationPopupContextProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";

interface IProps {
  onSearchValueChanged: (value: string) => void;
  value: string;
  requireUserConfirmation?: boolean;
}

export default function SearchBar(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("search");
  const { showUnsavedChangesPopup } = useConfirmationPopupContextProvider();

  const [localValue, setLocalValue] = useState(props.value);
  const [debouncedValue] = useDebounceValue<string>(localValue, 300);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    props.onSearchValueChanged(localValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const changeLocalValue = (newValue: string) => {
    props.requireUserConfirmation
      ? showUnsavedChangesPopup(() => setLocalValue(newValue))
      : setLocalValue(newValue);
  };

  return (
    <TextField
      placeholder={t("searchBarPlaceHolder")}
      variant="outlined"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        changeLocalValue(event.target.value)
      }
      value={localValue}
      slotProps={{
        input: {
          sx: { borderRadius: 40 },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              sx={{
                visibility: localValue ? "visible" : "hidden",
              }}
              onClick={() => changeLocalValue("")}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        },
      }}
      size="small"
    />
  );
}
