import { useTranslation } from "react-i18next";
import { ScenarioType } from "models/scenario-type";
import { ReactElement } from "react";

interface IProps {
  scenarioType: ScenarioType;
}

export function ScenarioName(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("scenario");

  return <>{t(`scenarioName.${props.scenarioType.toString()}`)}</>;
}

export default ScenarioName;
