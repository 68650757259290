import { useEffect, useState } from "react";
import OrganisationTreeNode, {
  findUpstreamParentBranch,
} from "features/organisation/domain/models/organisation-tree-node";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import {
  ControllerRenderProps,
  FieldValues,
  useController,
} from "react-hook-form";
import { useGatewaysContextProvider } from "../context/gateway-provider";

export interface IGatewayOrganisationHook {
  isOrganisationPickerOpen: boolean;
  upstreamParentBranch: OrganisationTreeNode[];
  isLinkErrorPopupOpen: boolean;
  organisationUnitField: ControllerRenderProps<
    FieldValues,
    "organisationUnitId"
  >;

  setOrganisationUnit: (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null,
    upstreamBranch: OrganisationTreeNode[] | null,
  ) => void;

  linkOrganisationUnit: () => void;
  unlinkOrganisationUnit: () => void;

  closeLinkErrorPopup: () => void;
  closeOrganisationPicker: () => void;
}

const useGatewayOrganisation = (): IGatewayOrganisationHook => {
  const [isOrganisationPickerOpen, setIsOrganisationPickerOpen] =
    useState(false);
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);
  const [isLinkErrorPopupOpen, setIsLinkErrorPopupOpen] =
    useState<boolean>(false);
  const { organisationUnitTree } = useOrganisationUnitTreeContext();
  const { field: organisationUnitField } = useController({
    name: "organisationUnitId",
  });
  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const handleOrganisationUnitChange = (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null = null,
    upstreamBranch: OrganisationTreeNode[] | null = null,
  ) => {
    field.onChange(selectedNode?.id ?? null);
    const branchToSet =
      upstreamBranch ??
      (selectedNode && organisationUnitTree
        ? (findUpstreamParentBranch(
            organisationUnitTree,
            selectedNode.id,
            [],
          ) ?? [])
        : []);

    setUpstreamParentBranch(branchToSet);
    if (selectedNode) {
      setIsOrganisationPickerOpen(false);
    }
  };

  useEffect(() => {
    if (gatewayDetailsHook.viewingMode === "viewing") {
      if (gatewayDetailsHook.currentSelectedGateway?.organisationUnitId) {
        const selectedNode = {
          id: gatewayDetailsHook.currentSelectedGateway.organisationUnitId,
        } as OrganisationTreeNode;

        handleOrganisationUnitChange(organisationUnitField, selectedNode);
      } else {
        handleOrganisationUnitChange(organisationUnitField, null, []);
      }
    } else if (gatewayDetailsHook.currentSelectedGateway) {
      const selectedNode = {
        id: gatewayDetailsHook.currentSelectedGateway.organisationUnitId,
      } as OrganisationTreeNode;

      handleOrganisationUnitChange(organisationUnitField, selectedNode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gatewayDetailsHook.viewingMode,
    gatewayDetailsHook.currentSelectedGateway,
    organisationUnitTree,
  ]);

  const setOrganisationUnit = (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null = null,
    upstreamBranch: OrganisationTreeNode[] | null = null,
  ) => {
    handleOrganisationUnitChange(field, selectedNode, upstreamBranch);
  };

  const canLinkOrUnlinkOrganisationUnit = () => {
    if (!gatewayDetailsHook.currentSelectedGateway) {
      return true;
    }
    const gatewayHasLinkedDevices =
      gatewayDetailsHook.currentSelectedGateway.devices?.some(
        (device) => !!device.organisationUnitId,
      );

    const gatewayHasLinkedSystems =
      gatewayDetailsHook.currentSelectedGateway.systems?.some(
        (system) => !!system.organisationUnitId,
      );
    return !gatewayHasLinkedDevices && !gatewayHasLinkedSystems;
  };

  const linkOrganisationUnit = () => {
    if (canLinkOrUnlinkOrganisationUnit()) {
      setIsOrganisationPickerOpen(true);
    } else {
      setIsLinkErrorPopupOpen(true);
    }
  };

  const unlinkOrganisationUnit = () => {
    if (canLinkOrUnlinkOrganisationUnit()) {
      setOrganisationUnit(organisationUnitField, null, []);
    } else {
      setIsLinkErrorPopupOpen(true);
    }
  };

  const closeLinkErrorPopup = () => setIsLinkErrorPopupOpen(false);
  const closeOrganisationPicker = () => setIsOrganisationPickerOpen(false);

  return {
    isOrganisationPickerOpen,
    upstreamParentBranch,
    isLinkErrorPopupOpen,
    organisationUnitField: organisationUnitField,
    setOrganisationUnit,
    linkOrganisationUnit,
    unlinkOrganisationUnit,
    closeLinkErrorPopup,
    closeOrganisationPicker,
  };
};

export default useGatewayOrganisation;
