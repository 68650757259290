import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";
import Constants from "style/constants";

interface IProps {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
  className?: string;
}

export default function SoundDetectionLoudSoundOutline(
  props: Readonly<IProps>,
): ReactElement {
  const color =
    props.disabled !== undefined && props.disabled
      ? Constants.Colors.inactive
      : (props.color ?? "#7342BD");

  return (
    <SvgIcon className={props.className} sx={props.sx} viewBox="0 0 24 24">
      <g id="Icon/material-symbols:sound-detection-loud-sound-outline">
        <path
          id="Vector"
          d="M17.725 17.6251H12.05L9.225 20.4501C8.84167 20.8334 8.37067 21.0251 7.812 21.0251C7.254 21.0251 6.78333 20.8334 6.4 20.4501L3.575 17.6251C3.19167 17.2417 3 16.7667 3 16.2001C3 15.6334 3.19167 15.1584 3.575 14.7751L6.375 11.9751V6.30005L17.725 17.6251ZM12.9 15.6251L8.375 11.1001V12.8001L4.975 16.2001L7.8 19.0251L11.2 15.6251H12.9ZM7.225 4.37505C9.00833 3.24172 10.9373 2.78739 13.012 3.01205C15.0873 3.23739 16.875 4.10005 18.375 5.60005C19.875 7.10005 20.7373 8.88738 20.962 10.9621C21.1873 13.0374 20.7333 14.9667 19.6 16.7501L18.15 15.3001C18.9 13.9334 19.1627 12.4874 18.938 10.9621C18.7127 9.43738 18.05 8.12505 16.95 7.02505C15.85 5.92505 14.5377 5.26239 13.013 5.03705C11.4877 4.81239 10.0417 5.07505 8.675 5.82505L7.225 4.37505ZM10.175 7.32505C11.125 7.04172 12.0833 6.98339 13.05 7.15005C14.0167 7.31672 14.85 7.75005 15.55 8.45005C16.25 9.15005 16.679 9.97938 16.837 10.9381C16.9957 11.8961 16.9333 12.8501 16.65 13.8001L14.95 12.1001C14.95 11.6834 14.8877 11.2791 14.763 10.8871C14.6377 10.4957 14.4333 10.1584 14.15 9.87505C13.85 9.57505 13.5043 9.35839 13.113 9.22505C12.721 9.09172 12.3083 9.02505 11.875 9.02505L10.175 7.32505Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  );
}
