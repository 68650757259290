import { Button, Typography } from "@mui/material";
import ScanSearchIcon from "components/custom-icons/scan-search-icon";
import styles from "features/systems/IS32/views/IS32-scan-configuration.module.scss";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { useSystemsContextProvider } from "features/systems/context/systems-provider";
import { useIS32ContextProvider } from "features/systems/IS32/context/IS32-provider";
import moment from "moment";

const IS32ScanConfiguration = () => {
  const { t } = useTranslation("IS32");

  const { systemsDetailsHook } = useSystemsContextProvider();

  const { startScan, startScanIsLoading } = useIS32ContextProvider();

  return (
    <div className={styles.scanContainer}>
      <Typography variant="h5">{t("startScanLabel")}</Typography>
      <Button
        startIcon={<ScanSearchIcon color={Constants.Colors.primary} />}
        variant="outlined"
        onClick={() => startScan(systemsDetailsHook.selectedSystem!.id!)}
        disabled={startScanIsLoading}
      >
        {t("startScanButtonLabel")}
      </Button>
      {systemsDetailsHook.selectedSystem!.lastSystemEvent && (
        <Typography variant="subtitle1">
          {t("startScanSubInformation", {
            scanDatetime: moment(
              systemsDetailsHook.selectedSystem!.lastSystemEvent
                ?.lastStatusChangeOn,
            )
              .format("DD-MM-YYYY HH:mm")
              .toString(),
          })}
        </Typography>
      )}
    </div>
  );
};

export default IS32ScanConfiguration;
