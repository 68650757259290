import { Grid2 } from "@mui/material";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import OrganisationUnitScenarioIcon from "features/residents/residents-settings/views/resident-alarm-scenarios/organisation-unit-scenario-icon";
import { ScenarioType } from "models/scenario-type";

interface Props {
  organisationUnitScenarios: OrganisationUnitScenario[];
}

const ResidentAlarmScenarios = ({
  organisationUnitScenarios,
}: Readonly<Props>) => {
  const isMatchingScenarioType = (scenarioType: ScenarioType) =>
    organisationUnitScenarios.some((s) => s.scenarioType === scenarioType);

  const organisationUnitScenario = (scenarioType: ScenarioType) =>
    organisationUnitScenarios.find((s) => s.scenarioType === scenarioType);

  const iconStyle = { fontSize: 16 };

  return (
    <Grid2 container gap={1.5}>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(ScenarioType.Bed)}
          isVisible={isMatchingScenarioType(ScenarioType.Bed)}
          scenarioType={ScenarioType.Bed}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(ScenarioType.Room)}
          isVisible={isMatchingScenarioType(ScenarioType.Room)}
          scenarioType={ScenarioType.Room}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(
            ScenarioType.Bathroom,
          )}
          isVisible={isMatchingScenarioType(ScenarioType.Bathroom)}
          scenarioType={ScenarioType.Bathroom}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(
            ScenarioType.Intruder,
          )}
          isVisible={isMatchingScenarioType(ScenarioType.Intruder)}
          scenarioType={ScenarioType.Intruder}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(
            ScenarioType.FallDetection,
          )}
          isVisible={isMatchingScenarioType(ScenarioType.FallDetection)}
          scenarioType={ScenarioType.FallDetection}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(ScenarioType.Door)}
          isVisible={isMatchingScenarioType(ScenarioType.Door)}
          scenarioType={ScenarioType.Door}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(
            ScenarioType.Video,
          )}
          isVisible={isMatchingScenarioType(ScenarioType.Video)}
          scenarioType={ScenarioType.Video}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(
            ScenarioType.Acoustic,
          )}
          isVisible={isMatchingScenarioType(ScenarioType.Acoustic)}
          scenarioType={ScenarioType.Acoustic}
        />
      </Grid2>
      <Grid2>
        <OrganisationUnitScenarioIcon
          sx={iconStyle}
          organisationUnitScenario={organisationUnitScenario(
            ScenarioType.CurrentLocation,
          )}
          isVisible={isMatchingScenarioType(ScenarioType.CurrentLocation)}
          scenarioType={ScenarioType.CurrentLocation}
        />
      </Grid2>
    </Grid2>
  );
};

export default ResidentAlarmScenarios;
