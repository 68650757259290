import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import styles from "components/pop-up/pop-up.component.module.scss";
import { ReactElement } from "react";

interface IProps {
  isOpen: boolean;
  handleOnClose?: React.ReactEventHandler<{}>;
  title: string;
  body: string | ReactElement;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonAction?: React.ReactEventHandler<{}>;
  secondaryButtonAction?: React.ReactEventHandler<{}>;
  isLoadingPrimaryAction?: boolean;
  showLoadingIndicator?: boolean;
}

function PopUp(props: Readonly<IProps>): ReactElement {
  return (
    <Modal open={props.isOpen} onClose={props.handleOnClose}>
      <Box className={styles.modalContainer}>
        <Typography className={styles.heading} variant="h3">{props.title}</Typography>
        <Typography
          className={styles.content}
          margin="16px 0px 24px 0px"
          variant="subtitle1"
        >
          {props.body}
        </Typography>

        {props.showLoadingIndicator && (
          <div className={styles.progressIndicatorContainer}>
            <CircularProgress />
          </div>
        )}
        <div className={styles.buttons}>
          {props.secondaryButtonText && props.secondaryButtonAction && (
            <Button
              data-testid={"popup-button-" + props.secondaryButtonText}
              className={styles.button}
              variant="text"
              onClick={props.secondaryButtonAction}
            >
              {props.secondaryButtonText}
            </Button>
          )}
          {props.primaryButtonText && props.primaryButtonAction && (
            <Button
              data-testid={
                "popup-primary-action-button-" + props.primaryButtonText
              }
              className={styles.button}
              variant="contained"
              onClick={props.primaryButtonAction}
              loading={props.isLoadingPrimaryAction}
              loadingPosition="center"
            >
              {props.primaryButtonText}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default PopUp;
