import { IS32AccordionType } from "features/systems/IS32/domain/models/is32-accordion-type";
import { useState } from "react";

export interface IS32AccordionHook {
  roomUnitExpanded: boolean;
  generalExpanded: boolean;
  acousticExpanded: boolean;
  buttonsExpanded: boolean;
  locationsExpanded: boolean;

  handleAccordionExpandedChange: (
    accordionType: IS32AccordionType,
    isExpanded: boolean,
  ) => void;
}

const useIS32AccordionHook = (): IS32AccordionHook => {
  const [roomUnitExpanded, setRoomUnitExpanded] = useState(false);
  const [generalExpanded, setGeneralExpanded] = useState(false);
  const [acousticExpanded, setAcousticExpanded] = useState(false);
  const [buttonsExpanded, setButtonsExpanded] = useState(false);
  const [locationsExpanded, setLocationsExpanded] = useState(false);

  const handleAccordionExpandedChange = (
    accordionType: IS32AccordionType,
    isExpanded: boolean,
  ) => {
    if (accordionType === IS32AccordionType.RoomUnit) {
      setRoomUnitExpanded(isExpanded);
      if (!isExpanded) {
        setGeneralExpanded(false);
        setAcousticExpanded(false);
        setButtonsExpanded(false);
        setLocationsExpanded(false);
      }
    }
    if (accordionType === IS32AccordionType.General) {
      setGeneralExpanded(isExpanded);
    }
    if (accordionType === IS32AccordionType.Acoustic) {
      setAcousticExpanded(isExpanded);
    }
    if (accordionType === IS32AccordionType.Buttons) {
      setButtonsExpanded(isExpanded);
    }
    if (accordionType === IS32AccordionType.Locations) {
      setLocationsExpanded(isExpanded);
    }
  };

  return {
    roomUnitExpanded,
    generalExpanded,
    acousticExpanded,
    buttonsExpanded,
    locationsExpanded,

    handleAccordionExpandedChange,
  };
};

export default useIS32AccordionHook;
